

import React, { useState, useContext, useEffect } from 'react';
import { collection, addDoc, doc, updateDoc, getDoc, getDocs, query, where  } from 'firebase/firestore';
import { projectFirestore } from '../../firebase/config'; // Adjust the import path
import { Form, Button, Alert } from 'react-bootstrap';
import { UserContext } from '../contexts/UserContext'; // Adjust the import path
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Include the Quill stylesheet
import { useNavigate, useParams } from 'react-router-dom';

const FaqEditor = ({ existingFaqId = null }) => {
    const [question, setQuestion] = useState('');
    const [answer, setAnswer] = useState('');
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const { currentUser } = useContext(UserContext);
    const navigate = useNavigate();
    const { faqId } = useParams(); 

 

    useEffect(() => {
        if (faqId) {
            // If there's an FAQ ID, we're in edit mode
            const fetchFaqData = async () => {
                const docRef = doc(projectFirestore, 'faqs', faqId);
                const docSnap = await getDoc(docRef);
                if (docSnap.exists()) {
                    setQuestion(docSnap.data().question);
                    setAnswer(docSnap.data().answer);
                } else {
                    console.error('No FAQ found with the given ID');
                    setError('FAQ not found.');
                    navigate('/faqs-list');
                }
            };
            fetchFaqData();
        }
    }, [faqId, navigate]);

    const saveFaq = async () => {
        if (!currentUser) {
            setError('You must be logged in to edit FAQs.');
            return;
        }
        if (!question.trim() || !answer) {
            setError('Both question and answer fields are required.');
            return;
        }

        try {
            setLoading(true);
            if (!faqId) {
                // Check for existing FAQ with the same question before adding a new one
                const q = query(collection(projectFirestore, 'faqs'), where("question", "==", question));
                const querySnapshot = await getDocs(q);
                if (!querySnapshot.empty) {
                    setError('An FAQ with the same question already exists.');
                    setLoading(false);
                    return;
                }
                // Add new FAQ
                await addDoc(collection(projectFirestore, 'faqs'), { question, answer });
            } else {
                // Update existing FAQ
                const docRef = doc(projectFirestore, 'faqs', faqId);
                await updateDoc(docRef, { question, answer });
            }
            navigate('/faqs-list'); // Redirect to FAQs list after save
        } catch (err) {
            console.error('Error saving FAQ:', err);
            setError('Error saving FAQ. Please try again later.');
        } finally {
            setLoading(false);
        }
    };
    //     setLoading(true);
    //     try {
    //         let docRef;
    //         if (existingFaqId) {
    //             // Update existing FAQ
    //             docRef = doc(projectFirestore, 'faqs', existingFaqId);
    //             await updateDoc(docRef, { question, answer });
    //         } else {
    //             // Add new FAQ
    //             docRef = await addDoc(collection(projectFirestore, 'faqs'), { question, answer });
    //         }
    //         navigate('/faqs-list'); // Redirect to FAQs list after save
    //     } catch (err) {
    //         console.error('Error saving FAQ:', err);
    //         setError('Error saving FAQ. Please try again later.');
    //     } finally {
    //         setLoading(false);
    //     }
    // };

    return (
        <div className="container mt-5">
            <h2>{existingFaqId ? 'Edit FAQ' : 'Add FAQ'}</h2>
            {error && <Alert variant="danger">{error}</Alert>}
            <Form>
                <Form.Group className="mb-3">
                    <Form.Label>FAQ Question</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder="Enter FAQ question"
                        value={question}
                        onChange={(e) => setQuestion(e.target.value)}
                        required
                    />
                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Label>FAQ Answer</Form.Label>
                    <ReactQuill
                        theme="snow"
                        value={answer}
                        onChange={setAnswer}
                        placeholder="Enter FAQ answer"
                        required
                    />
                </Form.Group>
                <Button variant="primary" onClick={saveFaq} disabled={loading}>
                    {existingFaqId ? 'Update FAQ' : 'Save FAQ'}
                </Button>
            </Form>
        </div>
    );
};

export default FaqEditor;

