import React from "react";
import PlayReferral from "./PlayReferral";
import DailyWinners from "../Draw/DailyWinners";
import WeeklyWinners from "../Draw/WeeklyWinners";
import MonthlyWinners from "../Draw/MonthlyWinners";

const PlayDashboard = () => {
  return (
    <div>
      <h2>Play Dashboard</h2>
      <PlayReferral />
      <DailyWinners />
      <WeeklyWinners />
      <MonthlyWinners />
    </div>
  );
};

export default PlayDashboard;
