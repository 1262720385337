

import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  updateProfile,
  createUserWithEmailAndPassword,
  signInWithPopup,
} from "firebase/auth";
import {
  projectAuth,
  projectFirestore,
  googleProvider,
  generateToken,
} from "../../firebase/config";
import { doc, getDoc, setDoc, Timestamp, updateDoc } from "firebase/firestore";
import { useUser } from "../contexts/UserContext";
import {
  requestNotificationsPermissions,
  saveMessagingDeviceToken,
} from "../../firebase/messaging";
import styles from "./Signup.module.css";

function Signup() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [name, setName] = useState("");
  const [error, setError] = useState(null);
  const [isPlayer, setIsPlayer] = useState(true); // Default selection to "Yes"
  const navigate = useNavigate();
  const location = useLocation();
  const referral = new URLSearchParams(location.search).get("ref");

  const { currentUser } = useUser();

  useEffect(() => {
    if (currentUser) {
      navigate("/");
    }
  }, [currentUser, navigate]);

  useEffect(() => {
    console.log("Referral code from URL:", referral);
  }, [referral]);

  const handleSignup = async (e) => {
    e.preventDefault();

    try {
      if (!email.includes("@")) throw new Error("Please enter a valid email.");
      if (password.length < 6)
        throw new Error("Password should be at least 6 characters long.");

      const userCredential = await createUserWithEmailAndPassword(
        projectAuth,
        email,
        password
      );

      const userDoc = {
        email: email,
        displayName: name,
        isPlayer: isPlayer,
        loginCount: 0,
        role: "user",
        profileCompleted: false,
        referredBy: referral || null,
        creationDate: Timestamp.now(),
        referrals: 0,
        lastWin: null,
        fcmToken: false, // Add this field to track FCM token status
      };

      await setDoc(
        doc(projectFirestore, "users", userCredential.user.uid),
        userDoc
      );

      console.log("User profile created in Firestore:", userDoc);

      if (referral) {
        await setDoc(
          doc(projectFirestore, "referrals", userCredential.user.uid),
          {
            referredBy: referral,
            referredUserId: userCredential.user.uid,
            referralDate: Timestamp.now(),
            signedUp: true,
            signedUpDate: Timestamp.now(),
            earnedAmount: 0,
            status: "pending",
            displayName: name,
          }
        );

        console.log("Referral document created in Firestore");

        const referrerDoc = await getDoc(
          doc(projectFirestore, "users", referral)
        );

        if (referrerDoc.exists()) {
          await setDoc(
            doc(projectFirestore, "users", referral),
            { referrals: referrerDoc.data().referrals + 1 },
            { merge: true }
          );

          console.log("Referrer document updated in Firestore");
        }
      }

      const permissionGranted = await requestNotificationsPermissions();
      if (permissionGranted) {
        const fcmToken = await generateToken();
        if (fcmToken) {
          await saveMessagingDeviceToken(userCredential.user.uid, fcmToken);
          await updateDoc(
            doc(projectFirestore, "users", userCredential.user.uid),
            { fcmToken: true }
          );
        }
      } else {
        console.error("Notification permission not granted");
      }

      navigate("/profile-completion");
    } catch (error) {
      setError(error.message);
      console.error("Error during signup:", error);
    }
  };

  const handleGoogleSignup = async () => {
    try {
      const result = await signInWithPopup(projectAuth, googleProvider);
      const userRef = doc(projectFirestore, "users", result.user.uid);

      await setDoc(
        userRef,
        {
          email: result.user.email,
          displayName: result.user.displayName || "",
          isPlayer: isPlayer,
          loginCount: 0,
          role: "user",
          profileCompleted: false,
          referredBy: referral || null,
          creationDate: Timestamp.now(),
          referrals: 0,
          lastWin: null,
          fcmToken: false, // Add this field to track FCM token status
        },
        { merge: true }
      );

      console.log("Google sign-in profile created/updated in Firestore");

      if (referral) {
        await setDoc(doc(projectFirestore, "referrals", result.user.uid), {
          referredBy: referral,
          referredUserId: result.user.uid,
          referralDate: Timestamp.now(),
          signedUp: true,
          signedUpDate: Timestamp.now(),
          earnedAmount: 0,
          status: "pending",
          displayName: result.user.displayName || "",
        });

        console.log(
          "Referral document created in Firestore for Google sign-in"
        );

        const referrerDoc = await getDoc(
          doc(projectFirestore, "users", referral)
        );
        if (referrerDoc.exists()) {
          await setDoc(
            doc(projectFirestore, "users", referral),
            { referrals: referrerDoc.data().referrals + 1 },
            { merge: true }
          );

          console.log(
            "Referrer document updated in Firestore for Google sign-in"
          );
        }
      }

      const permissionGranted = await requestNotificationsPermissions();
      if (permissionGranted) {
        const fcmToken = await generateToken();
        if (fcmToken) {
          await saveMessagingDeviceToken(result.user.uid, fcmToken);
          await updateDoc(doc(projectFirestore, "users", result.user.uid), {
            fcmToken: true,
          });
        }
      } else {
        console.error("Notification permission not granted");
      }

      navigate("/profile-completion");
    } catch (error) {
      setError(error.message);
      console.error("Error during Google signup:", error);
    }
  };

  return (
    <div className={`container my-5 ${styles.flashingContainer}`}>
      <div className="row justify-content-center">
        <div className="col-lg-6 col-md-8">
          <h2 className="text-center mb-4">Signup</h2>
          {error && (
            <div className="alert alert-danger" role="alert">
              {error}
            </div>
          )}

          <form onSubmit={handleSignup}>
            <div className="form-group">
              <input
                type="text"
                className="form-control"
                placeholder="Full Name"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </div>

            <div className="form-group">
              <input
                type="email"
                className="form-control"
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className="form-group">
              <input
                type="password"
                className="form-control"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>

            <div className="form-group">
              <h5>Participate in the daily 25 cedis, Kenkey Gift Prize.</h5>
              <div className="row">
                <div className="col-auto">
                  <label className="custom-control-label" htmlFor="isPlayerYes">
                    Yes, I want to participate
                  </label>
                </div>
                <div className="col-auto">
                  <input
                    type="radio"
                    id="isPlayerYes"
                    name="isPlayer"
                    className="custom-control-input ml-3"
                    checked={isPlayer}
                    onChange={() => setIsPlayer(true)}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-auto">
                  <label className="custom-control-label" htmlFor="isPlayerNo">
                    No, I don't want to participate
                  </label>
                </div>
                <div className="col-auto">
                  <input
                    type="radio"
                    id="isPlayerNo"
                    name="isPlayer"
                    className="custom-control-input ml-3"
                    checked={!isPlayer}
                    onChange={() => setIsPlayer(false)}
                  />
                </div>
              </div>
            </div>

            <button type="submit" className="btn btn-primary w-100 mt-3">
              Signup
            </button>
          </form>

          <button
            onClick={handleGoogleSignup}
            className="btn btn-danger w-100 mt-3"
          >
            Signup with Google
          </button>
        </div>
      </div>
    </div>
  );
}

export default Signup;
