// import React, { useState } from 'react';
// import { getFunctions, httpsCallable } from 'firebase/functions';

// const AdminPanel = () => {
//   const [email, setEmail] = useState('');
//   const [role, setRole] = useState('');
//   const [message, setMessage] = useState(''); // State for UI feedback
//   const [error, setError] = useState(''); // State for error message

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     setMessage(''); // Reset message
//     setError(''); // Reset error

//     const functions = getFunctions();
//     const addAdminRole = httpsCallable(functions, 'addAdminRole');

//     try {
//       const result = await addAdminRole({ email, role });
//       console.log(result.data.message); // Log success message to console
//       setMessage(result.data.message); // Display success message in UI
//     } catch (error) {
//       console.error('Error adding admin role:', error); // Log error details to console
//       setError(`Error: ${error.message}`); // Display error message in UI
//     }
//   };

//   return (
//     <div>
//       <h2>Admin Panel</h2>
//       {message && <div className="alert alert-success">{message}</div>} {/* Display success message */}
//       {error && <div className="alert alert-danger">{error}</div>} {/* Display error message */}
//       <form onSubmit={handleSubmit}>
//         <input
//           type="email"
//           value={email}
//           onChange={(e) => setEmail(e.target.value)}
//           placeholder="Enter user's email"
//         />
//         <select value={role} onChange={(e) => setRole(e.target.value)}>
//           <option value="">Select role</option>
//           <option value="admin">Admin</option>
//           <option value="user">User</option>
//         </select>
//         <button type="submit">Assign Role</button>
//       </form>
//     </div>
//   );
// };

// export default AdminPanel;


import React, { useState } from 'react';
import { getFunctions, httpsCallable } from 'firebase/functions';

const AdminPanel = () => {
  const [email, setEmail] = useState('');
  const [role, setRole] = useState('');
  const [message, setMessage] = useState(''); // State to store success message
  const [error, setError] = useState(''); // State to store error message

  const handleSubmit = async (e) => {
    e.preventDefault();
    setMessage(''); // Clear previous success message
    setError(''); // Clear previous error message

    const functions = getFunctions();
    const addAdminRole = httpsCallable(functions, 'addAdminRole');

    try {
      const result = await addAdminRole({ email, role });
      console.log('Function result:', result); // Log the entire result object
    
      if (result.data.message) {
        console.log('Success:', result.data.message); // Log the success message
        setMessage(result.data.message); // Set success message
      } else if (result.data.error) {
        console.error('Error:', result.data.error); // Log the error message
        setError(result.data.error); // Set error message
      }
    } catch (error) {
      console.error('Error:', error); // Log error details to console
      setError(error.message); // Set error message
    }
    
    
  };

  return (
    <div>
      <h2>Admin Panel</h2>
      <form onSubmit={handleSubmit}>
        <input
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Enter user's email"
        />
        <select value={role} onChange={(e) => setRole(e.target.value)}>
          <option value="">Select role</option>
          <option value="admin">Admin</option>
          <option value="user">User</option>
        </select>
        <button type="submit">Assign Role</button>
      </form>
      {message && <div className="alert alert-success">{message}</div>} {/* Display success message */}
      {error && <div className="alert alert-danger">{error}</div>} {/* Display error message */}
    </div>
  );
};

export default AdminPanel;
