import React, { useState, useEffect } from 'react';
import { Form, Button, Container, Row, Col, Switch } from 'react-bootstrap';
import { doc, setDoc, getDoc, addDoc, collection } from 'firebase/firestore';
import { projectFirestore } from '../../../firebase/config';
import { useParams, useNavigate } from 'react-router-dom';

const FormsConfigurationsForm = () => {
    //const [formFields, setFormFields] = useState([{ label: '', name: '', type: '' }]);
    const [formFields, setFormFields] = useState([{ label: '', name: '', type: '', placeholder: '', required: false }]);
    const { docId } = useParams(); // This retrieves the document ID from the URL
    const [docName, setDocName] = useState('');
    const navigate = useNavigate();

    // useEffect(() => {
    //     const fetchConfiguration = async () => {
    //         if (docId) {
    //             const docRef = doc(projectFirestore, 'formsConfigurations', docId);
    //             const docSnap = await getDoc(docRef);
    //             if (docSnap.exists()) {
    //                 // Assuming the structure of your document
    //                 setFormFields(docSnap.data().fields);
    //             }
    //         }
    //     };
    //     fetchConfiguration();
    // }, [docId]);

    useEffect(() => {
        if (docId) {
            // Edit mode: Fetch the existing document to edit
            const fetchConfiguration = async () => {
                const docRef = doc(projectFirestore, 'formsConfigurations', docId);
                const docSnap = await getDoc(docRef);
                if (docSnap.exists()) {
                    const data = docSnap.data();
                    setFormFields(data.fields);
                    setDocName(docId); // Use the docId from the URL as the document name
                }
            };
            fetchConfiguration();
        }
    }, [docId]);

    const handleFieldChange = (index, field, value) => {
        const updatedFields = [...formFields];
        updatedFields[index][field] = value;
        setFormFields(updatedFields);
    };

    const handleRequiredChange = (index, value) => {
        const updatedFields = [...formFields];
        updatedFields[index]['required'] = value;
        setFormFields(updatedFields);
      };
    
      const handleDocNameChange = (e) => {
        setDocName(e.target.value); // Update the document name as it's typed
    };

    // const handleSubmit = async (e) => {
    //     e.preventDefault();
    //     try {
    //         const docRef = docId ? doc(projectFirestore, 'formsConfigurations', docId) : doc(projectFirestore, 'formsConfigurations');
    //         await setDoc(docRef, { fields: formFields }, { merge: true });
    //         alert('Form configuration saved successfully!');
    //         navigate('/forms-configurations-list'); // Navigate back to the list view after saving
    //     } catch (error) {
    //         console.error('Error saving form configuration:', error);
    //         alert('Failed to save form configuration.');
    //     }
    // };

    // const handleSubmit = async (e) => {
    //     e.preventDefault();
    //     try {
    //         // For new form configurations without a docId, let Firestore auto-generate it.
    //         if (!docId) {
    //             await addDoc(collection(projectFirestore, 'formsConfigurations'), { fields: formFields });
    //         } else {
    //             // For updating existing configurations, use the provided docId.
    //             const docRef = doc(projectFirestore, 'formsConfigurations', docId);
    //             await setDoc(docRef, { fields: formFields }, { merge: true });
    //         }
    //         alert('Form configuration saved successfully!');
    //         navigate('/forms-configurations-list');
    //     } catch (error) {
    //         console.error('Error saving form configuration:', error);
    //         alert('Failed to save form configuration.');
    //     }
    // };

    // const handleSubmit = async (e) => {
    //     e.preventDefault();
    //     const docId = 'communityFormConfig'; // Use your custom document ID
    //     const docRef = doc(projectFirestore, 'formsConfigurations', docId);
    //     try {
    //         await setDoc(docRef, { fields: formFields }, { merge: true });
    //         alert('Form configuration saved successfully!');
    //         navigate('/forms-configurations-list'); // Navigate back to the list view after saving
    //     } catch (error) {
    //         console.error('Error saving form configuration:', error);
    //         alert('Failed to save form configuration.');
    //     }
    // };

    const handleSubmit = async (e) => {
        e.preventDefault();
        // Use the custom document name from state
        if (!docName) {
            alert('Please specify a document name');
            return;
        }
        const docRef = doc(projectFirestore, 'formsConfigurations', docName.trim());
        try {
            await setDoc(docRef, { fields: formFields });
            alert('Form configuration saved successfully!');
            navigate('/forms-configurations-list'); // Navigate back to the list view after saving
        } catch (error) {
            console.error('Error saving form configuration:', error);
            alert('Failed to save form configuration.');
        }
    };
    
    

    //const addField = () => setFormFields([...formFields, { label: '', name: '', type: '' }]);

    const addField = () => {
        setFormFields([...formFields, { label: '', name: '', type: '', placeholder: '', required: false }]);
      };

    return (
        <Container>
            <h2>{docId ? 'Edit' : 'Create'} Form Configuration</h2>
            <Form onSubmit={handleSubmit}>
            {!docId && (
                    <Form.Group className="mb-3">
                        <Form.Label>Document Name</Form.Label>
                        <Form.Control
                            type="text"
                            value={docName}
                            onChange={handleDocNameChange}
                            placeholder="Enter document name, e.g., communityFormConfig"
                            required
                        />
                    </Form.Group>
                )}
                {formFields.map((field, index) => (
                    <div key={index}>
                        <Form.Group className="mb-3">
                            <Form.Label>Field Label</Form.Label>
                            <Form.Control
                                type="text"
                                value={field.label}
                                onChange={(e) => handleFieldChange(index, 'label', e.target.value)}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Field Name</Form.Label>
                            <Form.Control
                                type="text"
                                value={field.name}
                                onChange={(e) => handleFieldChange(index, 'name', e.target.value)}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Field Type</Form.Label>
                            <Form.Control
                                type="text"
                                value={field.type}
                                onChange={(e) => handleFieldChange(index, 'type', e.target.value)}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Placeholder</Form.Label>
                            <Form.Control
                                type="text"
                                value={field.placeholder}
                                onChange={(e) => handleFieldChange(index, 'placeholder', e.target.value)}
                                placeholder="Placeholder text"
                            />
                            </Form.Group>
                            <Form.Group as={Row} className="mb-3" controlId="formHorizontalCheck">
                            <Col sm={{ span: 10, offset: 2 }}>
                                <Form.Check
                                label="Required"
                                checked={field.required}
                                onChange={(e) => handleRequiredChange(index, e.target.checked)}
                                />
                            </Col>
                            </Form.Group>
                    </div>
                ))}
                <Button variant="secondary" onClick={addField} className="me-2">Add Field</Button>
                <Button variant="primary" type="submit">{docId ? 'Update' : 'Save'} Configuration</Button>
            </Form>
        </Container>
    );
};

export default FormsConfigurationsForm;
