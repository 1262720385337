import React, { useState, useEffect } from 'react';
import {
  collection,
  addDoc,
  getDocs,
  updateDoc,
  deleteDoc,
  doc,
  getDoc,
  arrayUnion,
  arrayRemove,
  writeBatch,
} from 'firebase/firestore';
import { Container, Row, Col, Card, Form, Button, ListGroup, Spinner, Alert } from 'react-bootstrap';
import { projectFirestore } from '../../../firebase/config';
import MasterDetailInterface from './CategoryManagement/MasterDetailInterface';
import ExpandableListInterface from './CategoryManagement/ExpandableListInterface';
import EditSubCategoryModal from './CategoryManagement/EditSubCategoryModal';
import { v4 as uuidv4 } from 'uuid';
import { runTransaction } from "firebase/firestore"; 

const CategoryManager = () => {
  const [categories, setCategories] = useState([]);
  const [error, setError] = useState('');
  const [newCategoryName, setNewCategoryName] = useState('');
  const [newCategoryIcon, setNewCategoryIcon] = useState('');
  const [newSubCategoryName, setNewSubCategoryName] = useState('');
  const [newSubCategoryIcon, setNewSubCategoryIcon] = useState('');
  const [selectedCategoryId, setSelectedCategoryId] = useState('');
  // const [selectedCategory, setSelectedCategory] = useState(null);
  const selectedCategory = categories.find(cat => cat.id === selectedCategoryId);
  const [editingCategoryId, setEditingCategoryId] = useState(null);
  const [uploading, setUploading] = useState(false);
  // Instead of a single 'uploading' state, use separate ones for different actions
  const [isAddingCategory, setIsAddingCategory] = useState(false);
  const [isAddingSubCategory, setIsAddingSubCategory] = useState(false);
  const [isEditingCategory, setIsEditingCategory] = useState(false);
  const [isEditingSubCategory, setIsEditingSubCategory] = useState(false);

  const [showEditForm, setShowEditForm] = useState(false);
  const [editFormData, setEditFormData] = useState({ name: '', icon1: '' });
  const [editingSubCategory, setEditingSubCategory] = useState(null); // Assuming 'icon' is another field you have
  const [editingSubCategoryParentId, setEditingSubCategoryParentId] = useState(null);
  const [selectedOption, setSelectedOption] = useState('');
  const [categoryData, setCategoryData] = useState({
    name: '',
    icon1: '',
    optionsText: '', // Options as a comma-separated string
  });
  const [dynamicDropdown, setDynamicDropdown] = useState({ label: '', options: [] });
  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    fetchCategories();
  }, []);

  const fetchCategories = async () => {
    try {
      const snapshot = await getDocs(collection(projectFirestore, 'advertcategories'));
      const fetchedCategories = snapshot.docs.map(doc => {
        const category = doc.data();
        //console.log(`Fetched category: ${category.name}`, category);
        return { id: doc.id, ...category };
      });
      setCategories(fetchedCategories);
    } catch (error) {
      console.error('Error fetching categories:', error);
    }
  };

  useEffect(() => {
    const selected = categories.find(cat => cat.id === selectedCategoryId);
    if (selected && selected.options) {
      setDynamicDropdown({
        label: `${selected.name} Type`, // Customize this label as needed
        options: selected.options,
      });
    } else {
      setDynamicDropdown({ label: '', options: [] }); // Reset if no category is selected or if it has no options
    }
  }, [selectedCategoryId, categories]);



const handleAddCategory = async (event) => {
  event.preventDefault();

  // Convert optionsText to an array, removing any empty strings
  const optionsArray = categoryData.optionsText.split(',')
                       .map(option => option.trim())
                       .filter(option => option !== '');

  // setUploading(true);
  setIsAddingCategory(true); // Set loading state for adding category


  try {
    await addDoc(collection(projectFirestore, 'advertcategories'), {
      name: categoryData.name,
      icon1: categoryData.icon1,
      // Ensure options is an array; if optionsText is empty, this becomes an empty array
      options: optionsArray,
    });
    alert('Category added successfully!');
    setCategoryData({ name: '', icon1: '', optionsText: '' }); // Reset form
  } catch (error) {
    console.error('Error adding category:', error);
    setError('Failed to add category. Please try again.');
  } finally {
    // setUploading(false);
    setIsAddingCategory(false); // Reset loading state after operation
  }
};

  

const addSubCategory = async (event) => {
  event.preventDefault();
  console.log("Starting addSubCategory");

  // 1. Validate user input
  if (!newSubCategoryName) {
    console.log("Validation failed: Please enter a name for the sub-category.");
    setError("Please enter a name for the sub-category.");
    return;
  }
  
  console.log("Passed validation");
  console.log(`Selected Category ID: ${selectedCategoryId}`);
  console.log(`Sub-Category Name: ${newSubCategoryName}`);
  console.log(`Sub-Category Icon: ${newSubCategoryIcon}`);
  console.log(`Selected Option: ${selectedOption}`);

  setIsAddingSubCategory(true); // Indicate editing process has started
  setIsSaving(true); // Set saving state to true before update

  try {
    const categoryRef = doc(projectFirestore, 'advertcategories', selectedCategoryId);
    console.log(`Firestore Document Reference: ${categoryRef.path}`);

    const categoryDoc = await getDoc(categoryRef);
    console.log("Fetched Category Document");

    if (!categoryDoc.exists()) {
      throw new Error("Parent category doesn't exist.");
    }
    console.log("Parent category exists");

    const updatedSubCategories = categoryDoc.data().subCategories ? [...categoryDoc.data().subCategories] : [];
    updatedSubCategories.push({
      subCategoryId: uuidv4(),
      name: newSubCategoryName,
      icon1: newSubCategoryIcon,
      option: selectedOption,
    });

    console.log("Prepared updated sub-categories", updatedSubCategories);

    // Await the updateDoc operation
    await updateDoc(categoryRef, { subCategories: updatedSubCategories });
    console.log("Sub-category updated successfully!");
    alert("Sub-category added successfully!");
  } catch (error) {
    console.error('Error adding sub-category:', error);
    setError(`Failed to add sub-category. ${error.message}`);
    console.log(`Error adding sub-category: ${error.message}`);
  } finally {
    setIsAddingSubCategory(false); // Reset adding state
    setIsSaving(false); // Reset saving state
    console.log("Finished addSubCategory");
  }
};


  const resetFormState = () => {
    setNewCategoryName('');
    setNewCategoryIcon('');
    setNewSubCategoryName('');
    setNewSubCategoryIcon('');
    setSelectedCategoryId('');
    setEditingCategoryId(null);
    setShowEditForm(false);
    setError(''); // Clear any existing errors
    fetchCategories(); // Refresh the categories list
  };
  

const startEditCategory = (category) => {
  setIsEditingCategory(true); // Indicate that the category edit process has started
  
  setEditingCategoryId(category.id);
  // Ensure you're setting the state correctly for the category being edited
  setCategoryData({
    ...categoryData, // Spread the existing state to retain other values
    name: category.name,
    icon1: category.icon1,
    optionsText: category.options ? category.options.join(', ') : '', // Convert array to string
  });
  
  setShowEditForm(true);

  // Note: You might reset setIsEditingCategory(false) after the form is submitted or closed.
  // This depends on your form's logic and where you handle form submissions and closures.
  // For example, if you have a function that handles form submission, you might call setIsEditingCategory(false) there.
};


  const startEditSubCategory = (categoryId, subCategory) => {
    // console.log("Editing sub-category", subCategory.id, "in category", categoryId);

    // Update selectedCategoryId to ensure selectedCategory and categoryOptions are correct.
    setSelectedCategoryId(categoryId); // This line is crucial.

    setEditingSubCategoryParentId(categoryId); // Store the parent category's ID
    setEditingSubCategory(subCategory); // Store the sub-category data for editing
    // Depending on your UI, you may want to open a modal here or set a flag to show an edit form
    // Trigger modal or other UI elements to edit sub-category.
  };

  const updateCategory = async () => {
    setIsEditingCategory(true); // Indicate the start of the editing process
    if (!editFormData.name.trim()) {
      console.error("Name is empty");
      return; // Prevent saving empty names
    }
  
    // Convert optionsText to an array if it's not empty
    const optionsArray = categoryData.optionsText
      ? categoryData.optionsText.split(',').map(option => option.trim())
      : [];
  
    try {
      const categoryRef = doc(projectFirestore, 'advertcategories', editingCategoryId);
      // console.log("Updating category with ID:", editingCategoryId); // Debugging
      await updateDoc(categoryRef, {
        name: editFormData.name,
        icon1: editFormData.icon1,
        options: optionsArray, // Update the options field with the converted array
      });
      console.log('Category updated successfully in Firestore');
      resetFormState(); // Reset state after successful update
    } catch (error) {
      console.error('Error updating category:', error);
    } finally {
      setIsEditingCategory(false); // Reset after completion, regardless of the outcome
    }
  };
  
  const deleteCategory = async (categoryId) => {
    const confirmation = window.confirm('Are you sure you want to delete this category?');
    if (!confirmation) return;
  
    try {
      const categoryRef = doc(projectFirestore, 'advertcategories', categoryId);
      const categoryDoc = await getDoc(categoryRef); // Get category document
  
      // Check if category has sub-categories
      const categoryData = categoryDoc.data();
      if (categoryData.subCategories && categoryData.subCategories.length > 0) {
        alert('This category has sub-categories. Please delete them first.');
        return; // Prevent deletion if sub-categories exist
      }
  
      await deleteDoc(categoryRef);
      console.log('Category deleted successfully!');
      fetchCategories(); // Refresh categories after deletion
    } catch (error) {
      console.error('Error deleting category:', error);
    }
  };

  const deleteSubCategory = async (categoryId, subCategoryId) => {
    console.log(`Deleting sub-category ${subCategoryId} from category ${categoryId}`);
    if (!categoryId) {
      console.error("No category selected for sub-category deletion.");
      setError("No category selected for sub-category deletion.");
      return;
    }
  
    const confirmation = window.confirm('Are you sure you want to delete this sub-category?');
    if (!confirmation) return;
  
    setUploading(true);
    try {
      const categoryRef = doc(projectFirestore, 'advertcategories', categoryId);
      const categoryDoc = await getDoc(categoryRef);
      if (!categoryDoc.exists()) {
        throw new Error("Category does not exist.");
      }
  
      const updatedSubCategories = categoryDoc.data().subCategories.filter(sub => sub.id !== subCategoryId);
  
      await updateDoc(categoryRef, { subCategories: updatedSubCategories });
      alert("Sub-category deleted successfully!");
    } catch (error) {
      console.error('Error deleting sub-category:', error);
      setError(`Failed to delete sub-category. ${error.message}`);
    } finally {
      setUploading(false);
      fetchCategories();
    }
  };
  
  

  // const handleSaveSubCategory = async () => {
  //   if (!editingSubCategoryParentId || !editingSubCategory) return;
  
  //   setIsEditingSubCategory(true); // Indicate editing process has started
  //   setIsSaving(true); // Set saving state to true before update
  //   try {
  //     const categoryRef = doc(projectFirestore, 'advertcategories', editingSubCategoryParentId);
  //     const categoryDoc = await getDoc(categoryRef);
  
  //     if (!categoryDoc.exists()) {
  //       throw new Error("Parent category doesn't exist.");
  //     }
  
  //     const updatedSubCategories = categoryDoc.data().subCategories.map(subCat =>
  //       subCat.id === editingSubCategory.id ? { ...editingSubCategory } : subCat
  //     );
  
  //     // Await the updateDoc operation
  //     await updateDoc(categoryRef, { subCategories: updatedSubCategories });
  //     alert("Sub-category updated successfully!");
  //   } catch (error) {
  //     console.error('Error updating sub-category:', error);
  //     setError(`Failed to update sub-category. ${error.message}`);
  //   } finally {
  //     setIsSaving(false); // Reset saving state after update
  //     setIsEditingSubCategory(false); // Indicate editing process has ended
  //     setEditingSubCategory(null); // Optionally reset the editing sub-category
  //     setEditingSubCategoryParentId(null); // Optionally reset the parent category ID
  //     // Optionally close the modal here if you have a state managing its visibility
  //     fetchCategories(); // Refresh the categories list to reflect the update
  //   }
  // };

  const handleSaveSubCategory = async () => {
    if (!editingSubCategoryParentId || !editingSubCategory) return;
  
    setIsEditingSubCategory(true); // Indicate editing process has started
    setIsSaving(true); // Set saving state to true before update
  
    try {
      const categoryRef = doc(projectFirestore, 'advertcategories', editingSubCategoryParentId);
      const categoryDoc = await getDoc(categoryRef);
  
      if (!categoryDoc.exists()) {
        throw new Error("Parent category doesn't exist.");
      }
  
      const batch = writeBatch(projectFirestore); // Create a batch write instance
  
      const existingSubCategories = categoryDoc.data().subCategories || [];
      const updatedSubCategories = existingSubCategories.map(subCat => {
        if (subCat.id === editingSubCategory.id || subCat.subcategoryId === editingSubCategory.id) {
          // Creating a new object for the sub-category with 'subCategoryId' and removing 'id'
          return {
            ...editingSubCategory,
            subCategoryId: subCat.id || subCat.subcategoryId,
          };
        } else {
          return subCat;
        }
      });
  
      // Prepare the updated sub-categories for the batch
      updatedSubCategories.forEach(sub => {
        delete sub.id; // Remove old id field
        delete sub.subcategoryId; // Ensure no old subcategoryId field is carried over
      });
  
      // Set the updated sub-categories array to the category document in the batch
      batch.update(categoryRef, { subCategories: updatedSubCategories });
  
      // Commit the batch write
      await batch.commit();
      alert("Sub-category updated successfully!");
    } catch (error) {
      console.error('Error updating sub-category:', error);
      setError(`Failed to update sub-category. ${error.message}`);
    } finally {
      setIsSaving(false); // Reset saving state after update
      setIsEditingSubCategory(false); // Indicate editing process has ended
      setEditingSubCategory(null); // Optionally reset the editing sub-category
      setEditingSubCategoryParentId(null); // Optionally reset the parent category ID
      fetchCategories(); // Refresh the categories list to reflect the update
    }
  };
  

  


  return (
    <Container fluid>
      <Row className="justify-content-center">
        
        <Col md={8}>
          <h2 className="text-center mb-4">Manage Categories</h2>
          {/* Display error message */}
          {error && <Alert variant="danger">{error}</Alert>}

          {/* Display spinner when loading */}
          {uploading && <div className="text-center my-2"><Spinner animation="border" /></div>}

          {/* Category List */}
          <ListGroup variant="flush">
          {categories.map((category) => (
              <ListGroup.Item key={category.id} className="mb-3">
                <div className="d-flex justify-content-between align-items-center">
                  {category.name}
                  <div>
                    <Button variant="warning" size="sm" onClick={() => startEditCategory(category)}>
                      Edit
                    </Button>
                    <Button variant="danger" size="sm" className="ms-2" onClick={() => deleteCategory(category.id)}>
                      Delete
                    </Button>
                  </div>
                </div>
              
              </ListGroup.Item>
            ))}

          </ListGroup>
          {showEditForm && (
            <Card className="mt-4">
              <Card.Body>
                <Card.Title>Edit Category</Card.Title>
                <Form onSubmit={updateCategory}>
                <Form.Group className="mb-3">
                    <Form.Label>Category Name</Form.Label>
                    <Form.Control
                      type="text"
                      value={categoryData.name}
                      onChange={(e) => setCategoryData({ ...categoryData, name: e.target.value })}
                    />
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>Category Icon</Form.Label>
                    <Form.Control
                      type="text"
                      value={categoryData.icon1}
                      onChange={(e) => setCategoryData({ ...categoryData, icon1: e.target.value })}
                    />
                  </Form.Group>

                  {/* Include Category Options here */}
                  <Form.Group className="mb-3">
                    <Form.Label>Category Options</Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={3}
                      placeholder="Enter options separated by commas"
                      value={categoryData.optionsText}
                      onChange={(e) => setCategoryData({ ...categoryData, optionsText: e.target.value })}
                    />
                  </Form.Group>
                  
                  <Button variant="primary" type="submit">Update Category</Button>
                  <Button variant="secondary" className="ms-2" onClick={() => setShowEditForm(false)}>Cancel</Button>
                </Form>
              </Card.Body>
            </Card>
          )}

          <Card className="mt-4">
            <Card.Body>
              <Card.Title>Add New Category</Card.Title>
              <Form onSubmit={handleAddCategory}>
              <Form.Group className="mb-3">
                  <Form.Label>Category Name</Form.Label>
                  <Form.Control
                    type="text"
                    value={categoryData.name}
                    onChange={(e) => setCategoryData({ ...categoryData, name: e.target.value })}
                    placeholder="Enter category name"
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Category Icon</Form.Label>
                  <Form.Control
                    type="text"
                    value={categoryData.icon1}
                    onChange={(e) => setCategoryData({ ...categoryData, icon1: e.target.value })}
                    placeholder="Enter category icon"
                  />
                </Form.Group>
                {/* Add this Form.Group for Category Options */}
                <Form.Group className="mb-3">
                    <Form.Label>Category Options</Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={3}
                      placeholder="Enter options separated by commas"
                      value={categoryData.optionsText}
                      onChange={(e) => setCategoryData({ ...categoryData, optionsText: e.target.value })}
                    />
                  </Form.Group>
                <Button variant="primary" type="submit" disabled={isAddingCategory}>{isAddingCategory ? 'Adding...' : 'Add Category'}</Button>
                {/* <Button variant="primary" onClick={handleTestAddCategory}>
                  Test Add Category
                </Button> */}
              </Form>
            </Card.Body>
          </Card>

          <Card className="mt-4 mb-4">
            <Card.Body>
              <Card.Title>Add Sub-Category</Card.Title>
              <Form onSubmit={addSubCategory}>
                <Form.Group className="mb-3">
                  <Form.Label>Select Category</Form.Label>
                  <Form.Select value={selectedCategoryId} onChange={(e) => setSelectedCategoryId(e.target.value)}>
                    <option value="">Select Category</option>
                    {categories.map((category) => (
                      <option key={category.id} value={category.id}>{category.name}</option>
                    ))}
                  </Form.Select>
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Sub-Category Name</Form.Label>
                  <Form.Control type="text" value={newSubCategoryName} onChange={(e) => setNewSubCategoryName(e.target.value)} />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Sub-Category Icon</Form.Label>
                  <Form.Control type="text" value={newSubCategoryIcon} onChange={(e) => setNewSubCategoryIcon(e.target.value)} />
                </Form.Group>

                {dynamicDropdown.options.length > 0 && (
                  <Form.Group className="mb-3">
                    <Form.Label>{dynamicDropdown.label}</Form.Label>
                    <Form.Select value={selectedOption} onChange={(e) => setSelectedOption(e.target.value)}>
                      <option value="">Select Type</option>
                      {dynamicDropdown.options.map((option, index) => (
                        <option key={index} value={option}>{option}</option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                )}


                <Button variant="primary" type="submit" disabled={isAddingSubCategory}>{isAddingSubCategory ? 'Adding...' : 'Add Sub-Category'}</Button>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md={8}>
          <ExpandableListInterface 
            categories={categories || []} 
            startEditSubCategory={startEditSubCategory} 
            deleteSubCategory={deleteSubCategory} 
          />
        </Col>
      </Row>

      <Row className="justify-content-center">
        <Col md={8}>
          <MasterDetailInterface 
            startEditSubCategory={startEditSubCategory} 
            deleteSubCategory={deleteSubCategory}
            categories={categories} // Assuming you're also passing categories as props
          />
        </Col>
      </Row>
      {/* Conditionally render the EditSubCategoryModal */}
      {editingSubCategory && (
        console.log(editingSubCategory),
        // console.log("Category Options before EditSubCategoryModal:", selectedCategory?.options),
      <EditSubCategoryModal
        show={!!editingSubCategory}
        onHide={() => {
          setEditingSubCategory(null);
          setEditingSubCategoryParentId(null);
        }}
        categoryId={editingSubCategoryParentId}
        subCategory={editingSubCategory}
        onSave={handleSaveSubCategory}
        // onSave={updateSubCategory}
        categoryOptions={selectedCategory?.options || []}
      />
      
    )}
    
    </Container>
  );
};

export default CategoryManager;

