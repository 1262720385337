import React, { useState, useEffect, useContext } from 'react';
import { collection, getDocs } from 'firebase/firestore';
import { projectFirestore } from '../../firebase/config';
import parse from 'html-react-parser';
import { groupBy } from 'lodash';
import { Link } from 'react-router-dom';

import roomImage1 from '../../assets/room1.jpg'
import roomImage2 from '../../assets/roomimage2.jpg'
import roomImage3 from '../../assets/dinningroom1.jpg'
import { UserContext } from '../contexts/UserContext';
import styles from './ServicesSection.module.css';

const ServicesSection = () => {
    const [services, setServices] = useState([]);
    const currentUser = useContext(UserContext);
    const [groupedServices, setGroupedServices] = useState({});
    

    useEffect(() => {
        const fetchServices = async () => {
            try {
                const servicesCollection = collection(projectFirestore, 'cleaningServices');
                const snapshot = await getDocs(servicesCollection);
                const servicesData = snapshot.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data()
                }));

                // Group services by 'cleaningType'
                const grouped = groupBy(servicesData, 'cleaningType');
                setGroupedServices(grouped);
            } catch (error) {
                console.error("Error fetching services:", error);
            }
        };
    
        fetchServices();
    }, []);
    

// Utility function to strip HTML tags and truncate text
const stripHtmlAndTruncate = (htmlContent, maxLength) => {
    // Create a new div element and set its inner HTML to the HTML content
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = htmlContent;
  
    // Extract text content from the div and remove any leading/trailing whitespace
    let textContent = tempDiv.textContent || tempDiv.innerText || '';
    textContent = textContent.trim();
  
    // Truncate text to the specified maximum length
    if (textContent.length > maxLength) {
      return textContent.substring(0, maxLength) + '...';
    }
  
    return textContent;
  };

    // Function to truncate description to the first 5 lines
const truncateDescription = (description) => {
    const lines = description.split('\n');
    if (lines.length > 5) {
    return lines.slice(0, 5).join(' ') + '...';
    }
    return description;
    };

    return (
        <>
            <div className="container my-5">
                <h2 className="text-center mb-4">WE KEEP IT SIMPLE!</h2>
                <div className="row">
                    <div className="col-md-4 mb-4">
                    <div className={styles.staticServiceCard}>
                        <img src={roomImage1} alt="Get an
                Instant Price" className="img-fluid" />
                <h3>GET YOUR INSTANT QUOTE TODAY</h3>
                <p>Waiting is not in our nature, and we know it's not in yours either. The long wait for a cleaning service quote can be frustrating – that's why at AllGhanaians Cleaning Service, we've cut out the wait entirely. We offer an immediate quote for our top-tier cleaning services directly on our website.</p>
                </div>
                </div>
                <div className="col-md-4 mb-4">
                <div className={styles.staticServiceCard}>
                <img src={roomImage2} alt="Set Your Schedule" className="img-fluid" />
                <h3>TAKE CHARGE OF YOUR CLEANING SCHEDULE</h3>
                <p>Navigate to our user-friendly online booking platform, and you'll discover the freedom to tailor your cleaning sessions to your personal timetable. Whether it's a serene morning or a quiet evening in Ghana, select the date and time that seamlessly aligns with your routine.</p>
                </div>
                </div>
                <div className="col-md-4 mb-4">
                <div className={styles.staticServiceCard}>
                <img src={roomImage3} alt="Enjoy a Clean Home" className="img-fluid" />
                <h3>EMBRACE SPOTLESS LIVING</h3>
                <p>With us, you reclaim precious moments for yourself and your loved ones, free from the worry of scrubbing and dusting. Relax, indulge in your personal pursuits, and bask in the comfort of a perpetually clean home. Wave farewell to the cleaning kits and embrace the ease that comes with AllGhanaians Cleaning Service.</p>
                </div>
                </div>

                </div>
                </div>
                
                <div className="container my-5">
                <h1 className="text-center mb-4">OUR CLEANING SERVICES</h1>
                                {/* Map over the grouped services to create sections */}
                                {Object.entries(groupedServices).map(([cleaningType, services]) => (
                                    <div key={cleaningType}>
                                        <h2 className="text-center mb-4">{cleaningType.toUpperCase()}</h2>
                                        <div className="row">
                                            {services.map((service) => (
                                                <Link to={`/cleaningServices/${service.id}`} key={service.id} className={`col-md-4 mb-4 ${styles.serviceCardLink}`}>
                                                    <div className={styles.serviceCard}>
                                                        {service.images && service.images.length > 0 && (
                                                            <img src={service.images[0]} alt={service.name} className={`img-fluid ${styles.serviceImage}`} />
                                                        )}
                                                        <h3>{service.name}</h3>
                                                        <p>{stripHtmlAndTruncate(service.description, 100)}</p>
                                                        <p className={styles.servicePrice}>GHS {service.price}</p>
                                                    </div>
                                                </Link>
                                            ))}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </>
                    );
                };

export default ServicesSection;