// import React, { useState, useEffect } from "react";
// import { useNavigate } from "react-router-dom";
// import { projectAuth, projectFirestore } from "../../firebase/config";
// import { doc, updateDoc, getDoc } from "firebase/firestore";
// import { useUser } from "../contexts/UserContext";
// import { Navigate } from "react-router-dom";
// import { Container, Form, Button, Row, Col, Card } from "react-bootstrap";

// function Participation() {
//   const [noCount, setNoCount] = useState(0);
//   const [hideParticipation, setHideParticipation] = useState(false);
//   const navigate = useNavigate();
//   const { currentUser, loading } = useUser(); // Access the currentUser from the UserContext

//   useEffect(() => {
//     if (currentUser) {
//       checkParticipationStatus(currentUser.uid);
//     }
//   }, [currentUser]);

//   const checkParticipationStatus = async (uid) => {
//     try {
//       const userProfileRef = doc(projectFirestore, "users", uid);
//       const userProfileSnapshot = await getDoc(userProfileRef);
//       if (userProfileSnapshot.exists()) {
//         const { noParticipationCount = 0, hideParticipationPage = false } =
//           userProfileSnapshot.data();
//         setNoCount(noParticipationCount);
//         if (hideParticipationPage || noParticipationCount >= 5) {
//           setHideParticipation(true);
//           navigate("/");
//         }
//       }
//     } catch (error) {
//       console.error("Error checking participation status:", error);
//     }
//   };

//   const handleParticipationChoice = async (choice, hidePage = false) => {
//     const currentUser = projectAuth.currentUser;
//     if (!currentUser) {
//       console.error("No current user");
//       return;
//     }

//     const userProfileRef = doc(projectFirestore, "users", currentUser.uid);
//     console.log("UserProfileRef:", userProfileRef.path);

//     try {
//       const userProfileSnapshot = await getDoc(userProfileRef);

//       if (userProfileSnapshot.exists()) {
//         let { noParticipationCount = 0 } = userProfileSnapshot.data();

//         if (choice) {
//           await updateDoc(userProfileRef, {
//             isPlayer: true,
//             hideParticipationPage: hidePage,
//           });
//           alert("You are now a player! Enjoy the game!");
//         } else if (hidePage) {
//           await updateDoc(userProfileRef, { hideParticipationPage: true });
//           setHideParticipation(true);
//         } else {
//           noParticipationCount += 1;
//           await updateDoc(userProfileRef, { noParticipationCount });
//           setNoCount(noParticipationCount);

//           if (noParticipationCount >= 5) {
//             setHideParticipation(true);
//           }
//         }
//         navigate("/");
//       }
//     } catch (error) {
//       console.error("Error handling participation choice:", error);
//     }
//   };

//   if (loading) {
//     return <div>Loading...</div>;
//   }

//   if (!currentUser) {
//     return <Navigate to="/login" />;
//   }

//   return (
//     <Container
//       className="d-flex justify-content-center align-items-center"
//       style={{ minHeight: "50vh" }}
//     >
//       <Card
//         className="p-4 shadow-sm"
//         style={{ width: "100%", maxWidth: "600px" }}
//       >
//         <Card.Body>
//           {!hideParticipation && (
//             <>
//               <h5 className="text-center mb-4">
//                 Participate in Kenkey Gift Prize, 25 Cedis per day
//               </h5>
//               <Form>
//                 <Form.Group as={Row} className="mb-3">
//                   <Col sm={12}>
//                     <Form.Check
//                       type="radio"
//                       id="isPlayerYes"
//                       name="isPlayer"
//                       label="Yes, I want to participate"
//                       onChange={() => handleParticipationChoice(true)}
//                     />
//                   </Col>
//                   <Col sm={12}>
//                     <Form.Check
//                       type="radio"
//                       id="isPlayerNo"
//                       name="isPlayer"
//                       label="No, I don't want to participate"
//                       onChange={() => handleParticipationChoice(false)}
//                     />
//                   </Col>
//                   <Col sm={12}>
//                     <Form.Check
//                       type="radio"
//                       id="hidePage"
//                       name="isPlayer"
//                       label="Do not display this page again"
//                       onChange={() => handleParticipationChoice(false, true)}
//                     />
//                   </Col>
//                 </Form.Group>
//                 <Button
//                   variant="primary"
//                   className="w-100"
//                   onClick={() => navigate("/")}
//                 >
//                   Proceed
//                 </Button>
//               </Form>
//             </>
//           )}
//         </Card.Body>
//       </Card>
//     </Container>
//   );
// }

// export default Participation;

import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { projectAuth, projectFirestore } from "../../firebase/config";
import { doc, updateDoc, getDoc } from "firebase/firestore";
import { useUser } from "../contexts/UserContext";
import { Navigate } from "react-router-dom";
import { Container, Form, Button, Row, Col, Card } from "react-bootstrap";

function Participation() {
  const [noCount, setNoCount] = useState(0);
  const [hideParticipation, setHideParticipation] = useState(false);
  const [selectedChoice, setSelectedChoice] = useState(null);
  const navigate = useNavigate();
  const { currentUser, loading } = useUser(); // Access the currentUser from the UserContext

  useEffect(() => {
    if (currentUser) {
      checkParticipationStatus(currentUser.uid);
    }
  }, [currentUser]);

  const checkParticipationStatus = async (uid) => {
    try {
      const userProfileRef = doc(projectFirestore, "users", uid);
      const userProfileSnapshot = await getDoc(userProfileRef);
      if (userProfileSnapshot.exists()) {
        const { noParticipationCount = 0, hideParticipationPage = false } =
          userProfileSnapshot.data();
        setNoCount(noParticipationCount);
        if (hideParticipationPage || noParticipationCount >= 5) {
          setHideParticipation(true);
          navigate("/");
        }
      }
    } catch (error) {
      console.error("Error checking participation status:", error);
    }
  };

  const handleProceed = async () => {
    const currentUser = projectAuth.currentUser;
    if (!currentUser) {
      console.error("No current user");
      return;
    }

    const userProfileRef = doc(projectFirestore, "users", currentUser.uid);
    console.log("UserProfileRef:", userProfileRef.path);

    try {
      const userProfileSnapshot = await getDoc(userProfileRef);

      if (userProfileSnapshot.exists()) {
        let { noParticipationCount = 0 } = userProfileSnapshot.data();

        if (selectedChoice === "yes") {
          await updateDoc(userProfileRef, {
            isPlayer: true,
            hideParticipationPage: false,
          });
          alert("You are now a player! Enjoy the game!");
        } else if (selectedChoice === "no") {
          noParticipationCount += 1;
          await updateDoc(userProfileRef, { noParticipationCount });
          setNoCount(noParticipationCount);

          if (noParticipationCount >= 5) {
            setHideParticipation(true);
          }
        } else if (selectedChoice === "hide") {
          await updateDoc(userProfileRef, { hideParticipationPage: true });
          setHideParticipation(true);
        }
        navigate("/");
      }
    } catch (error) {
      console.error("Error handling participation choice:", error);
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!currentUser) {
    return <Navigate to="/login" />;
  }

  return (
    <Container
      className="d-flex justify-content-center align-items-center"
      style={{ minHeight: "50vh" }}
    >
      <Card
        className="p-4 shadow-sm"
        style={{ width: "100%", maxWidth: "600px" }}
      >
        <Card.Body>
          {!hideParticipation && (
            <>
              <h5 className="text-center mb-4">
                Participate in the daily 25 cedis, Kenkey Gift Prize
              </h5>
              <Form>
                <Form.Group as={Row} className="mb-3">
                  <Col sm={12}>
                    <Form.Check
                      type="radio"
                      id="isPlayerYes"
                      name="isPlayer"
                      label="Yes, I want to participate"
                      onChange={() => setSelectedChoice("yes")}
                    />
                  </Col>
                  <Col sm={12}>
                    <Form.Check
                      type="radio"
                      id="isPlayerNo"
                      name="isPlayer"
                      label="No, I don't want to participate"
                      onChange={() => setSelectedChoice("no")}
                    />
                  </Col>
                  <Col sm={12}>
                    <Form.Check
                      type="radio"
                      id="hidePage"
                      name="isPlayer"
                      label="Do not display this page again"
                      onChange={() => setSelectedChoice("hide")}
                    />
                  </Col>
                </Form.Group>
                <Button
                  variant="primary"
                  className="w-100"
                  onClick={handleProceed}
                >
                  Proceed
                </Button>
              </Form>
            </>
          )}
        </Card.Body>
      </Card>
    </Container>
  );
}

export default Participation;
