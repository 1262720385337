import React, { useState, useEffect } from 'react';
import { collection, getDocs } from 'firebase/firestore';
import { projectFirestore } from '../../firebase/config'; // Adjust the import path
import Accordion from 'react-bootstrap/Accordion';
import DOMPurify from 'dompurify';

const FAQs = () => {
    const [faqs, setFaqs] = useState([]);


    const SafeHtmlRenderer = ({ html }) => {
        return <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(html) }} />;
    };

    useEffect(() => {
        const fetchFaqs = async () => {
            const querySnapshot = await getDocs(collection(projectFirestore, 'faqs'));
            setFaqs(querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })));
        };
        fetchFaqs();
    }, []);

    return (
        <div className="container mt-5">
            <h2>Frequently Asked Questions</h2>
            <Accordion defaultActiveKey="0">
                {faqs.map((faq, index) => (
                    <Accordion.Item eventKey={index.toString()} key={faq.id}>
                        <Accordion.Header>{faq.question}</Accordion.Header>
                        <Accordion.Body>
                            <SafeHtmlRenderer html={faq.answer} />
                        </Accordion.Body>
                    </Accordion.Item>
                ))}
            </Accordion>
        </div>
    );
};

export default FAQs;
