import React, { useState, useEffect, useContext } from 'react';
import { projectFirestore, projectStorage } from '../../../firebase/config';
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import { collection, addDoc, serverTimestamp, getDocs, doc, getDoc, updateDoc, deleteDoc, query, where } from 'firebase/firestore';
import { UserContext } from '../../contexts/UserContext';
import { Form, Button, Container, Row, Col, ProgressBar, Card, Tabs, Tab } from 'react-bootstrap';

import { useParams, Link, useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

const AdvertForm = () => {


  const [categories, setCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('');
  const [selectedSubCategoryName, setSelectedSubCategoryName] = useState('');
  const [selectedSubCategoryId, setSelectedSubCategoryId] = useState('');
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [advertType, setAdvertType] = useState(''); // 'business' or 'individual'
  const [error, setError] = useState('');
  const { currentUser } = useContext(UserContext);
  const [images, setImages] = useState([]);
  const [imageUrls, setImageUrls] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [isEditMode, setIsEditMode] = useState(false);
  const [existingImages, setExistingImages] = useState([]);
  const [currentAdvertId, setCurrentAdvertId] = useState(null);
  const { advertId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchCategories = async () => {
      const querySnapshot = await getDocs(collection(projectFirestore, 'advertcategories'));
      setCategories(querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })));
    };
    fetchCategories();
  }, []);

  useEffect(() => {
    const fetchSubCategories = async () => {
      if (selectedCategory) {
        const categoryDocRef = doc(projectFirestore, 'advertcategories', selectedCategory);
        const categorySnap = await getDoc(categoryDocRef);
        if (categorySnap.exists() && categorySnap.data().subCategories) {
          setSubCategories(categorySnap.data().subCategories);
        } else {
          setSubCategories([]);
        }
      } else {
        setSubCategories([]); // Clear sub-categories if no category is selected
      }
    };
  
    fetchSubCategories();
  }, [selectedCategory]);
  
  useEffect(() => {
    const fetchAdvertData = async () => {
      if (advertId) { // Ensure advertId is available
        setIsEditMode(true); // Mark as edit mode
        const docRef = doc(projectFirestore, 'adverts', advertId);
        const docSnap = await getDoc(docRef);
  
        if (docSnap.exists()) {
          const advertData = docSnap.data();
          // Populate the form with the advert data
          setTitle(advertData.title);
          setDescription(advertData.description);
          setSelectedCategory(advertData.category);
          // Additional step: Set the category ID and sub-category ID if necessary
          setSelectedSubCategoryName(advertData.subCategory);
          setAdvertType(advertData.advertType);
          setImageUrls(advertData.images || []); // Handle images carefully, ensure it's an array
          setExistingImages(advertData.images || []);
          // Populate other fields as necessary
        } else {
          console.error("No such advert found!");
        }
      }
    };
  
    fetchAdvertData();
  }, [advertId]); // Depend on advertId

  useEffect(() => {
    // Cleanup URLs on unmount
    return () => {
        imageUrls.forEach(image => URL.revokeObjectURL(image.preview));
    };
}, [imageUrls]);

const resetFormFields = () => {
  setTitle('');
  setDescription('');
  setSelectedCategory('');
  setSelectedSubCategoryName('');
  setSelectedSubCategoryId(''); // Ensure this is reset
  setAdvertType('');
  setImages([]);
  setImageUrls([]);
  setExistingImages([]);
  setError('');
  // Reset any other state fields related to the form
};

  const handleImageChange = (e) => {
    if (e.target.files && e.target.files.length > 0) {
        const selectedFiles = [...e.target.files];
        // Limit the number of images
        const filesLimit = selectedFiles.slice(0, 5); // Adjust the limit as needed
        setImages(filesLimit);

        // Generate previews
        const filesPreview = filesLimit.map(file => 
            Object.assign(file, {
                preview: URL.createObjectURL(file)
            })
        );
        setImageUrls(filesPreview); // Assuming `imageUrls` is used for previews; adjust as per your state structure
    }
};

const removeImage = (index) => {
  const newImages = [...images];
  newImages.splice(index, 1);
  setImages(newImages);

  const newImageUrls = [...imageUrls];
  newImageUrls.splice(index, 1);
  setImageUrls(newImageUrls);
};



  const handleUpload = async () => {
    if (images.length === 0) {
      // No images to upload
      setError('No images selected for upload');
      return;
    }
    setUploading(true);
    setUploadProgress(0);
    
    const uploadPromises = images.map((image) => {
      const fileRef = ref(projectStorage, `advert-images/${image.name}-${uuidv4()}`);
      const uploadTask = uploadBytesResumable(fileRef, image);
      return new Promise((resolve, reject) => {
        uploadTask.on(
          'state_changed',
          (snapshot) => {
            const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            setUploadProgress(progress);
          },
          (error) => reject(error),
          () => getDownloadURL(uploadTask.snapshot.ref).then(resolve)
        );
      });
    });
  
    try {
      const newImageUrls = await Promise.all(uploadPromises);
      const allImageUrls = [...existingImages, ...newImageUrls];
      setImageUrls(allImageUrls); // Combine existing and new images
    } catch (error) {
      console.error('Error uploading images: ', error);
      setError('Error uploading images');
    } finally {
      setUploading(false);
    }
  };
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    // Filter out blob URLs from imageUrls if they accidentally got included.
    const validImageUrls = imageUrls.filter(url => typeof url === 'string');
  
    if (!title || !description || !selectedCategory || !selectedSubCategoryName || !advertType || validImageUrls.length === 0) {
      setError('Please fill in all fields and upload at least one image');
      return;
    }

     //   console.log("Fetched SubCategory ID fetchedSubCategoryID start: ", fetchedSubCategoryId); // Debug log
    console.log("selectedCategory start: ", selectedCategory); // Debug log
    console.log("selectedSubCategoryName start: ", selectedSubCategoryName); // Debug log
  
    // Direct use of selectedSubCategoryId, simplifying logic for both new posts and edits.
    let advertData = {
      title,
      description,
      category: selectedCategory,
      subCategory: selectedSubCategoryName,
      advertType,
      userId: currentUser?.uid,
      images: validImageUrls,
      subCategoryId: selectedSubCategoryId,
    };

      console.log("selectedCategory end: ", selectedCategory); // Debug log
    console.log("selectedSubCategoryName end: ", selectedSubCategoryName); // Debug log


  
    if (!isEditMode) {
      // Add the createdAt field for new posts
      advertData.createdAt = serverTimestamp();
    }
  
    setUploading(true); // Start the upload indicator
  
    try {
      if (isEditMode && advertId) {
        // Edit mode: Update existing document
        await updateDoc(doc(projectFirestore, 'adverts', advertId), advertData);
        console.log("Advert updated with ID: ", advertId);
      } else {
        // New post: Add new document
        const docRef = await addDoc(collection(projectFirestore, 'adverts'), advertData);
        console.log("Document written with ID: ", docRef.id);
      }
  
      // Reset form fields and states after successful submission
      resetFormFields();
  
      // Optionally redirect the user or show a success message
      navigate('/my-adverts'); // Redirect or show success message
  
    } catch (error) {
      console.error('Error saving advert:', error);
      setError('Failed to save advert');
    } finally {
      setUploading(false); // Stop the upload indicator
    }
  };
  
  const handleDelete = async () => {
    if (currentAdvertId) {
      const advertRef = doc(projectFirestore, 'adverts', currentAdvertId);
      await deleteDoc(advertRef);
      console.log("Advert deleted");
      // Redirect or update UI accordingly
    }
  };
  return (
    <Container>
      <Row className="justify-content-md-center">
        <Col xs={12} md={8}>
          <h2>{isEditMode ? 'Edit Advert' : 'Post an Advert'}</h2>
          {error && <div className="alert alert-danger">{error}</div>}
          <Form onSubmit={handleSubmit}>
            <Tabs defaultActiveKey="basicInfo" id="advert-form-tabs" className="mb-3">
              <Tab eventKey="basicInfo" title="Basic Information">
                <Form.Group className="mb-3">
                  <Form.Label>Title</Form.Label>
                  <Form.Control type="text" value={title} onChange={(e) => setTitle(e.target.value)} />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Description</Form.Label>
                  <Form.Control as="textarea" rows={3} value={description} onChange={(e) => setDescription(e.target.value)} />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Type of Advert</Form.Label>
                  <Form.Select required value={advertType} onChange={(e) => setAdvertType(e.target.value)}>
                    <option value="">Choose...</option>
                    <option value="Business">Business</option>
                    <option value="Individual">Individual</option>
                  </Form.Select>
                </Form.Group>
              </Tab>
              <Tab eventKey="category" title="Category">
                <Form.Group className="mb-3">
                  <Form.Label>Category</Form.Label>
                  <Form.Select value={selectedCategory} onChange={(e) => {
                    setSelectedCategory(e.target.value);
                    setSelectedSubCategoryName('');
                  }}>
                    <option value="">Select Category</option>
                    {categories.map((cat) => (
                      <option key={cat.id} value={cat.id}>{cat.name}</option>
                    ))}
                  </Form.Select>
                </Form.Group>
                {/* <Form.Group className="mb-3">
                  <Form.Label>Sub-Category</Form.Label>
                  <Form.Select value={selectedSubCategoryName} onChange={(e) => setSelectedSubCategoryName(e.target.value)}>
                    <option value="">Select Sub-Category</option>
                    {subCategories.map((sub) => (
                      <option key={sub.id} value={sub.name}>{sub.name}</option>
                    ))}
                  </Form.Select>
                </Form.Group> */}
                <Form.Group className="mb-3">
  <Form.Label>Sub-Category</Form.Label>
  <Form.Select 
    value={selectedSubCategoryName} 
    onChange={(e) => {
      const selectedName = e.target.value;
      const selectedSubCat = subCategories.find(sub => sub.name === selectedName);
      setSelectedSubCategoryName(selectedName);
      setSelectedSubCategoryId(selectedSubCat?.id || ""); // Ensures selectedSubCategoryId is always up to date
    }}
  >
    <option value="">Select Sub-Category</option>
    {subCategories.map((sub) => (
      <option key={sub.id} value={sub.name}>{sub.name}</option>
    ))}
  </Form.Select>
</Form.Group>

              </Tab>
              <Tab eventKey="images" title="Images">
                <Form.Group controlId="formFileMultiple" className="mb-3">
                  <Form.Label>Advert Images</Form.Label>
                  <Form.Control type="file" multiple onChange={handleImageChange} />
                  <Button variant="primary" onClick={handleUpload} disabled={uploading || images.length === 0} className="mt-2">
                    {uploading ? 'Uploading...' : 'Upload Images'}
                  </Button>
                  {uploading && <ProgressBar now={uploadProgress} label={`${Math.round(uploadProgress)}%`} className="mt-2" />}
                </Form.Group>
                <div className="image-previews">
                  {imageUrls.map((image, index) => (
                    <Card key={index} style={{ width: '18rem', display: 'inline-block', marginRight: '10px' }}>
                      <Card.Img variant="top" src={image.preview || image} style={{ maxWidth: '100px', maxHeight: '100px' }} />
                      <Card.Body>
                        <Button variant="danger" onClick={() => removeImage(index)}>Remove</Button>
                      </Card.Body>
                    </Card>
                  ))}
                </div>
              </Tab>
            </Tabs>
            <Button variant="primary" type="submit" disabled={uploading || imageUrls.length === 0}>
              {isEditMode ? 'Update Advert' : 'Post Advert'}
            </Button>
          </Form>
        </Col>
      </Row>
    </Container>
  );}
  
  export default AdvertForm;


  
  
// const handleSubmit = async (e) => {
  //   e.preventDefault();
  //   // Filter out blob URLs from imageUrls if they accidentally got included.
  //   const validImageUrls = imageUrls.filter(url => typeof url === 'string');

  //   if (!title || !description || !selectedCategory || !selectedSubCategoryName || !advertType || imageUrls.length === 0) {
  //     setError('Please fill in all fields and upload at least one image');
  //     return;
  //   }

  //   let fetchedSubCategoryId = selectedSubCategoryId; // Use the existing one by default
  //   console.log("Fetched SubCategory ID fetchedSubCategoryID start: ", fetchedSubCategoryId); // Debug log
  //   console.log("selectedCategory start: ", selectedCategory); // Debug log
  //   console.log("selectedSubCategoryName start: ", selectedSubCategoryName); // Debug log

  //   if (isEditMode && selectedSubCategoryName) {
  //     console.log("Edit Mode: Fetching subCategoryId for", selectedSubCategoryName);
  //     const categoryDocRef = doc(projectFirestore, 'advertcategories', selectedCategory);
  //     const categorySnap = await getDoc(categoryDocRef);
      
  //     if (categorySnap.exists()) {
  //         const categoryData = categorySnap.data();
  //         console.log("Category Data Fetched:", categoryData.name); // Debugging: Confirm the correct category data is fetched
          
  //         const subCategory = categoryData.subCategories.find(sub => sub.name === selectedSubCategoryName);
          
  //         if (subCategory) {
  //             fetchedSubCategoryId = subCategory.id;
  //             console.log("Fetched SubCategory ID:", fetchedSubCategoryId); // Should log "b11dcf76-a576-4e26-81cc-96f9f47a0ffa" for "Volvo"
  //         } else {
  //             console.error("SubCategory not found by name:", selectedSubCategoryName);
  //         }
  //     } else {
  //         console.error("Category not found by ID:", selectedCategory);
  //     }
  // } else if (!isEditMode) {
  //     // For new postings, ensure the logic here correctly assigns `fetchedSubCategoryId` based on user selection
  //     // This might involve similar logic to the above but based on current selection rather than fetching
  //     console.log("New Post: Using selectedSubCategoryName to assign fetchedSubCategoryId");
  //     // Assuming `selectedSubCategoryName` and `selectedCategory` are set correctly when user selects a sub-category
  //     // You may need to implement a similar logic as in editMode to fetch and assign the correct subCategoryId for new posts
  // }
  
    

  //   let advertData = {
  //     title,
  //     description,
  //     category: selectedCategory,
  //     subCategory: selectedSubCategoryName,
  //     // subCategoryId: selectedSubCategoryId,
  //     advertType,
  //     userId: currentUser?.uid,
  //     // images: imageUrls,
  //     images: validImageUrls,
  //     subCategoryId: fetchedSubCategoryId, // Use fetched or existing subCategoryId
      
  //   };
  //   console.log("Fetched SubCategory ID end: ", fetchedSubCategoryId); 
  //   console.log("selectedCategory end: ", selectedCategory); // Debug log
  //   console.log("selectedSubCategoryName end: ", selectedSubCategoryName); // Debug log




  //   if (!isEditMode) {
  //     // If not in edit mode, add the createdAt field
  //     advertData = { ...advertData, createdAt: serverTimestamp() };
  //   }
  
  //   setUploading(true); // Start the upload indicator
  
  //   try {
  //     if (isEditMode && advertId) {
  //       // Edit mode: Update existing document
  //       await updateDoc(doc(projectFirestore, 'adverts', advertId), advertData);
  //       console.log("Advert updated with ID: ", advertId);
  //     } else {
       
  //       const docRef = await addDoc(collection(projectFirestore, 'adverts'), advertData);
  //       console.log("Document written with ID: ", docRef.id);
  //     }
  
  //     // Reset form fields and states after successful submission
  //     resetFormFields();
  
  //     // Optionally redirect the user or show a success message
  //     navigate('/my-adverts'); // Redirect or show success message
  
  //   } catch (error) {
  //     console.error('Error saving advert:', error);
  //     setError('Failed to save advert');
  //   } finally {
  //     setUploading(false); // Stop the upload indicator
  //   }
  // };
  
  // Function to reset form fields
  // const resetFormFields = () => {
  //   setTitle('');
  //   setDescription('');
  //   setSelectedCategory('');
  //   setSelectedSubCategoryName('');
  //   setSelectedSubCategoryId('');
  //   setAdvertType('');
  //   setImages([]);
  //   setImageUrls([]);
  //   setExistingImages([]);
  //   setError('');
  //   // Reset any other state fields related to the form
  // };


//   return (
//     <Container>
//       <Row className="justify-content-md-center">
//         <Col xs={12} md={8}>
//           <h2>{isEditMode ? 'Edit Advert' : 'Post an Advert'}</h2>
//           {error && <div className="alert alert-danger">{error}</div>}
//           <Form onSubmit={handleSubmit}>
//             <Form.Group className="mb-3">
//               <Form.Label>Title</Form.Label>
//               <Form.Control type="text" value={title} onChange={(e) => setTitle(e.target.value)} />
//             </Form.Group>
//             <Form.Group className="mb-3">
//               <Form.Label>Description</Form.Label>
//               <Form.Control as="textarea" rows={3} value={description} onChange={(e) => setDescription(e.target.value)} />
//             </Form.Group>
//             <Form.Group className="mb-3">
//               <Form.Label>Category</Form.Label>
//               {/* <Form.Select value={selectedCategory} onChange={(e) => {
//                 setSelectedCategory(e.target.value);
//                 setSelectedSubCategoryName('');
//                 setSelectedSubCategoryId('');
//               }}>
//                 <option>Select Category</option>
//                 {categories.map((cat) => (
//                   <option key={cat.id} value={cat.name}>{cat.name}</option>
//                 ))}
//               </Form.Select> */}

//               <Form.Select 
//   value={selectedCategory} 
//   onChange={(e) => {
//     setSelectedCategory(e.target.value);
//     setSelectedSubCategoryName(''); // Clear the selected sub-category
//   }}
// >
//   <option value="">Select Category</option>
//   {categories.map((cat) => (
//     <option key={cat.id} value={cat.id}>{cat.name}</option>
//   ))}
// </Form.Select>

//             </Form.Group>
//             <Form.Group className="mb-3">
//               <Form.Label>Sub-Category</Form.Label>

//               {/* <Form.Select value={selectedSubCategoryName} onChange={(e) => {
//                 const selected = categories.find(cat => cat.name === selectedCategory)?.subCategories.find(sub => sub.name === e.target.value);
//                 setSelectedSubCategoryName(selected?.name || '');
//                 setSelectedSubCategoryId(selected?.id || '');
//               }}>
//                 <option>Select Sub-Category</option>
//                 {selectedCategory && categories.find(cat => cat.name === selectedCategory)?.subCategories.map((sub) => (
//                   <option key={sub.id} value={sub.name}>{sub.name}</option>
//                 ))}
//               </Form.Select> */}
//               <Form.Select 
//   value={selectedSubCategoryName} 
//   onChange={(e) => setSelectedSubCategoryName(e.target.value)}
// >
//   <option value="">Select Sub-Category</option>
//   {subCategories.map((sub) => (
//     <option key={sub.id} value={sub.name}>{sub.name}</option>
//   ))}
// </Form.Select>

//             </Form.Group>
//             <Form.Group className="mb-3">
//               <Form.Label>Type of Advert</Form.Label>
//               <Form.Select required value={advertType} onChange={(e) => setAdvertType(e.target.value)}>
//                 <option value="">Choose...</option>
//                 <option value="Business">Business</option>
//                 <option value="Individual">Individual</option>
//               </Form.Select>
//             </Form.Group>
//             {/* <Form.Group controlId="formFileMultiple" className="mb-3">
//               <Form.Label>Advert Images</Form.Label>
//               <Form.Control type="file" multiple onChange={handleImageChange} />
//               <Button variant="primary" onClick={handleUpload} disabled={uploading || images.length === 0} className="mt-2">
//                 {uploading ? 'Uploading...' : 'Upload Images'}
//               </Button>
//               {uploading && <ProgressBar now={uploadProgress} label={`${Math.round(uploadProgress)}%`} className="mt-2" />}
//             </Form.Group>
//             <div>
//               {imageUrls.map((url, index) => (
//                 <img key={index} src={url} alt="Advert" style={{ maxWidth: '100px', maxHeight: '100px', marginRight: '5px' }} />
//               ))}
//             </div>
//             <Button variant="primary" type="submit" disabled={uploading || imageUrls.length === 0}>
//               {uploading ? (isEditMode ? 'Updating...' : 'Posting...') : (isEditMode ? 'Update Advert' : 'Post Advert')}
//             </Button> */}

//             <Form.Group controlId="formFileMultiple" className="mb-3">
//     <Form.Label>Advert Images</Form.Label>
//     <Form.Control type="file" multiple onChange={handleImageChange} />
//     <Button variant="primary" onClick={handleUpload} disabled={uploading || images.length === 0} className="mt-2">
//         {uploading ? 'Uploading...' : 'Upload Images'}
//     </Button>
//     {uploading && <ProgressBar now={uploadProgress} label={`${Math.round(uploadProgress)}%`} className="mt-2" />}
// </Form.Group>
// {/* Updated Image Previews and Removal Section */}
// <div className="image-previews">
//     {imageUrls.map((image, index) => (
//         <div key={index} className="image-preview" style={{ display: 'inline-block', marginRight: '10px' }}>
//             <img src={image.preview || image} alt="Preview" style={{ maxWidth: '100px', maxHeight: '100px', display: 'block' }} />
//             <Button variant="danger" onClick={() => removeImage(index)} style={{ display: 'block' }}>Remove</Button>
//         </div>
//     ))}
// </div>
// <Button variant="primary" type="submit" disabled={uploading || imageUrls.length === 0}>
//     {uploading ? (isEditMode ? 'Updating...' : 'Posting...') : (isEditMode ? 'Update Advert' : 'Post Advert')}
// </Button>



//           </Form>
//         </Col>
//       </Row>
//     </Container>
//   );
// };

// export default AdvertForm;

  



   // Only fetch new subCategoryId if we're editing and the subCategory might have changed
    // if (isEditMode && selectedSubCategoryName) {
    //   console.log("Edit Mode: Fetching subCategoryId", fetchedSubCategoryId ); // Debug log
    //     const q = query(collection(projectFirestore, 'advertcategories'), where("name", "==", selectedCategory));
    //     const querySnapshot = await getDocs(q);
    //     const categoryDoc = querySnapshot.docs.find(doc => doc.data().name === selectedCategory);
    //     if (categoryDoc) {
    //         const subCategory = categoryDoc.data().subCategories.find(sub => sub.name === selectedSubCategoryName);
    //         if (subCategory) {
    //             fetchedSubCategoryId = subCategory.id;
    //             console.log("Fetched SubCategory ID: ", fetchedSubCategoryId); // Debug log
    //         }
    //     }
    // }

    // if (isEditMode && selectedSubCategoryName) {
    //   console.log("Edit Mode: Fetching subCategoryId"); // Debug log
    //   const q = query(collection(projectFirestore, 'advertcategories'), where("name", "==", selectedCategory));
    //   const querySnapshot = await getDocs(q);
    //   const categoryDoc = querySnapshot.docs.find(doc => doc.data().name === selectedCategory);
    //   if (categoryDoc) {
    //       const subCategory = categoryDoc.data().subCategories.find(sub => sub.name === selectedSubCategoryName);
    //       if (subCategory) {
    //           fetchedSubCategoryId = subCategory.id;
    //           console.log("Fetched SubCategory ID: ", fetchedSubCategoryId); // Debug log
    //       } else {
    //           console.error("SubCategory not found by name:", selectedSubCategoryName);
    //       }
    //   } else {
    //       console.error("Category not found by name:", selectedCategory);
    //   }
    // }