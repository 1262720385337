// import React, { useState, useEffect } from "react";
// import { Link } from "react-router-dom";
// import { collection, getDocs } from "firebase/firestore";
// import { projectFirestore } from "../../../firebase/config";
// import { Container, Table, Button, Card, Row, Col } from "react-bootstrap";
// import { format } from "date-fns"; // Import format from date-fns

// // Helper function to format the date using date-fns
// const formatDate = (timestamp) => {
//   try {
//     if (!timestamp || !timestamp.seconds) return "N/A";
//     const date = new Date(timestamp.seconds * 1000);
//     return format(date, "dd-MM-yyyy");
//   } catch (error) {
//     console.error("Error formatting date:", error);
//     return "N/A";
//   }
// };

// const UserList = () => {
//   const [users, setUsers] = useState([]);

//   useEffect(() => {
//     const fetchUsers = async () => {
//       const querySnapshot = await getDocs(
//         collection(projectFirestore, "users")
//       );
//       const usersData = querySnapshot.docs.map((doc) => ({
//         id: doc.id,
//         ...doc.data(),
//       }));
//       setUsers(usersData);
//     };

//     fetchUsers();
//   }, []);

//   return (
//     <Container fluid className="pt-5">
//       <Container fluid className="pt-5"></Container>
//       <Row className="mb-4">
//         <Col>
//           <h2>User Accounts</h2>
//         </Col>
//         <Col className="text-end">
//           <h4>Total Users: {users.length}</h4>
//         </Col>
//       </Row>
//       <Card>
//         <Card.Body>
//           <Table striped bordered hover>
//             <thead>
//               <tr>
//                 <th>User ID</th>
//                 <th>Display Name</th>
//                 <th>Phone Number</th>
//                 <th>Email</th>
//                 <th>Creation Date</th>
//                 <th>Actions</th>
//               </tr>
//             </thead>
//             <tbody>
//               {users.map((user) => (
//                 <tr key={user.id}>
//                   <td>
//                     {user.creationDate ? formatDate(user.creationDate) : "N/A"}
//                   </td>
//                   <td>{user.id}</td>
//                   <td>{user.displayName}</td>
//                   <td>{user.phoneNumber}</td>
//                   <td>{user.email}</td>

//                   <td>
//                     <Link to={`/user/${user.id}`}>
//                       <Button variant="primary">View Details</Button>
//                     </Link>
//                   </td>
//                 </tr>
//               ))}
//             </tbody>
//           </Table>
//         </Card.Body>
//       </Card>
//     </Container>
//   );
// };

// export default UserList;

import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { collection, getDocs } from "firebase/firestore";
import { projectFirestore } from "../../../firebase/config";
import { Container, Table, Button, Card, Row, Col } from "react-bootstrap";
import { format } from "date-fns"; // Import format from date-fns

// Helper function to format the date using date-fns
const formatDate = (timestamp) => {
  try {
    if (!timestamp || !timestamp.seconds) return "N/A";
    const date = new Date(timestamp.seconds * 1000);
    return format(date, "dd-MM-yyyy");
  } catch (error) {
    console.error("Error formatting date:", error);
    return "N/A";
  }
};

const UserList = () => {
  const [users, setUsers] = useState([]);

  useEffect(() => {
    const fetchUsers = async () => {
      const querySnapshot = await getDocs(
        collection(projectFirestore, "users")
      );
      const usersData = querySnapshot.docs
        .map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }))
        .sort((a, b) => {
          if (!a.creationDate && !b.creationDate) return 0;
          if (!a.creationDate) return 1;
          if (!b.creationDate) return -1;
          return b.creationDate.seconds - a.creationDate.seconds;
        });
      setUsers(usersData);
    };

    fetchUsers();
  }, []);

  return (
    <Container fluid className="pt-5">
      <Row className="mb-4">
        <Col>
          <h2>User Accounts</h2>
        </Col>
        <Col className="text-end">
          <h4>Total Users: {users.length}</h4>
        </Col>
      </Row>
      <Card>
        <Card.Body>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Creation Date</th>
                <th>User ID</th>
                <th>Display Name</th>
                <th>Phone Number</th>
                <th>Email</th>
                <th>Is Player</th>
                <th>FCM Token</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {users.map((user) => (
                <tr key={user.id}>
                  <td>
                    {user.creationDate ? formatDate(user.creationDate) : "N/A"}
                  </td>
                  <td>{user.id}</td>
                  <td>{user.displayName}</td>
                  <td>{user.phoneNumber}</td>
                  <td>{user.email}</td>
                  <td>{user.isPlayer ? "Yes" : "No"}</td>
                  <td>{user.fcmToken ? "Yes" : "No"}</td>
                  <td>
                    <Link to={`/user/${user.id}`}>
                      <Button variant="primary">View Details</Button>
                    </Link>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Card.Body>
      </Card>
    </Container>
  );
};

export default UserList;
