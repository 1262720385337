import React from "react";
import EmailComponent from "./EmailComponent";

const EmailTest = () => {
  console.log("Rendering EmailTest component");
  return (
    <div>
      <h2>Email Test</h2>
      <EmailComponent />
    </div>
  );
};

export default EmailTest;
