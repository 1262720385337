// import React, { useState, useEffect } from 'react';
// import { useNavigate } from 'react-router-dom';
// import { Form, Button } from 'react-bootstrap'; // Importing components from react-bootstrap

// import GoogleGeolocations from '../Geolocations/Google/GoogleGeolocation';
// import axios from 'axios';

// const AddEditAddressForm = ({ address, onSave, onCancel }) => {
//   const navigate = useNavigate();

//   const initialLat = 6.700071;
//   const initialLng = -1.630783;
//   const [mapCenter, setMapCenter] = useState({ lat: initialLat, lng: initialLng });
//   const [formState, setFormState] = useState({
//     fullName: '',
//     street: '',
//     city: '',
//     state: '',
//     zipCode: '',
//     country: '',
//     latitude: '',
//     longitude: '',
//     addressType: ''
//   });

//   useEffect(() => {
//     if (address) {
//       setFormState({
//         fullName: address.fullName || '',
//         street: address.street || '',
//         city: address.city || '',
//         state: address.state || '',
//         zipCode: address.zipCode || '',
//         country: address.country || '',
//         latitude: address.latitude || '',
//         longitude: address.longitude || '',
//         addressType: address.addressType || ''
//       });
//     } else {
//       setFormState({
//         fullName: '',
//         street: '',
//         city: '',
//         state: '',
//         zipCode: '',
//         country: '',
//         latitude: '',
//         longitude: '',
//         addressType: ''
//       });
//     }
//   }, [address]);

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setFormState(prevState => ({
//       ...prevState,
//       [name]: value
//     }));
//   };

//   const handleMapSelect = (lat, lng) => {
//     setFormState(prevState => ({
//       ...prevState,
//       latitude: lat.toString(),
//       longitude: lng.toString()
//     }));
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     if (!formState.addressType) {
//       alert('Please select an address type.');
//       return;
//     }

//     const apiKey = 'YOUR_API_KEY'; // Replace with your Google Maps API key
//     const fullAddress = `${formState.street}, ${formState.city}, ${formState.state}, ${formState.zipCode}, ${formState.country}`;

//     // try {
//     //   const response = await axios.get(`https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(fullAddress)}&key=${apiKey}`);
//     //   if (response.data.results.length > 0) {
//     //     const { lat, lng } = response.data.results[0].geometry.location;
//     //     setFormState(prevState => ({
//     //       ...prevState,
//     //       latitude: lat.toString(),
//     //       longitude: lng.toString()
//     //     }));
//     //     updateMapCenter(lat, lng);
//     //   } else {
//     //     alert('No results found for the specified address.');
//     //   }
//     // } catch (error) {
//     //   console.error('Geocoding API error:', error);
//     //   alert('Failed to fetch coordinates for the address.');
//     // }

//     onSave(formState);
//   };

//   return (
//     <Form onSubmit={handleSubmit}>
//       <Form.Group className="mb-3" controlId="fullName">
//         <Form.Label>Full Name</Form.Label>
//         <Form.Control type="text" name="fullName" value={formState.fullName} onChange={handleChange} placeholder="Enter full name" />
//       </Form.Group>
//       <Form.Group className="mb-3" controlId="street">
//         <Form.Label>Street</Form.Label>
//         <Form.Control type="text" name="street" value={formState.street} onChange={handleChange} placeholder="Enter street" />
//       </Form.Group>
//       <Form.Group className="mb-3" controlId="city">
//         <Form.Label>City</Form.Label>
//         <Form.Control type="text" name="city" value={formState.city} onChange={handleChange} placeholder="Enter city" />
//       </Form.Group>
//       <Form.Group className="mb-3" controlId="state">
//         <Form.Label>State</Form.Label>
//         <Form.Control type="text" name="state" value={formState.state} onChange={handleChange} placeholder="Enter state" />
//       </Form.Group>
//       <Form.Group className="mb-3" controlId="zipCode">
//         <Form.Label>Zip Code</Form.Label>
//         <Form.Control type="text" name="zipCode" value={formState.zipCode} onChange={handleChange} placeholder="Enter zip code" />
//       </Form.Group>
//       <Form.Group className="mb-3" controlId="country">
//         <Form.Label>Country</Form.Label>
//         <Form.Control type="text" name="country" value={formState.country} onChange={handleChange} placeholder="Enter country" />
//       </Form.Group>
//       <GoogleGeolocations onLocationSelect={handleMapSelect} />
//       <Form.Group className="mb-3" controlId="latitude">
//         <Form.Label>Latitude</Form.Label>
//         <Form.Control type="text" name="latitude" value={formState.latitude} onChange={handleChange} placeholder="Latitude" />
//       </Form.Group>
//       <Form.Group className="mb-3" controlId="longitude">
//         <Form.Label>Longitude</Form.Label>
//         <Form.Control type="text" name="longitude" value={formState.longitude} onChange={handleChange} placeholder="Longitude" />
//       </Form.Group>
//       <Form.Group className="mb-3" controlId="addressType">
//         <Form.Label>Address Type</Form.Label>
//         <Form.Select name="addressType" value={formState.addressType} onChange={handleChange} required>
//           <option value="" disabled>Select Address Type</option>
//           <option value="Pickup">Pickup</option>
//           <option value="Delivery">Delivery</option>
//         </Form.Select>
//       </Form.Group>
//       <Button variant="primary" type="submit">
//         Save
//       </Button>
//       <Button variant="secondary" onClick={onCancel}>
//         Cancel
//       </Button>
//     </Form>
//   );
// };

// export default AddEditAddressForm;


import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Form, Button } from 'react-bootstrap'; // Importing components from react-bootstrap

import GoogleGeolocations from '../Geolocations/Google/GoogleGeolocation';
import axios from 'axios';

const AddEditAddressForm = ({ address, onSave, onCancel }) => {
  const navigate = useNavigate();

  const initialLat = 6.700071;
  const initialLng = -1.630783;
  const [mapCenter, setMapCenter] = useState({ lat: initialLat, lng: initialLng });
  const [formState, setFormState] = useState({
    fullName: '',
    street: '',
    city: '',
    state: '',
    zipCode: '',
    country: '',
    latitude: '',
    longitude: '',
    addressType: ''
  });

  useEffect(() => {
    if (address) {
      setFormState({
        fullName: address.fullName || '',
        street: address.street || '',
        city: address.city || '',
        state: address.state || '',
        zipCode: address.zipCode || '',
        country: address.country || '',
        latitude: address.latitude || '',
        longitude: address.longitude || '',
        addressType: address.addressType || ''
      });
    } else {
      setFormState({
        fullName: '',
        street: '',
        city: '',
        state: '',
        zipCode: '',
        country: '',
        latitude: '',
        longitude: '',
        addressType: ''
      });
    }
  }, [address]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormState(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleMapSelect = (lat, lng) => {
    setFormState(prevState => ({
      ...prevState,
      latitude: lat.toString(),
      longitude: lng.toString()
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!formState.addressType) {
      alert('Please select an address type.');
      return;
    }

    onSave(formState);
  };

  return (
    <Form onSubmit={handleSubmit} className="mt-4">
      <Form.Group controlId="fullName" className="mb-3">
        <Form.Label>Full Name</Form.Label>
        <Form.Control type="text" name="fullName" value={formState.fullName} onChange={handleChange} placeholder="Enter full name" />
      </Form.Group>
      <Form.Group controlId="street" className="mb-3">
        <Form.Label>Street</Form.Label>
        <Form.Control type="text" name="street" value={formState.street} onChange={handleChange} placeholder="Enter street" />
      </Form.Group>
      <Form.Group controlId="city" className="mb-3">
        <Form.Label>City</Form.Label>
        <Form.Control type="text" name="city" value={formState.city} onChange={handleChange} placeholder="Enter city" />
      </Form.Group>
      <Form.Group controlId="state" className="mb-3">
        <Form.Label>Region</Form.Label>
        <Form.Control type="text" name="state" value={formState.state} onChange={handleChange} placeholder="Enter state" />
      </Form.Group>
      <Form.Group controlId="zipCode" className="mb-3">
        <Form.Label>Post Code</Form.Label>
        <Form.Control type="text" name="zipCode" value={formState.zipCode} onChange={handleChange} placeholder="Enter zip code" />
      </Form.Group>
      <Form.Group controlId="country" className="mb-3">
        <Form.Label>Country</Form.Label>
        <Form.Control type="text" name="country" value={formState.country} onChange={handleChange} placeholder="Enter country" />
      </Form.Group>
      <GoogleGeolocations onLocationSelect={handleMapSelect} />
      <Form.Group controlId="addressType" className="mb-3">
        <Form.Label>Address Type</Form.Label>
        <Form.Select name="addressType" value={formState.addressType} onChange={handleChange} required>
          <option value="" disabled>Select Address Type</option>
          <option value="Pickup">Pickup</option>
          <option value="Delivery">Delivery</option>
        </Form.Select>
      </Form.Group>
      <Button variant="primary" type="submit" className="me-2">
        Save
      </Button>
      <Button variant="secondary" onClick={onCancel}>
        Cancel
      </Button>
    </Form>
  );
};

export default AddEditAddressForm;
