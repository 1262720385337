

// import React from "react";
// import { Modal, Button } from "react-bootstrap";

// function NotificationModal({ show, onHide, title, body, action }) {
//   return (
//     <Modal show={show} onHide={onHide} backdrop="static" keyboard={false}>
//       <Modal.Header closeButton>
//         <Modal.Title>{title}</Modal.Title>
//       </Modal.Header>
//       <Modal.Body>{body}</Modal.Body>
//       <Modal.Footer>
//         {action && (
//           <Button variant="primary" onClick={action.onClick}>
//             {action.label}
//           </Button>
//         )}
//         <Button variant="secondary" onClick={onHide}>
//           Close
//         </Button>
//       </Modal.Footer>
//     </Modal>
//   );
// }

// export default NotificationModal;


import React from "react";
import { Modal, Button } from "react-bootstrap";

function NotificationModal({ show, onHide, title, body, action }) {
  const handleActionClick = () => {
    if (action.onClick) {
      action.onClick(); // This will navigate the user
    }
    onHide(); // This will close the modal
  };

  return (
    <Modal show={show} onHide={onHide} backdrop="static" keyboard={false}>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{body}</Modal.Body>
      <Modal.Footer>
        {action && (
          <Button variant="secondary" onClick={handleActionClick}>
            {action.label}
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
}

export default NotificationModal;
