import React from 'react';
import { Container, Row, Col, Card, Button, ListGroup } from 'react-bootstrap';

const AcademicEducationalDetail = ({ announcement }) => {
  // Placeholder data for demonstration
  const placeholderData = {
    title: "Scholarship Program for STEM Students",
    applicationDeadline: "2024-09-30",
    description: "We are excited to announce a scholarship program for students pursuing degrees in science, technology, engineering, and mathematics (STEM). The scholarship aims to support outstanding students with financial aid for their academic pursuits.",
    eligibilityCriteria: "Open to all students currently enrolled in or accepted to a full-time STEM undergraduate or graduate program. Applicants must have a GPA of 3.5 or higher.",
    scholarshipAmount: "$5,000 per academic year",
    howToApply: "Submit your application along with transcripts, a resume, and a personal statement outlining your career goals in STEM to scholarships@edu.org.",
    contactInfo: "For questions, please email scholarships@edu.org or call (555) 987-6543.",
    additionalInfo: "Ten scholarships will be awarded based on academic excellence, leadership, and community involvement.",
  };

  // Replace placeholderData with actual announcement data when integrating
  const data = announcement || placeholderData;

  return (
    <Container className="mt-5">
      <Row>
        <Col>
          <Card>
            <Card.Body>
              <Card.Title>{data.title}</Card.Title>
              <Card.Subtitle className="mb-2 text-muted">Application Deadline: {data.applicationDeadline}</Card.Subtitle>
              <Card.Text>{data.description}</Card.Text>
              <ListGroup variant="flush" className="mb-3">
                <ListGroup.Item><strong>Eligibility Criteria:</strong> {data.eligibilityCriteria}</ListGroup.Item>
                <ListGroup.Item><strong>Scholarship Amount:</strong> {data.scholarshipAmount}</ListGroup.Item>
                <ListGroup.Item><strong>How to Apply:</strong> {data.howToApply}</ListGroup.Item>
                <ListGroup.Item><strong>Contact Information:</strong> {data.contactInfo}</ListGroup.Item>
              </ListGroup>
              <Card.Text>{data.additionalInfo}</Card.Text>
              <Button variant="primary" href={`mailto:${data.contactInfo.split(' ')[1]}`}>Email Us</Button>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default AcademicEducationalDetail;
