
import React, { useState, useEffect } from 'react';
import { Form, Button, Container } from 'react-bootstrap';
import { doc, setDoc, getDoc } from 'firebase/firestore';
import { projectFirestore } from '../../../firebase/config';
import { useParams, useNavigate } from 'react-router-dom';

const CategoryConfigurationMappingForm = () => {
  const [mappingName, setMappingName] = useState('');
  const [categoryName, setCategoryName] = useState('');
  const [subCategoryName, setSubCategoryName] = useState('');
  const [configurationId, setConfigurationId] = useState('');
  const { mappingId } = useParams(); // This is the id parameter from the URL
  const navigate = useNavigate();

  console.log("Captured mappingId:", mappingId);


  // Load existing data if in edit mode
  useEffect(() => {
    const fetchMapping = async () => {
      if (mappingId) {
        const docRef = doc(projectFirestore, 'categoryConfigurationMapping', mappingId);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          const data = docSnap.data();
          setMappingName(mappingId); // Document ID as mapping name
          setCategoryName(data.categoryName);
          setSubCategoryName(data.subCategoryName);
          setConfigurationId(data.configurationId);
        }
      }
    };
    fetchMapping();
  }, [mappingId]);




  const handleSubmit = async (e) => {
    e.preventDefault();
    const documentId = mappingId || (mappingName || `${categoryName}_${subCategoryName}`);
    const configRef = doc(projectFirestore, 'categoryConfigurationMapping', documentId);
    try {
      await setDoc(configRef, {
        categoryName,
        subCategoryName,
        configurationId,
      }, { merge: true });
      alert('Configuration mapping saved!');
      navigate('/category-configuration-mapping-form');
    } catch (error) {
      console.error('Error saving configuration:', error);
      alert('Error saving configuration');
    }
  };

  const resetForm = () => {
    setMappingName('');
    setCategoryName('');
    setSubCategoryName('');
    setConfigurationId('');
  };




  return (
    <Container>
      <h2>{mappingId ? 'Edit' : 'Create'} Category Configuration Mapping</h2>
      <Form onSubmit={handleSubmit}>
        {mappingId ? (
          <Form.Group className="mb-3">
            <Form.Label>Mapping Name (ID)</Form.Label>
            <Form.Control
              type="text"
              readOnly
              defaultValue={mappingName}
            />
          </Form.Group>
        ) : (
          <Form.Group className="mb-3">
            <Form.Label>Mapping Name (Optional)</Form.Label>
            <Form.Control
              type="text"
              value={mappingName}
              placeholder="e.g., mappingPersonal"
              onChange={(e) => setMappingName(e.target.value)}
            />
          </Form.Group>
        )}
        <Form.Group className="mb-3">
          <Form.Label>Category Name</Form.Label>
          <Form.Control
            type="text"
            value={categoryName}
            placeholder="e.g., Announcements"
            onChange={(e) => setCategoryName(e.target.value)}
            required
          />
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>Sub-Category Name</Form.Label>
          <Form.Control
            type="text"
            value={subCategoryName}
            placeholder="e.g., Birthday Celebrations"
            onChange={(e) => setSubCategoryName(e.target.value)}
            required
          />
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>Form Configuration ID</Form.Label>
          <Form.Control
            type="text"
            value={configurationId}
            placeholder="e.g., personalFormConfig"
            onChange={(e) => setConfigurationId(e.target.value)}
            required
          />
        </Form.Group>
        <Button variant="primary" type="submit">{mappingId ? 'Update' : 'Save'} Mapping</Button>
        <Button variant="secondary" onClick={resetForm} className="ms-2">Reset</Button>
      </Form>
    </Container>
  );
};

export default CategoryConfigurationMappingForm;
