// DynamicDetailPage.js
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { doc, getDoc } from 'firebase/firestore';
import { projectFirestore } from '../../../firebase/config'; 
import AcademicEducationalDetail from './AcademicEducationalDetail';
import BusinessAnnouncementDetail from './BusinessAnnouncementDetail';
import CelebrationDetail from './CelebrationDetail';
import CommunityPublicInterestDetail from './CommunityPublicInterestDetail';
import MiscellaneousDetail from './MiscellaneousDetail';
import AnnouncementDetail from './AnnouncementDetail';

const categoryToComponentMap = {
    'Personal and Family Announcements': CelebrationDetail,
    'Business and Professional Announcements': BusinessAnnouncementDetail,
    'Community and Public Announcements': CommunityPublicInterestDetail,
    'Academic and Educational Announcements': AcademicEducationalDetail,
    // 'Miscellaneous Announcements': MiscellaneousDetail,
  };
  

const DynamicDetailPage = () => {
  const { announcementId } = useParams();
  const [announcement, setAnnouncement] = useState(null);

  useEffect(() => {
    const fetchAnnouncement = async () => {
      const docRef = doc(projectFirestore, 'announcements', announcementId);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        setAnnouncement(docSnap.data());
      }
    };
    fetchAnnouncement();
  }, [announcementId]);

  if (!announcement) {
    return <div>Loading...</div>;
  }

//   const DetailComponent = categoryToComponentMap[announcement.categoryName] || AnnouncementDetail;
//   const DetailComponent = categoryToDetailComponentMap[announcement.group] || DefaultDetailComponent;
  const DetailComponent = categoryToComponentMap[announcement.group] || MiscellaneousDetail;

  return <DetailComponent announcement={announcement} />;
};

export default DynamicDetailPage;