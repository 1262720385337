import React from "react";
import { Card, Row, Col } from "react-bootstrap";
import "./StatsStyle.css"; // Assuming you have an external CSS file for styles

const StatsDisplay = ({ unclaimedPercentage, paidPercentage, drawTimes }) => {
  return (
    <Row xs={1} md={3} className="g-4 mb-3">
      <Col>
        <Card className="text-center stat-card">
          <Card.Body>
            <Card.Title>Unclaimed Winnings</Card.Title>
            <Card.Text>
              <strong>{unclaimedPercentage}%</strong>
              <div className="stat-description">of gifts remain unclaimed</div>
            </Card.Text>
          </Card.Body>
        </Card>
      </Col>
      <Col>
        <Card className="text-center stat-card">
          <Card.Body>
            <Card.Title>Claimed and Paid</Card.Title>
            <Card.Text>
              <strong>{paidPercentage}%</strong>
              <div className="stat-description">of claims have been paid</div>
            </Card.Text>
          </Card.Body>
        </Card>
      </Col>
      <Col>
        <Card className="text-center stat-card">
          <Card.Body>
            <Card.Title>Daily Giveout's</Card.Title>
            <Card.Text>
              <strong>Two</strong>
              <div className="stat-description">@06.00 GMT & @12.00 GMT</div>
            </Card.Text>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
};

export default StatsDisplay;
