import { projectFirestore, messaging as messagingInstance } from "./config";
import { doc, getDoc, setDoc, Timestamp } from "firebase/firestore";
import { getToken, onMessage } from "firebase/messaging";

export const FCM_TOKEN_COLLECTION = "fcmTokens";
export const FCM_TOKEN_KEY = "fcmToken"; // key for storing FCM token in Firestore
const VAPID_KEY =
  "BLUYgZWYnMYBR9gTxyqVg4eAciTxAeCuvSPR5HsERN8Kcd7EO5m8pRKfnfB-7gG6p0M_DiAqJdUXQOuWfhQ4XFg";

export async function requestNotificationsPermissions() {
  console.log("Requesting notifications permission...");
  const permission = await Notification.requestPermission();
  console.log("Notification permission status:", permission);

  return permission === "granted";
}

export async function saveMessagingDeviceToken(uid) {
  console.log("Saving messaging device token...");

  if (!messagingInstance) {
    console.log("Messaging is not supported in this browser.");
    return null;
  }

  try {
    const fcmToken = await getToken(messagingInstance, { vapidKey: VAPID_KEY });
    console.log("FCM Token received:", fcmToken);

    if (fcmToken) {
      const tokenRef = doc(projectFirestore, FCM_TOKEN_COLLECTION, uid);
      await setDoc(
        tokenRef,
        { fcmToken, createdAt: Timestamp.now() },
        { merge: true }
      );
      console.log("FCM token saved to Firestore:", fcmToken);

      onMessage(messagingInstance, (message) => {
        console.log(
          "New foreground notification from Firebase Messaging!",
          message.notification
        );
        new Notification(message.notification.title, {
          body: message.notification.body,
        });
      });

      return fcmToken;
    } else {
      console.log("No FCM token received.");
      return null;
    }
  } catch (error) {
    console.error("Unable to get messaging token.", error);
    return null;
  }
}
