import React, { useState } from "react";
import { Dropdown } from "react-bootstrap";
import {
  manageCleaningServices,
  manageAdvertServices,
  manageDeliveryServices,
  manageAdminServices,
  manageGiftPrizeServices,
} from "../../components/utils/constants";

const Sidebar = ({ onComponentSelect }) => {
  const [selectedService, setSelectedService] = useState(null);

  // Define services array with Delivery Partner features
  const services = {
    "Kenkey Gift Prize": manageGiftPrizeServices,
    // "Advert Services": manageAdvertServices,
    // "Cleaning Services": manageCleaningServices,
    // "Delivery Services": manageDeliveryServices,
    // "Admin Services": manageAdminServices,

    // Add other services here
  };

  const handleServiceSelect = (service) => {
    setSelectedService(service);
  };

  const handleComponentSelect = (component) => {
    onComponentSelect(component);
  };

  return (
    <div style={{ backgroundColor: "#e6f7ff", padding: "20px" }}>
      <Dropdown>
        <Dropdown.Toggle variant="secondary" id="dropdown-basic">
          {selectedService ? selectedService : "Select a Service"}
        </Dropdown.Toggle>

        <Dropdown.Menu>
          {Object.keys(services).map((service) => (
            <Dropdown.Item
              key={service}
              onClick={() => handleServiceSelect(service)}
            >
              {service}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>

      {/* Render components of the selected service */}
      {selectedService && (
        <div style={{ border: "1px solid #ccc", padding: "10px" }}>
          {services[selectedService].map((component, index) => (
            <div
              key={component}
              onClick={() => handleComponentSelect(component)}
              style={{
                cursor: "pointer",
                marginBottom:
                  index !== services[selectedService].length - 1 ? "10px" : "0",
              }}
            >
              {component}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default Sidebar;

