import React, { useState, useEffect } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import { ListGroup, Row, Col, Card, Container, Button } from 'react-bootstrap';
import { doc, getDoc } from 'firebase/firestore';
import { projectFirestore } from '../../../firebase/config';
import { ICON_MAP } from '../../utils/iconMap';

const SubCategoryList = () => {
  const { categoryId } = useParams();
  const [subCategories, setSubCategories] = useState([]);
  const [category, setCategory] = useState({});
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchSubCategories = async () => {
      setLoading(true);
      try {
        const categoryRef = doc(projectFirestore, 'advertcategories', categoryId);
        const categorySnap = await getDoc(categoryRef);
        if (categorySnap.exists()) {
          setCategory(categorySnap.data());
          const options = categorySnap.data().options || [];
          const subCategoriesData = categorySnap.data().subCategories || [];
          
          // Group subcategories by options
          const groupedSubCategories = options.map(option => ({
            optionName: option,
            subCategories: subCategoriesData.filter(sub => sub.option === option)
          }));
          
          // Sort subcategories alphabetically
          const sortedGroupedSubCategories = groupedSubCategories.map(group => ({
            ...group,
            subCategories: group.subCategories.sort((a, b) => a.name.localeCompare(b.name))
          }));

          setSubCategories(sortedGroupedSubCategories);
        } else {
          console.log('No such category!');
        }
      } catch (error) {
        console.error('Error fetching sub-categories:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchSubCategories();
  }, [categoryId]);

  const handlePostAdvert = () => {
    navigate('/post-adverts');
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <Container>
      <Row>
        <Col md={3}>
          <h1>{category.name}</h1>
          <ListGroup>
            {subCategories.map(optionGroup => (
              <div key={optionGroup.optionName}>
                <div className="text-center">
                  {/* <h2>{optionGroup.optionName}</h2> Centered header */}
                </div>
                {optionGroup.subCategories.map(sub => (
                  <ListGroup.Item key={sub.subCategoryId} action as={Link} to={`/sub-category-posts/${categoryId}/${sub.subCategoryId}/${encodeURIComponent(sub.name)}`}>
                    {sub.name}
                  </ListGroup.Item>
                ))}
              </div>
            ))}
          </ListGroup>
        </Col>
        <Col md={9}>
          <div className="d-flex justify-content-between align-items-center mb-3">
            <h2>Sub-Categories in {category.name}</h2>
            <Button onClick={handlePostAdvert} variant="primary">
              + Post an Advert
            </Button>
          </div>
          {subCategories.map(optionGroup => (
            <div key={optionGroup.optionName} className="mb-5"> {/* Add margin bottom */}
            <div className="text-center mb-5"> {/* Add margin bottom */}
              <h2>{optionGroup.optionName}</h2> {/* Centered header */}
            </div>
              <Row xs={1} md={2} lg={3} className="g-4">
                {optionGroup.subCategories.map(sub => (
                  <Col key={sub.subCategoryId}>
                    <Link to={`/sub-category-posts/${categoryId}/${sub.subCategoryId}/${encodeURIComponent(sub.name)}`} style={{ textDecoration: 'none' }}>
                      <Card className="h-100 text-center">
                        <Card.Body className="d-flex flex-column justify-content-center align-items-center" style={{ height: '200px' }}>
                          {React.createElement(ICON_MAP[sub.icon1] || ICON_MAP['defaultIcon'], {
                            style: { fontSize: '35px', color: '#23843c' }
                          })}
                          <Card.Title className="mt-3" style={{ fontSize: '1.1rem' }}>{sub.name}</Card.Title>
                        </Card.Body>
                      </Card>
                    </Link>
                  </Col>
                ))}
              </Row>
            </div>
          ))}
        </Col>
      </Row>
    </Container>
  );
};

export default SubCategoryList;
