
import React, { useState, useEffect } from 'react';
import { Form, Button, Container, Table, Alert } from 'react-bootstrap';
import { projectFirestore } from '../../../firebase/config';
import { doc, updateDoc, getDoc, getDocs, collection, deleteDoc } from 'firebase/firestore';

const AdvertTypePricesManager = () => {
    const [categories, setCategories] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState('');
    const [prices, setPrices] = useState({});
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');

    useEffect(() => {
        const fetchCategories = async () => {
            try {
                const snapshot = await getDocs(collection(projectFirestore, 'advertcategories'));
                setCategories(snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })));
            } catch (err) {
                setError('Failed to fetch categories');
            }
        };
        fetchCategories();
    }, []);

    useEffect(() => {
        if (selectedCategory) {
            fetchPrices();
        }
    }, [selectedCategory]);

    const fetchPrices = async () => {
        setLoading(true);
        setError('');
        try {
            const docRef = doc(projectFirestore, 'adverttypeprices', selectedCategory);
            const docSnap = await getDoc(docRef);
            setPrices(docSnap.exists() ? docSnap.data().prices : {});
        } catch (err) {
            setError('Failed to fetch prices');
        }
        setLoading(false);
    };

    const handlePriceChange = (type, value) => {
        setPrices(prevPrices => ({ ...prevPrices, [type]: parseFloat(value) }));
    };

    const handleSubmit = async () => {
        if (!selectedCategory) {
            setError('Please select a category.');
            return;
        }

        setLoading(true);
        setError('');
        try {
            const docRef = doc(projectFirestore, 'adverttypeprices', selectedCategory);
            await updateDoc(docRef, { prices }, { merge: true });
            fetchPrices();  // Refresh list after update
            alert('Prices updated successfully');
            setPrices({}); // Reset form after submission
        } catch (err) {
            setError('Failed to update prices');
        }
        setLoading(false);
    };

    const handleDelete = async (advertType) => {
        const newPrices = { ...prices };
        delete newPrices[advertType];
        try {
            const docRef = doc(projectFirestore, 'adverttypeprices', selectedCategory);
            await updateDoc(docRef, { prices: newPrices });
            fetchPrices();  // Refresh list after deletion
        } catch (err) {
            setError('Failed to delete price');
        }
    };

    return (
        <Container>
            <h1>Manage Advert Type Prices</h1>
            {error && <Alert variant="danger">{error}</Alert>}
            <Form>
                <Form.Group controlId="advertCategory">
                    <Form.Label>Select Category</Form.Label>
                    <Form.Control as="select" value={selectedCategory} onChange={e => setSelectedCategory(e.target.value)}>
                        <option value="">Select a category</option>
                        {categories.map(category => (
                            <option key={category.id} value={category.id}>{category.name}</option>
                        ))}
                    </Form.Control>
                </Form.Group>

                {Object.keys(prices).map((type) => (
                    <Form.Group key={type} controlId={`price-${type}`}>
                        <Form.Label>{type} Price</Form.Label>
                        <Form.Control
                            type="number"
                            value={prices[type] || 0}
                            onChange={e => handlePriceChange(type, e.target.value)}
                        />
                        {/* <Button variant="danger" onClick={() => handleDelete(type)}>Delete</Button> */}
                    </Form.Group>
                ))}
                <Button variant="primary" onClick={handleSubmit} disabled={loading}>
                    {loading ? 'Updating...' : 'Update Prices'}
                </Button>
            </Form>
        </Container>
    );
};

export default AdvertTypePricesManager;
