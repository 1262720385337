
// src/components/PlayReferral.js
import React, { useState, useEffect } from "react";
import { projectAuth } from "../../../firebase/config";
import {
  Button,
  InputGroup,
  FormControl,
  Container,
  Row,
  Col,
} from "react-bootstrap";
import {
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  FacebookIcon,
  TwitterIcon,
  WhatsappIcon,
} from "react-share";

const PlayReferral = () => {
  const [referralLink, setReferralLink] = useState("");
  const [copySuccess, setCopySuccess] = useState("");

  useEffect(() => {
    projectAuth.onAuthStateChanged((user) => {
      if (user) {
        setReferralLink(`http://allghanaians.com/signup?ref=${user.uid}`);
      }
    });
  }, []);

  const copyToClipboard = () => {
    navigator.clipboard.writeText(referralLink).then(() => {
      setCopySuccess("Copied!");
      setTimeout(() => setCopySuccess(""), 2000); // Clear success message after 2 seconds
    });
  };

  return (
    <Container className="mt-4">
      <Row className="justify-content-md-center">
        <Col md="8">
          <h2 className="text-center">Referral Program</h2>
          <p className="text-center">
            Invite your friends using the link below:
          </p>
          <InputGroup className="mb-3">
            <FormControl type="text" value={referralLink} readOnly />
            <Button variant="secondary" onClick={copyToClipboard}>
              Copy
            </Button>
          </InputGroup>
          {copySuccess && (
            <p className="text-success text-center">{copySuccess}</p>
          )}

          <div className="text-center mt-3">
            <h3>Share on Social Media</h3>
            <FacebookShareButton url={referralLink} className="me-2">
              <FacebookIcon size={32} round />
            </FacebookShareButton>
            <TwitterShareButton url={referralLink} className="me-2">
              <TwitterIcon size={32} round />
            </TwitterShareButton>
            <WhatsappShareButton url={referralLink} className="me-2">
              <WhatsappIcon size={32} round />
            </WhatsappShareButton>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default PlayReferral;
