
import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import backgroundImage from '../../assets/livingroom3.jpg'; // Ensure the path is correct

import styles from './HeroSection.module.css'; // Create a corresponding CSS module

const HeroSection = () => {

    const navigate = useNavigate();

    // Function to navigate to the QuoteTool component
    const handleBookNowClick = () => {
        navigate('/booknow');
    };

    return (
        <div className={styles.heroSection} style={{ backgroundImage: `url(${backgroundImage})` }}>
            <div className={styles.content}>
                <h1>Welcome to AllGhanaians Cleaning Service</h1>
                <p>Your trusted partner in professional cleaning.</p>
                <button className="btn btn-primary" onClick={handleBookNowClick}>Book Now</button>
            </div>
        </div>
    );
};

export default HeroSection;
