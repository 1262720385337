// import React, { useState } from "react";
// import { Form, Button, Container, Row, Col } from "react-bootstrap";
// import axios from "axios";

// const TestSendGrid = () => {
//   const [formData, setFormData] = useState({
//     name: "",
//     email: "",
//     recipients: "",
//     subject: "",
//     message: "",
//   });

//   const [validated, setValidated] = useState(false);

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setFormData({
//       ...formData,
//       [name]: value,
//     });
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     const form = e.currentTarget;
//     if (form.checkValidity() === false) {
//       e.preventDefault();
//       e.stopPropagation();
//     } else {
//       e.preventDefault();
//       console.log("Form is valid. Sending email with formData:", formData);
//       try {
//         await axios.post("/api/sendTestEmail", formData);
//         console.log("Email sent successfully");
//         setFormData({
//           name: "",
//           email: "",
//           recipients: "",
//           subject: "",
//           message: "",
//         });
//         setValidated(true);
//       } catch (error) {
//         console.error(
//           "Error in handleSubmit while sending email:",
//           error.message
//         );
//       }
//     }
//   };

//   return (
//     <Container className="mt-5">
//       <Row className="justify-content-md-center">
//         <Col md="8">
//           <h2 className="mb-4 text-center">Send Mail</h2>
//           <Form noValidate validated={validated} onSubmit={handleSubmit}>
//             <Form.Group controlId="formName">
//               <Form.Label>Name</Form.Label>
//               <Form.Control
//                 required
//                 type="text"
//                 placeholder="Enter your name"
//                 name="name"
//                 value={formData.name}
//                 onChange={handleChange}
//               />
//               <Form.Control.Feedback type="invalid">
//                 Please provide your name.
//               </Form.Control.Feedback>
//             </Form.Group>

//             <Form.Group controlId="formEmail" className="mt-3">
//               <Form.Label>Email</Form.Label>
//               <Form.Control
//                 required
//                 type="email"
//                 placeholder="Enter your email"
//                 name="email"
//                 value={formData.email}
//                 onChange={handleChange}
//               />
//               <Form.Control.Feedback type="invalid">
//                 Please provide a valid email.
//               </Form.Control.Feedback>
//             </Form.Group>

//             <Form.Group controlId="formRecipients" className="mt-3">
//               <Form.Label>Recipients</Form.Label>
//               <Form.Control
//                 required
//                 type="text"
//                 placeholder="Enter recipients' emails, separated by commas"
//                 name="recipients"
//                 value={formData.recipients}
//                 onChange={handleChange}
//               />
//               <Form.Control.Feedback type="invalid">
//                 Please provide at least one recipient email.
//               </Form.Control.Feedback>
//             </Form.Group>

//             <Form.Group controlId="formSubject" className="mt-3">
//               <Form.Label>Subject</Form.Label>
//               <Form.Control
//                 required
//                 type="text"
//                 placeholder="Enter the subject"
//                 name="subject"
//                 value={formData.subject}
//                 onChange={handleChange}
//               />
//               <Form.Control.Feedback type="invalid">
//                 Please provide a subject.
//               </Form.Control.Feedback>
//             </Form.Group>

//             <Form.Group controlId="formMessage" className="mt-3">
//               <Form.Label>Message</Form.Label>
//               <Form.Control
//                 required
//                 as="textarea"
//                 rows={5}
//                 placeholder="Enter your message"
//                 name="message"
//                 value={formData.message}
//                 onChange={handleChange}
//               />
//               <Form.Control.Feedback type="invalid">
//                 Please provide a message.
//               </Form.Control.Feedback>
//             </Form.Group>

//             <Button className="mt-4 w-100" type="submit">
//               Send Mail
//             </Button>
//           </Form>
//         </Col>
//       </Row>
//     </Container>
//   );
// };

// export default TestSendGrid;

import React, { useState } from "react";
import { Form, Button, Container, Row, Col } from "react-bootstrap";
import axios from "axios";

const TestSendGrid = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "info@allghanaians.com", // Default email address
    recipients: "",
    subject: "",
    message: "",
  });

  const [validated, setValidated] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
    } else {
      e.preventDefault();
      console.log("Form is valid. Sending email with formData:", formData);
      try {
        const response = await axios.post("/api/sendTestEmail", formData, {
          headers: {
            "Content-Type": "application/json",
          },
        });
        console.log("Email sent successfully:", response.data);
        setFormData({
          name: "",
          email: "info@allghanaians.com", // Reset to default email
          recipients: "",
          subject: "",
          message: "",
        });
        setValidated(true);
      } catch (error) {
        console.error(
          "Error in handleSubmit while sending email:",
          error.message
        );
      }
    }
  };

  return (
    <Container className="mt-5">
      <Row className="justify-content-md-center">
        <Col md="8">
          <h2 className="mb-4 text-center">Send Mail</h2>
          <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <Form.Group controlId="formName">
              <Form.Label>Name</Form.Label>
              <Form.Control
                required
                type="text"
                placeholder="Enter your name"
                name="name"
                value={formData.name}
                onChange={handleChange}
              />
              <Form.Control.Feedback type="invalid">
                Please provide your name.
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group controlId="formEmail" className="mt-3">
              <Form.Label>Email</Form.Label>
              <Form.Control
                required
                type="email"
                placeholder="Enter your email"
                name="email"
                value={formData.email}
                readOnly
              />
              <Form.Control.Feedback type="invalid">
                Please provide a valid email.
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group controlId="formRecipients" className="mt-3">
              <Form.Label>Recipients</Form.Label>
              <Form.Control
                required
                type="text"
                placeholder="Enter recipients' emails, separated by commas"
                name="recipients"
                value={formData.recipients}
                onChange={handleChange}
              />
              <Form.Control.Feedback type="invalid">
                Please provide at least one recipient email.
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group controlId="formSubject" className="mt-3">
              <Form.Label>Subject</Form.Label>
              <Form.Control
                required
                type="text"
                placeholder="Enter the subject"
                name="subject"
                value={formData.subject}
                onChange={handleChange}
              />
              <Form.Control.Feedback type="invalid">
                Please provide a subject.
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group controlId="formMessage" className="mt-3">
              <Form.Label>Message</Form.Label>
              <Form.Control
                required
                as="textarea"
                rows={5}
                placeholder="Enter your message"
                name="message"
                value={formData.message}
                onChange={handleChange}
              />
              <Form.Control.Feedback type="invalid">
                Please provide a message.
              </Form.Control.Feedback>
            </Form.Group>

            <Button className="mt-4 w-100" type="submit">
              Send Mail
            </Button>
          </Form>
        </Col>
      </Row>
    </Container>
  );
};

export default TestSendGrid;
