import React from 'react';
import { Accordion, Card, Button } from 'react-bootstrap';
import { v4 as uuidv4 } from 'uuid';

const ExpandableListInterface = ({ categories = [], startEditSubCategory, deleteSubCategory }) => {
  return (
    <>
        <h2>Expandable Categories List</h2>
        <Accordion defaultActiveKey="0">
        {categories.map((category, index) => (
            <Accordion.Item eventKey={String(index)} key={category.id}>
            <Accordion.Header>{category.name}</Accordion.Header>
            <Accordion.Body>
                {category.subCategories?.map(subCat => (
                <div key={subCat.id || uuidv4()} className="d-flex justify-content-between align-items-center">
                    {subCat.name}
                    <div>
                    <Button variant="info" size="sm" onClick={() => startEditSubCategory(category.id, subCat)}>Edit</Button>
                    <Button variant="danger" size="sm" onClick={() => deleteSubCategory(category.id, subCat.id)}>Delete</Button>
                    
                    </div>
                </div>
                ))}
            </Accordion.Body>
            </Accordion.Item>
        ))}
        </Accordion>
    </>
  );
};

export default ExpandableListInterface;
