// import React, { useState, useEffect } from "react";
// import {
//   getAuth,
//   signInWithEmailAndPassword,
//   signInWithPopup,
// } from "firebase/auth";
// import {
//   projectAuth,
//   projectFirestore,
//   googleProvider,
//   generateToken,
// } from "../../firebase/config";
// import { doc, getDoc, updateDoc, Timestamp } from "firebase/firestore";
// import { useNavigate } from "react-router-dom";
// import { useUser } from "../contexts/UserContext";
// import {
//   requestNotificationsPermissions,
//   saveMessagingDeviceToken,
// } from "../../firebase/messaging";
// import styles from "./Login.module.css";

// function Login() {
//   const [email, setEmail] = useState("");
//   const [password, setPassword] = useState("");
//   const [error, setError] = useState(null);
//   const navigate = useNavigate();
//   const { currentUser, loading } = useUser();

//   useEffect(() => {
//     if (currentUser?.profileCompleted) {
//       navigate("/");
//     }
//   }, [currentUser, navigate]);

//   const handleLogin = async (e) => {
//     e.preventDefault();
//     try {
//       const userCredential = await signInWithEmailAndPassword(
//         projectAuth,
//         email,
//         password
//       );
//       await checkUserProfile(userCredential.user.uid);
//     } catch (err) {
//       setError(err.message);
//     }
//   };

//   const handleGoogleLogin = async () => {
//     try {
//       const result = await signInWithPopup(projectAuth, googleProvider);
//       await checkUserProfile(result.user.uid);
//     } catch (err) {
//       setError(err.message);
//     }
//   };

//   const checkUserProfile = async (uid) => {
//     const userProfileRef = doc(projectFirestore, "users", uid);
//     const userProfileSnapshot = await getDoc(userProfileRef);

//     if (userProfileSnapshot.exists()) {
//       const {
//         profileCompleted,
//         role,
//         isPlayer,
//         loginCount = 0,
//         creationDate,
//         fcmToken,
//       } = userProfileSnapshot.data();

//       if (typeof isPlayer === "undefined" || !isPlayer) {
//         navigate("/participation");
//         return;
//       }

//       let newLoginCount = loginCount + 1;
//       await updateDoc(userProfileRef, { loginCount: newLoginCount });

//       if (!creationDate) {
//         await updateDoc(userProfileRef, { creationDate: Timestamp.now() });
//       }

//       if (!fcmToken) {
//         const permissionGranted = await requestNotificationsPermissions(uid);
//         if (permissionGranted) {
//           const fcmToken = await generateToken();
//           if (fcmToken) {
//             await saveMessagingDeviceToken(uid, fcmToken);
//             await updateDoc(userProfileRef, { fcmToken: true });
//           }
//         }
//       }

//       if (role && !profileCompleted) {
//         navigate("/profile-completion");
//       } else if (role === "admin") {
//         navigate("/admin-dashboard");
//       } else {
//         navigate("/");
//       }
//     } else {
//       setError("User profile not found. Please complete your profile.");
//     }
//   };

//   if (loading) {
//     return <div>Loading...</div>;
//   }

//   if (currentUser) {
//     return <div>Redirecting...</div>;
//   }

//   return (
//     <div
//       className="d-flex justify-content-center align-items-center"
//       style={{ minHeight: "50vh" }}
//     >
//       <div className="col-md-6 col-lg-4">
//         <h2 className="text-center mb-4">Login</h2>
//         {error && (
//           <div className="alert alert-danger" role="alert">
//             {error}
//           </div>
//         )}
//         <form onSubmit={handleLogin} className="mb-3">
//           <div className="mb-3">
//             <input
//               type="email"
//               className="form-control"
//               placeholder="Email"
//               value={email}
//               onChange={(e) => setEmail(e.target.value)}
//             />
//           </div>
//           <div className="mb-3">
//             <input
//               type="password"
//               className="form-control"
//               placeholder="Password"
//               value={password}
//               onChange={(e) => setPassword(e.target.value)}
//             />
//           </div>
//           <button className="btn btn-primary w-100" type="submit">
//             Login
//           </button>
//         </form>
//         <button className="btn btn-danger w-100" onClick={handleGoogleLogin}>
//           Login with Google
//         </button>
//       </div>
//     </div>
//   );
// }

// export default Login;


import React, { useState, useEffect } from "react";
import { signInWithEmailAndPassword, signInWithPopup } from "firebase/auth";
import {
  projectAuth,
  projectFirestore,
  googleProvider,
  generateToken,
} from "../../firebase/config";
import { doc, getDoc, updateDoc, Timestamp } from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import { useUser } from "../contexts/UserContext";
import {
  requestNotificationsPermissions,
  saveMessagingDeviceToken,
} from "../../firebase/messaging";


function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const { currentUser, loading } = useUser();

  useEffect(() => {
    // Redirect if already logged in
    if (currentUser) {
      navigate("/");
    }
  }, [currentUser, navigate]);

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const userCredential = await signInWithEmailAndPassword(
        projectAuth,
        email,
        password
      );
      postLoginChecks(userCredential.user.uid);
    } catch (err) {
      setError(err.message);
    }
  };

  const handleGoogleLogin = async () => {
    try {
      const result = await signInWithPopup(projectAuth, googleProvider);
      postLoginChecks(result.user.uid);
    } catch (err) {
      setError(err.message);
    }
  };

  const postLoginChecks = async (uid) => {
    const userProfileRef = doc(projectFirestore, "users", uid);
    const userProfileSnapshot = await getDoc(userProfileRef);

    if (!userProfileSnapshot.exists()) {
      setError("User profile not found. Please contact support.");
      return;
    }

    const userData = userProfileSnapshot.data();

    const newLoginCount = (userData.loginCount || 0) + 1;
    await updateDoc(userProfileRef, { loginCount: newLoginCount });

    if (!userData.creationDate) {
      await updateDoc(userProfileRef, { creationDate: Timestamp.now() });
    }

    if (!userData.fcmToken) {
      const permissionGranted = await requestNotificationsPermissions(uid);
      if (permissionGranted) {
        const token = await generateToken();
        if (token) {
          await saveMessagingDeviceToken(uid, token);
          await updateDoc(userProfileRef, { fcmToken: token });
        }
      }
    }

    // Direct users based on role
    if (userData.role === "admin") {
      navigate("/admin-dashboard");
    } else {
      navigate("/");
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (currentUser) {
    return <div>Redirecting...</div>;
  }

  return (
    <div
      className="d-flex justify-content-center align-items-center"
      style={{ minHeight: "50vh" }}
    >
      <div className="col-md-6 col-lg-4">
        <h2 className="text-center mb-4">Login</h2>
        {error && (
          <div className="alert alert-danger" role="alert">
            {error}
          </div>
        )}
        <form onSubmit={handleLogin} className="mb-3">
          <div className="mb-3">
            <input
              type="email"
              className="form-control"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className="mb-3">
            <input
              type="password"
              className="form-control"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          <button className="btn btn-primary w-100" type="submit">
            Login
          </button>
        </form>
        <button className="btn btn-danger w-100" onClick={handleGoogleLogin}>
          Login with Google
        </button>
      </div>
    </div>
  );
}

export default Login;
