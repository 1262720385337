// import React, { useState, useEffect } from "react";
// import { Form, Container, Row, Col } from "react-bootstrap";
// import { projectFirestore } from "../../../firebase/config";
// import { collection, getDocs } from "firebase/firestore";

// const DeliveryDropdown = ({
//   selectedDeliveryCategory,
//   selectedDeliverySubcategory,
//   selectedDeliveryCategoryAttributes,
//   selectedDeliverySubCategoryAttributes,
//   setSelectedDeliveryCategory,
//   setSelectedDeliverySubcategory,
//   setSelectedDeliveryCategoryAttributes,
//   setSelectedDeliverySubCategoryAttributes,
// }) => {
//   const [categories, setCategories] = useState([]);


//   useEffect(() => {
//     const fetchCategories = async () => {
//       try {
//         const querySnapshot = await getDocs(
//           collection(projectFirestore, "DeliveryCategories")
//         );
//         const categoriesData = querySnapshot.docs.map((doc) => ({
//           id: doc.id,
//           ...doc.data(),
//         }));
//         setCategories(categoriesData);
//       } catch (error) {
//         console.error("Error fetching categories:", error);
//       }
//     };

//     fetchCategories();
//   }, []);

//   const handleCategoryChange = (e) => {
//     const categoryId = e.target.value;
//     console.log("Selected delivery category:", categoryId);
//     setSelectedDeliveryCategory(categoryId);
//     setSelectedDeliverySubcategory("");
//     setSelectedDeliveryCategoryAttributes({}); // Clear selected category attributes
//     setSelectedDeliverySubCategoryAttributes({}); // Clear selected subcategory attributes
//   };

//   const handleSubCategoryChange = (e) => {
//     const subcategoryId = e.target.value;
//     console.log("Selected delivery subcategory:", subcategoryId);
//     setSelectedDeliverySubcategory(subcategoryId);
//     setSelectedDeliverySubCategoryAttributes({}); // Clear selected subcategory attributes
//   };

//   const handleAttributeChange = (attributeKey, option, isChecked) => {
//     if (selectedDeliverySubcategory) {
//       setSelectedDeliverySubCategoryAttributes((prevAttributes) => ({
//         ...prevAttributes,
//         [attributeKey]: isChecked
//           ? [...(prevAttributes[attributeKey] || []), option]
//           : prevAttributes[attributeKey].filter((item) => item !== option),
//       }));
//     } else {
//       setSelectedDeliveryCategoryAttributes((prevAttributes) => ({
//         ...prevAttributes,
//         [attributeKey]: isChecked
//           ? [...(prevAttributes[attributeKey] || []), option]
//           : prevAttributes[attributeKey].filter((item) => item !== option),
//       }));
//     }
//   };

//   const renderAttributesInput = (attributes, isSubCategory = false) => {
//     const selectedDeliveryDropdownAttributes = isSubCategory
//       ? selectedDeliverySubCategoryAttributes
//       : selectedDeliveryCategoryAttributes;

//     return Object.entries(attributes).map(([key, options], index) => {
//       const optionsArray = options.split(", ");

//       return (
//         <div key={index}>
//           <label>{key}:</label>
//           {optionsArray.map((option) => (
//             <div key={option}>
//               <Form.Check
//                 type="checkbox"
//                 id={
//                   isSubCategory
//                     ? `sub-${key}-${option}`
//                     : `cat-${key}-${option}`
//                 }
//                 label={option}
//                 checked={
//                   selectedDeliveryDropdownAttributes[key]?.includes(option) ||
//                   false
//                 }
//                 onChange={(e) =>
//                   handleAttributeChange(key, option, e.target.checked)
//                 }
//               />
//             </div>
//           ))}
//         </div>
//       );
//     });
//   };

//   return (
//     <Container>
//       <Row>
//         <Col>
//           <Form.Label>
//             <span className="fw-bold">Select Delivery Category:</span>
//           </Form.Label>
//           <Form.Select onChange={handleCategoryChange}>
//             <option value="">Select a Delivery Category</option>
//             {categories.map((category) => (
//               <option key={category.id} value={category.id}>
//                 {category.name}
//               </option>
//             ))}
//           </Form.Select>
//         </Col>
//       </Row>

//       {selectedDeliveryCategory && (
//         <Row>
//           <Col>
//             <Form.Label>
//               <span className="fw-bold">
//                 Select Delivery Category Attributes:
//               </span>
//             </Form.Label>
//             {categories.find((cat) => cat.id === selectedDeliveryCategory)
//               ?.attributes &&
//               renderAttributesInput(
//                 categories.find((cat) => cat.id === selectedDeliveryCategory)
//                   .attributes
//               )}
//           </Col>
//         </Row>
//       )}

//       <Row>
//         <Col>
//           <Form.Label>
//             <span className="fw-bold">Select Delivery Sub-Category:</span>
//           </Form.Label>
//           <Form.Select onChange={handleSubCategoryChange}>
//             <option value="">Select a Delivery Sub-Category</option>
//             {categories
//               .find((cat) => cat.id === selectedDeliveryCategory)
//               ?.subCategories.map((subCat) => (
//                 <option key={subCat.id} value={subCat.id}>
//                   {subCat.name}
//                 </option>
//               ))}
//           </Form.Select>
//         </Col>
//       </Row>

//       {selectedDeliverySubcategory && (
//         <Row>
//           <Col>
//             <Form.Label>
//               <span className="fw-bold">Select Delivery Subcategories Attributes:</span>
//             </Form.Label>
//             {categories
//               .find((cat) => cat.id === selectedDeliveryCategory)
//               ?.subCategories.find(
//                 (subCat) => subCat.id === selectedDeliverySubcategory
//               )?.attributes &&
//               renderAttributesInput(
//                 categories
//                   .find((cat) => cat.id === selectedDeliveryCategory)
//                   ?.subCategories.find(
//                     (subCat) => subCat.id === selectedDeliverySubcategory
//                   )?.attributes,
//                 true
//               )}
//           </Col>
//         </Row>
//       )}
//     </Container>
//   );
// };

// export default DeliveryDropdown;


import React, { useState, useEffect } from "react";
import { Form, Container, Row, Col } from "react-bootstrap";
import { projectFirestore } from "../../../firebase/config";
import { collection, getDocs } from "firebase/firestore";

const DeliveryDropdown = ({
  selectedDeliveryCategory,
  selectedDeliverySubcategory,
  selectedDeliveryCategoryAttributes,
  selectedDeliverySubCategoryAttributes,
  setSelectedDeliveryCategory,
  setSelectedDeliverySubcategory,
  setSelectedDeliveryCategoryAttributes,
  setSelectedDeliverySubCategoryAttributes,
}) => {
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const querySnapshot = await getDocs(
          collection(projectFirestore, "DeliveryCategories")
        );
        const categoriesData = querySnapshot.docs.map((doc) => {
  const data = doc.data();
  return {
    id: doc.id,
    ...data,
    subCategories: (data.subCategories || []).filter(subCat => subCat.active).map(subCat => ({
      ...subCat,
      attributes: Object.entries(subCat.attributes || {}).reduce((acc, [key, val]) => {
        if (val.active) { acc[key] = val; }
        return acc;
      }, {})
    }))
  };
})

          .filter((cat) => cat.subCategories.length > 0); // Only include categories with active subcategories
        setCategories(categoriesData);
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };

    fetchCategories();
  }, []);

  const handleCategoryChange = (e) => {
    const categoryId = e.target.value;
    setSelectedDeliveryCategory(categoryId);
    setSelectedDeliverySubcategory("");
    setSelectedDeliveryCategoryAttributes({});
    setSelectedDeliverySubCategoryAttributes({});
  };

  const handleSubCategoryChange = (e) => {
    const subcategoryId = e.target.value;
    setSelectedDeliverySubcategory(subcategoryId);
    setSelectedDeliverySubCategoryAttributes({});
  };

  const handleAttributeChange = (attributeKey, option, isChecked) => {
    const updateAttributes = (prevAttributes) => ({
      ...prevAttributes,
      [attributeKey]: isChecked
        ? [...(prevAttributes[attributeKey] || []), option]
        : prevAttributes[attributeKey].filter((item) => item !== option),
    });

    if (selectedDeliverySubcategory) {
      setSelectedDeliverySubCategoryAttributes(updateAttributes);
    } else {
      setSelectedDeliveryCategoryAttributes(updateAttributes);
    }
  };

  const renderAttributesInput = (attributes, isSubCategory = false) => {
    const selectedAttributes = isSubCategory
      ? selectedDeliverySubCategoryAttributes
      : selectedDeliveryCategoryAttributes;

    if (!attributes) {
      return null; // If attributes are undefined or null, render nothing.
    }

    return Object.entries(attributes).map(([key, options], index) => (
      <div key={index}>
        <label>{key}:</label>
        {options.split(", ").map((option) => (
          <div key={option}>
            <Form.Check
              type="checkbox"
              id={
                isSubCategory ? `sub-${key}-${option}` : `cat-${key}-${option}`
              }
              label={option}
              checked={selectedAttributes[key]?.includes(option) || false}
              onChange={(e) =>
                handleAttributeChange(key, option, e.target.checked)
              }
            />
          </div>
        ))}
      </div>
    ));
  };


  return (
    <Container>
      <Row>
        <Col>
          <Form.Label>
            <span className="fw-bold">Select Delivery Category:</span>
          </Form.Label>
          <Form.Select
            value={selectedDeliveryCategory}
            onChange={handleCategoryChange}
          >
            <option value="">Select a Delivery Category</option>
            {categories.map((category) => (
              <option key={category.id} value={category.id}>
                {category.name}
              </option>
            ))}
          </Form.Select>
        </Col>
      </Row>

      {selectedDeliveryCategory && (
        <Row>
          <Col>
            <Form.Label>
              <span className="fw-bold">Select Delivery Sub-Category:</span>
            </Form.Label>
            <Form.Select
              value={selectedDeliverySubcategory}
              onChange={handleSubCategoryChange}
            >
              <option value="">Select a Delivery Sub-Category</option>
              {categories
                .find((cat) => cat.id === selectedDeliveryCategory)
                ?.subCategories.map((subCat) => (
                  <option key={subCat.id} value={subCat.id}>
                    {subCat.name}
                  </option>
                ))}
            </Form.Select>
          </Col>
        </Row>
      )}

      {selectedDeliverySubcategory && (
        <Row>
          <Col>
            <Form.Label>
              <span className="fw-bold">
                Select Delivery Subcategory Attributes:
              </span>
            </Form.Label>
            {renderAttributesInput(
              categories
                .find((cat) => cat.id === selectedDeliveryCategory)
                ?.subCategories.find(
                  (subCat) => subCat.id === selectedDeliverySubcategory
                )?.attributes,
              true
            )}
          </Col>
        </Row>
      )}
    </Container>
  );
};

export default DeliveryDropdown;
