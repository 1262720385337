import React, { useState } from 'react';
import { Container, Row, Col, ListGroup, Card, Button } from 'react-bootstrap';

const MasterDetailInterface = ({ categories = [], startEditSubCategory, deleteSubCategory }) => {
  const [selectedCategory, setSelectedCategory] = useState(null);

  return (
    <Container fluid>
    <h2>Master-Detail Categories View</h2>
      <Row>
        <Col sm={4}>
          <ListGroup>
            {categories.map(category => (
              <ListGroup.Item
                key={category.id}
                action
                onClick={() => setSelectedCategory(category)}
                active={selectedCategory && selectedCategory.id === category.id}
              >
                {category.name}
              </ListGroup.Item>
            ))}
          </ListGroup>
        </Col>
        <Col sm={8}>
          {selectedCategory && (
            <Card>
              <Card.Header>{selectedCategory.name}</Card.Header>
              <Card.Body>
                <h5>Sub-Categories:</h5>
                <ListGroup variant="flush">
                {selectedCategory?.subCategories?.map((subCat, index) => (
                    <ListGroup.Item key={index}>
                      {subCat.name}
                      <Button variant="info" size="sm" className="ms-2" onClick={() => startEditSubCategory(selectedCategory.id, subCat)}>Edit</Button>
                      <Button variant="danger" size="sm" className="ms-2" onClick={() => deleteSubCategory(selectedCategory.id, subCat.id)}>Delete</Button>
                    </ListGroup.Item>
                  ))}
                </ListGroup>
              </Card.Body>
            </Card>
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default MasterDetailInterface;
