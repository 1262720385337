import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { doc, getDoc } from 'firebase/firestore';
import { projectFirestore } from '../../firebase/config';
import { Alert, Card, ListGroup } from 'react-bootstrap';

const UserOrderDetails = () => {
    const [order, setOrder] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const { orderId } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        const fetchOrder = async () => {
            setLoading(true);
            try {
                const docRef = doc(projectFirestore, 'cleaningOrders', orderId);
                const docSnap = await getDoc(docRef);
    
                if (docSnap.exists()) {
                    setOrder({ id: docSnap.id, ...docSnap.data() });
                } else {
                    console.error('No such document!');
                    setError('Order not found.');
                }
            } catch (err) {
                console.error('Error fetching order:', err);
                setError('Failed to fetch order.');
            }
            setLoading(false);
        };
    
        fetchOrder();
    }, [orderId]);

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <Alert variant="danger">{error}</Alert>;
    }

    return (
        <div className="container mt-5">
            <Card>
                <Card.Header as="h5">Order Details</Card.Header>
                {order && (
                    <ListGroup variant="flush">
                        <ListGroup.Item><strong>Service:</strong> {order.serviceDetails.selectedService}</ListGroup.Item>
                        <ListGroup.Item><strong>Price:</strong> GHS {order.serviceDetails.calculatedPrice}</ListGroup.Item>
                        <ListGroup.Item><strong>Date:</strong> {order.serviceDetails.serviceDate}</ListGroup.Item>
                        <ListGroup.Item><strong>Time:</strong> {order.serviceDetails.serviceTime}</ListGroup.Item>
                        <ListGroup.Item><strong>Status:</strong> {order.orderStatus}</ListGroup.Item>
                        <ListGroup.Item><strong>Additional Info:</strong> {order.serviceDetails.additionalInfo}</ListGroup.Item>
                        <ListGroup.Item>
                            <button className="btn btn-secondary" onClick={() => navigate('/user-orders')}>
                                Back to Orders
                            </button>
                        </ListGroup.Item>
                    </ListGroup>
                )}
            </Card>
        </div>
    );
};

export default UserOrderDetails;
