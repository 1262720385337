


import React from 'react';
import { Card, Button, Container, Row, Col } from 'react-bootstrap';

const MiscellaneousDetail = ({ announcement }) => {
  // Placeholder data for demonstration
  const placeholderData = {
    title: "Community Cleanup Initiative",
    date: "2024-04-22",
    location: "Central Park, Main Entrance",
    description: "Join us for a community cleanup day at Central Park! This event is a fantastic opportunity to give back to the community, meet your neighbors, and help keep our park beautiful. All materials will be provided.",
    additionalDetails: [
      "Volunteers should meet at the main entrance by 9:00 AM.",
      "Refreshments will be provided for all volunteers.",
      "Please wear comfortable shoes and clothing.",
    ],
    callToAction: "Register Now",
    callToActionLink: "https://www.communityinitiative.org/register",
    contactInfo: "For more information, please contact us at cleanup@communityinitiative.org or (555) 123-4567."
  };

  // Use actual announcement data if available; otherwise, use placeholder
  const data = announcement || placeholderData;

  return (
    <Container className="mt-5">
      <Row>
        <Col>
          <Card>
            <Card.Header as="h5">{data.title}</Card.Header>
            <Card.Body>
              <Card.Subtitle className="mb-2 text-muted">{data.date} - {data.location}</Card.Subtitle>
              <Card.Text>{data.description}</Card.Text>
              <Card.Text>{data.advertType}</Card.Text>
              <Card.Text>{data.contactInfo}</Card.Text>
              {/* <Card.Text>{data.date}</Card.Text> */}
              {data.additionalDetails && Array.isArray(data.additionalDetails) ? (
                <ul>
                  {data.additionalDetails.map((detail, index) => (
                    <li key={index}>{detail}</li>
                  ))}
                </ul>
              ) : null}
              <Button variant="primary" href={data.callToActionLink}>{data.callToAction}</Button>
              <Card.Text className="mt-3">
                <small className="text-muted">{data.contactInfo}</small>
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default MiscellaneousDetail;
