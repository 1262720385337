import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { doc, getDoc } from "firebase/firestore";
import { projectFirestore } from "../../../firebase/config";
import { Container, Card, Row, Col, Form } from "react-bootstrap";

const UserDetails = () => {
  const { id } = useParams();
  const [user, setUser] = useState(null);

  useEffect(() => {
    const fetchUser = async () => {
      const docRef = doc(projectFirestore, "users", id);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        setUser(docSnap.data());
      } else {
        console.log("No such document!");
      }
    };

    fetchUser();
  }, [id]);

  if (!user) {
    return <div>Loading...</div>;
  }

  return (
    <Container fluid className="pt-5">
      <Container fluid className="pt-5"></Container>
      <h2>User Details</h2>
      <Card>
        <Card.Body>
          <Row className="mb-3">
            <Col>
              <Form.Label>User ID</Form.Label>
              <Form.Control type="text" value={id} readOnly />
            </Col>
            <Col>
              <Form.Label>Display Name</Form.Label>
              <Form.Control
                type="text"
                value={user.displayName || "N/A"}
                readOnly
              />
            </Col>
          </Row>
          <Row className="mb-3">
            <Col>
              <Form.Label>Phone Number</Form.Label>
              <Form.Control
                type="text"
                value={user.phoneNumber || "N/A"}
                readOnly
              />
            </Col>
            <Col>
              <Form.Label>Email</Form.Label>
              <Form.Control type="text" value={user.email} readOnly />
            </Col>
          </Row>
          <Row className="mb-3">
            <Col>
              <Form.Label>Creation Date</Form.Label>
              <Form.Control
                type="text"
                value={
                  user.creationDate?.toDate().toLocaleDateString() || "N/A"
                }
                readOnly
              />
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </Container>
  );
};

export default UserDetails;
