import React, { useState, useEffect } from 'react';
import { collection, getDocs, deleteDoc, doc } from 'firebase/firestore';
import { projectFirestore } from '../../firebase/config'; // Adjust the import path
import { Table, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom'; // Adjust the import path

const FaqsList = () => {
    const [faqs, setFaqs] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchFaqs = async () => {
            const querySnapshot = await getDocs(collection(projectFirestore, 'faqs'));
            setFaqs(querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })));
        };
        fetchFaqs();
    }, []);

    const handleEdit = (faqId) => {
        navigate(`/edit-faq/${faqId}`); // Adjust the route as needed
    };

    const handleDelete = async (faqId) => {
        if (window.confirm('Are you sure you want to delete this FAQ?')) {
            await deleteDoc(doc(projectFirestore, 'faqs', faqId));
            setFaqs(faqs.filter(faq => faq.id !== faqId));
        }
    };

    return (
        <Table striped bordered hover>
            <thead>
                <tr>
                    <th>FAQ Question</th>
                    <th>Actions</th>
                </tr>
            </thead>
            <tbody>
                {faqs.map(faq => (
                    <tr key={faq.id}>
                        <td>{faq.question}</td>
                        <td>
                            <Button variant="primary" onClick={() => handleEdit(faq.id)}>Edit</Button>
                            <Button variant="danger" onClick={() => handleDelete(faq.id)}>Delete</Button>
                        </td>
                    </tr>
                ))}
            </tbody>
        </Table>
    );
};

export default FaqsList;
