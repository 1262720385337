import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Container, Card } from 'react-bootstrap';
import { projectFirestore } from '../../firebase/config';
import { doc, updateDoc, getDoc, getDocs, addDoc, collection, deleteDoc } from 'firebase/firestore';
import FlutterwavePayments from '../Payments/FlutterwavePayments';

function AdvertsOrderSummary() {
  const location = useLocation();
  const navigate = useNavigate();
  const advertData = location.state?.advertData;
  const [priceDetails, setPriceDetails] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchPriceDetails = async () => {
      const docRef = doc(projectFirestore, 'adverttypeprices', advertData.categoryId);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists() && docSnap.data().prices[advertData.advertType]) {
        setPriceDetails(docSnap.data().prices[advertData.advertType]);
      } else {
        alert("Pricing details not found.");
      }
      setIsLoading(false);
    };

    fetchPriceDetails();
  }, [advertData.categoryId, advertData.advertType]);

  const handlePaymentSuccess = async () => {
    // After successful payment, post the announcement data to Firestore
    const advertRef = await addDoc(collection(projectFirestore, 'announcements'), advertData);
    console.log("Advert posted successfully with ID:", advertRef.id);
    navigate(`/advert-details/${advertRef.id}`);
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <Container>
      <Card>
        <Card.Body>
          <p><strong>Category:</strong> {advertData.categoryName}</p>
          <p><strong>Advert Type:</strong> {advertData.advertType}</p>
          <p><strong>Price:</strong> ${priceDetails}</p>
          <FlutterwavePayments
            calculatedPrice={priceDetails}
            onPaymentSuccess={handlePaymentSuccess}
          />
        </Card.Body>
      </Card>
    </Container>
  );
}

export default AdvertsOrderSummary;
