// import React, { useEffect } from "react";
// import { generateToken, messaging } from "../../firebase/config";
// import { onMessage } from "firebase/messaging";


// function WebNotifications() {
//   useEffect(() => {
//     generateToken();
//     onMessage(messaging, (payload) => {
//       console.log("Message received. ", payload);
//     })
//   }, []);
// }

// export default WebNotifications;

// import React, { useEffect } from "react";
// import { generateToken } from "../../firebase/config";

// function WebNotifications() {
//   useEffect(() => {
//     generateToken().then((token) => {
//       if (token) {
//         console.log("Generated Token:", token);
//       } else {
//         console.log("No token generated.");
//       }
//     });
//   }, []);

//   return null; // This component doesn't need to render anything
// }

// export default WebNotifications;

import React, { useEffect } from "react";
import { generateToken, messaging } from "../../firebase/config";
import { onMessage } from "firebase/messaging";

function WebNotifications() {
  useEffect(() => {
    generateToken().then((token) => {
      if (token) {
        console.log("Generated Token:", token);
      } else {
        console.log("No token generated.");
      }
    }).catch((error) => {
      console.error("Error generating token:", error);
    });

    onMessage(messaging, (payload) => {
      console.log("Message received. ", payload);
      const notificationTitle = payload.notification.title;
      const notificationOptions = {
        body: payload.notification.body,
        icon: payload.notification.icon,
      };
      new Notification(notificationTitle, notificationOptions);
    });
  }, []);

  return null;
}

export default WebNotifications;


