// OrderDetails.js

import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { projectFirestore } from '../../firebase/config';
import { Container, Card, ListGroup } from 'react-bootstrap';

const AdminOrderDetails = () => {
    const [order, setOrder] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const { orderId } = useParams();
    const [orderStatus, setOrderStatus] = useState('pending');
    const navigate = useNavigate();


    useEffect(() => {
        const fetchOrder = async () => {
            setLoading(true);
            try {
                const docRef = doc(projectFirestore, 'cleaningOrders', orderId);
                const docSnap = await getDoc(docRef);
    
                if (docSnap.exists()) {
                    const fetchedOrder = { id: docSnap.id, ...docSnap.data() }; // Define fetchedOrder here
                    setOrder(fetchedOrder);
                    setOrderStatus(fetchedOrder.orderStatus || 'pending'); // Set local state based on fetched data
                } else {
                    console.error('No such document!');
                    setError('Order not found.');
                }
            } catch (err) {
                console.error('Error fetching order:', err);
                setError('Failed to fetch order.');
            }
            setLoading(false);
        };
    
        fetchOrder();
    }, [orderId]);
    

    const handleStatusChange = async (e) => {
        const newStatus = e.target.value;
        setOrderStatus(newStatus);  // Update local state
    
        // Update Firestore
        try {
            const orderRef = doc(projectFirestore, 'cleaningOrders', orderId);
            await updateDoc(orderRef, {
                orderStatus: newStatus
            });
            console.log("Order status updated to:", newStatus);
        } catch (err) {
            console.error("Error updating order status:", err);
            // Optionally handle error, e.g., show error message
        }
    };
    



    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>Error: {error}</div>;
    }

    return (
        <Container className="mt-5">
            <Card>
                <Card.Header as="h5">Order Details</Card.Header>
                {order && (
                    <ListGroup variant="flush">
                        <ListGroup.Item><strong>Customer Name:</strong> {order.customerInfo.name}</ListGroup.Item>
                        <ListGroup.Item><strong>Email:</strong> {order.customerInfo.email}</ListGroup.Item>
                        <ListGroup.Item><strong>Service:</strong> {order.serviceDetails.selectedService}</ListGroup.Item>
                        <ListGroup.Item><strong>Price:</strong> GHS {order.serviceDetails.calculatedPrice}</ListGroup.Item>
                        <ListGroup.Item><strong>Date:</strong> {order.serviceDetails.serviceDate}</ListGroup.Item>
                        <ListGroup.Item><strong>Time:</strong> {order.serviceDetails.serviceTime}</ListGroup.Item>
                        <ListGroup.Item><strong>Frequency:</strong> {order.serviceDetails.frequency}</ListGroup.Item>
                        <ListGroup.Item><strong>Additional Info:</strong> {order.serviceDetails.additionalInfo}</ListGroup.Item>
                        <ListGroup.Item>
                            <strong>Status:</strong>
                            <select
                                value={orderStatus}
                                onChange={handleStatusChange}
                                className="form-select form-select-sm ms-2"
                                style={{ width: 'auto', display: 'inline-block' }}
                            >
                                <option value="pending">Pending</option>
                                <option value="accepted">Accepted</option>
                                <option value="in-progress">In-progress</option>
                                <option value="completed">Completed</option>
                            </select>
                        </ListGroup.Item>
                        <ListGroup.Item>
                            <button 
                                className="btn btn-secondary" 
                                onClick={() => navigate('/admin-dashboard')} // Adjust the path as per your route configuration
                            >
                                Back to Dashboard
                            </button>
                        </ListGroup.Item>

                    </ListGroup>
                )}
            </Card>
        </Container>
    );
};

export default AdminOrderDetails;
