import React, { useState, useEffect } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { projectFirestore } from '../../../firebase/config';
import { collection, getDocs, deleteDoc, doc } from 'firebase/firestore';
import { Button } from 'react-bootstrap';

const CategoryConfigurationList = () => {
  const [configMappings, setConfigMappings] = useState([]);
  const { mappingId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchConfigMappings = async () => {
      const querySnapshot = await getDocs(collection(projectFirestore, 'categoryConfigurationMapping'));
      const fetchedConfigMappings = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setConfigMappings(fetchedConfigMappings);
    };

    fetchConfigMappings();
  }, []);

  const handleDelete = async (id) => {
    if (window.confirm('Are you sure you want to delete this configuration mapping?')) {
      const docRef = doc(projectFirestore, 'categoryConfigurationMapping', id);
      try {
        await deleteDoc(docRef);
        alert('Configuration mapping deleted!');
        setConfigMappings(configMappings.filter(mapping => mapping.id !== id)); // Remove the deleted item from the list
      } catch (error) {
        console.error('Error deleting configuration:', error);
        alert('Error deleting configuration');
      }
    }
  };

  return (
    <div className="container mt-5">
      <h2 className="mb-4">Category Configuration Mappings</h2>
      {configMappings.length > 0 ? (
        <table className="table table-hover">
          <thead className="thead-light">
            <tr>
              <th>Mapping Name</th>
              <th>Category Name</th>
              <th>Sub-Category Name</th>
              <th>Configuration ID</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {configMappings.map((mapping) => (
              <tr key={mapping.id}>
                <td>{mapping.id}</td>
                <td>{mapping.categoryName}</td>
                <td>{mapping.subCategoryName}</td>
                <td>{mapping.configurationId}</td>
                <td>
                  <Button variant="primary" btn-sm onClick={() => navigate(`/category-configuration-mapping/${mapping.id}`)}>Edit</Button>
                  {/* <Button variant="danger" btn-sm onClick={() => handleDelete(mapping.id)}>Delete</Button> */}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <div>No mappings found</div>
      )}
    </div>
  );
};

export default CategoryConfigurationList;
