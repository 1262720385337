import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom'; // Add this line to import useNavigate
import { projectFirestore, projectAuth } from '../../firebase/config';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { UserContext } from '../contexts/UserContext';

import styles from './UserDashboard.module.css';

function UserDashboard() {
  // const user = useContext(UserContext);
  const { currentUser } = useContext(UserContext);
  const [pastBookings, setPastBookings] = useState([]);
  const [upcomingBookings, setUpcomingBookings] = useState([]);
  const [paymentHistory, setPaymentHistory] = useState([]);
  const navigate = useNavigate(); 
  
  useEffect(() => {
    const fetchHistoricalData = async () => {
      if (currentUser?.uid)  {
        // Fetch past bookings
        const pastBookingsQuery = query(collection(projectFirestore, 'bookings'), where('userId', '==', currentUser.uid), where('date', '<', new Date()));
        const pastBookingsSnapshot = await getDocs(pastBookingsQuery);
        setPastBookings(pastBookingsSnapshot.docs.map(doc => doc.data()));

        // Fetch upcoming bookings
        const upcomingBookingsQuery = query(collection(projectFirestore, 'bookings'), where('userId', '==', currentUser.uid), where('date', '>=', new Date()));
        const upcomingBookingsSnapshot = await getDocs(upcomingBookingsQuery);
        setUpcomingBookings(upcomingBookingsSnapshot.docs.map(doc => doc.data()));

        // Fetch payment history
        const paymentHistoryQuery = query(collection(projectFirestore, 'payments'), where('userId', '==', currentUser.uid));
        const paymentHistorySnapshot = await getDocs(paymentHistoryQuery);
        setPaymentHistory(paymentHistorySnapshot.docs.map(doc => doc.data()));
      }
    };

    fetchHistoricalData();
  }, [currentUser, navigate]);
  

  return (
    <div className={styles.dashboard}>
      <h2>Welcome, {currentUser ? currentUser.displayName : "Guest"}!</h2>

      <section className={styles.pastBookings}>
        <h3>Past Bookings</h3>
        <ul>
          {pastBookings.map(booking => (
            <li key={booking.id}>
              {booking.date} - {booking.service} - {booking.amount}
            </li>
          ))}
        </ul>
      </section>

      <section className={styles.upcomingBookings}>
        <h3>Upcoming Bookings</h3>
        <ul>
          {upcomingBookings.map(booking => (
            <li key={booking.id}>
              {booking.date} - {booking.service} - {booking.amount}
            </li>
          ))}
        </ul>
      </section>

      <section className={styles.paymentHistory}>
        <h3>Payment History</h3>
        <ul>
          {paymentHistory.map(payment => (
            <li key={payment.id}>
              {payment.date} - {payment.amount} - {payment.status}
            </li>
          ))}
        </ul>
      </section>

      <section className={styles.profileSettings}>
        <button>Edit Profile</button>
      </section>
    </div>
  );
}
export default UserDashboard;
