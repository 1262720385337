import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { Container, Row, Col, Card, ButtonGroup, ToggleButton } from 'react-bootstrap';
import { collection, query, where, getDocs, doc, getDoc } from 'firebase/firestore';
import { projectFirestore } from '../../../firebase/config';

const SubCategoryPostPage = () => {
  const { categoryId, subCategoryId } = useParams();
  const [announcements, setAnnouncements] = useState([]);
  const [loading, setLoading] = useState(true);
  const [view, setView] = useState('grid'); // 'grid' or 'list'
  const [subCategoryName, setSubCategoryName] = useState('');

  useEffect(() => {
    const fetchSubCategoryName = async () => {
      const catRef = doc(projectFirestore, 'advertcategories', categoryId);
      const catSnap = await getDoc(catRef);
      if (catSnap.exists()) {
        const subCategory = catSnap.data().subCategories.find(sub => sub.subCategoryId === subCategoryId);
        if (subCategory) {
          setSubCategoryName(subCategory.name);
        } else {
          console.log('Sub-category not found');
        }
      } else {
        console.log('Category not found');
      }
    };

    fetchSubCategoryName();
  }, [categoryId, subCategoryId]);

  useEffect(() => {
    const fetchAnnouncements = async () => {
      setLoading(true);
      try {
        const q = query(
          collection(projectFirestore, 'announcements'),
          where('categoryId', '==', categoryId),
          where('subCategoryId', '==', subCategoryId)
        );
        const querySnapshot = await getDocs(q);
        
        if (!querySnapshot.empty) {
          const fetchedAnnouncements = querySnapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data()
          }));
          setAnnouncements(fetchedAnnouncements);
        } else {
          console.log("No announcements found for this sub-category.");
        }
      } catch (error) {
        console.error('Error fetching announcements:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchAnnouncements();
  }, [categoryId, subCategoryId]);

  const toggleView = (selectedView) => {
    setView(selectedView);
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <Container>
      <Row>
        <Col md={12}>
          <h2>Announcements in {subCategoryName}</h2>
          <ButtonGroup className="mb-2">
            <ToggleButton
              type="radio"
              variant="outline-secondary"
              name="view"
              value="grid"
              checked={view === 'grid'}
              onChange={(e) => toggleView(e.currentTarget.value)}>
              Grid
            </ToggleButton>
            <ToggleButton
              type="radio"
              variant="outline-secondary"
              name="view"
              value="list"
              checked={view === 'list'}
              onChange={(e) => toggleView(e.currentTarget.value)}>
              List
            </ToggleButton>
          </ButtonGroup>
          <Row>
            {announcements.map((announcement) => (
              <Col key={announcement.id} md={view === 'grid' ? 4 : 12}>
                <Link to={`/announcements/details/${announcement.id}`} className="text-decoration-none text-dark">
                  <Card className={`mb-3 ${view === 'list' ? 'flex-row' : ''}`}>
                    {view === 'grid' && announcement.images && announcement.images.length > 0 && (
                      <Card.Img variant="top" src={announcement.images[0]} style={{ height: '200px', objectFit: 'cover' }} />
                    )}
                    {view === 'list' && (
                      <div style={{ width: '30%' }}>
                        {announcement.images.slice(0, 2).map((image, index) => (
                          <Card.Img key={index} src={image} style={{ width: '100%', height: '200px', objectFit: 'cover' }} />
                        ))}
                      </div>
                    )}
                    <Card.Body>
                      <Card.Title style={styles.truncateTitle}>{announcement.title}</Card.Title>
                      <Card.Text style={styles.truncateText}>{announcement.description}</Card.Text>
                    </Card.Body>
                  </Card>
                </Link>
              </Col>
            ))}
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

const styles = {
  truncateTitle: {
    display: '-webkit-box',
    WebkitLineClamp: 2,
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  truncateText: {
    display: '-webkit-box',
    WebkitLineClamp: 3,
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  }
};

export default SubCategoryPostPage;




