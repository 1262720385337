// AdminDashboard.js
import React, { useState, useEffect, useContext } from 'react';
import { projectFirestore } from '../../firebase/config';
import { collection, query, onSnapshot } from 'firebase/firestore';
import { UserContext } from '../contexts/UserContext';
import { useNavigate } from 'react-router-dom';
import { Table, Alert } from 'react-bootstrap';

const AdminDashboard = () => {
    const [orders, setOrders] = useState([]);
    const [error, setError] = useState('');
    const { currentUser } = useContext(UserContext);
    const navigate = useNavigate();


    
    // Fetch orders from Firestore
    useEffect(() => {
        console.log('Current user role:', currentUser?.role); // Debugging line
         if (currentUser && currentUser.role === 'admin') {
            const q = query(collection(projectFirestore, 'cleaningOrders'));
            const unsubscribe = onSnapshot(q, (querySnapshot) => {
                const ordersData = querySnapshot.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data(),
                }));
                setOrders(ordersData);
            }, (err) => {
                console.error('Error fetching orders from AdminDashboard:', err);
                setError('Failed to fetch orders.');
            });

            return () => unsubscribe();
         } else {
            navigate('/login'); // Redirect non-admin users to login
         }
    }, [currentUser, navigate]);

    // Handle order click
    const handleOrderClick = (orderId) => {
        console.log('Order clicked:', orderId);
        // Navigate to order details page or expand to show details
    };

    if (!currentUser || currentUser.role !== 'admin') {
        return <Alert variant="danger">Access denied. Admins only.</Alert>;
    }

    return (
        <div className="container mt-5">
            <h2 className="mb-4">Admin Dashboard</h2>
            {error && <Alert variant="danger">{error}</Alert>}
            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Customer Name</th>
                        <th>Email</th>
                        <th>Service</th>
                        <th>Price</th>
                        <th>Date</th>
                        <th>Time</th>
                        <th>Status</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {orders.map((order, index) => (
                        <tr key={order.id} onClick={() => handleOrderClick(order.id)}>
                            <td>{index + 1}</td>
                            <td>{order.customerInfo.name}</td>
                            <td>{order.customerInfo.email}</td>
                            <td>{order.serviceDetails.selectedService}</td>
                            <td>GHS {order.serviceDetails.calculatedPrice}</td>
                            <td>{order.serviceDetails.serviceDate}</td>
                            <td>{order.serviceDetails.serviceTime}</td>
                            <td>{order.orderStatus}</td>
                            <td>
                                <button 
                                    className="btn btn-primary btn-sm"
                                    onClick={() => navigate(`/orders/${order.id}`)} // Redirect to OrderDetails
                                >
                                    View
                                </button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        </div>
    );
};

export default AdminDashboard;
