// import React, { useState, useEffect } from "react";
// import drawService from "../Services/drawService";
// import { Table } from "react-bootstrap";

// const MonthlyWinners = () => {
//   const [winners, setWinners] = useState([]);

//   useEffect(() => {
//     drawService.getMonthlyWinners().then(setWinners);
//   }, []);

//   const formatDate = (seconds) => {
//     const date = new Date(seconds * 1000);
//     const day = date.getDate().toString().padStart(2, "0");
//     const month = (date.getMonth() + 1).toString().padStart(2, "0");
//     const year = date.getFullYear();
//     return `${day}-${month}-${year}`;
//   };

//   return (
//     <div>
//       <h2 className="mt-4">Monthly Draw Winners</h2>
//       <div className="table-responsive">
//         <Table striped bordered hover>
//           <thead>
//             <tr>
//               <th>Date</th>
//               <th>Name</th>
//               <th>Amount</th>
//             </tr>
//           </thead>
//           <tbody>
//             {winners
//               .sort((a, b) => b.date.seconds - a.date.seconds)
//               .map((winner, index) => (
//                 <tr
//                   key={index}
//                   className={index === 0 ? "table-primary" : ""}
//                   style={index === 0 ? { fontWeight: "bold" } : {}}
//                 >
//                   <td>{formatDate(winner.date.seconds)}</td>
//                   <td>{winner.name}</td>
//                   <td>{winner.amount} cedis</td>
//                 </tr>
//               ))}
//           </tbody>
//         </Table>
//       </div>
//     </div>
//   );
// };

// export default MonthlyWinners;


import React, { useState, useEffect } from "react";
import drawService from "../Services/drawService";
import { Table } from "react-bootstrap";

const MonthlyWinners = () => {
  const [winners, setWinners] = useState([]);

  useEffect(() => {
    drawService.getMonthlyWinners().then(setWinners);
  }, []);

  const formatDate = (seconds) => {
    const date = new Date(seconds * 1000);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };

  return (
    <div>
      <h2 className="mt-4">Monthly Draw Winners</h2>
      <div className="table-responsive">
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Date</th>
              <th>Name</th>
              <th>Amount</th>
              <th>Claimed</th>
              <th>Paid</th>
            </tr>
          </thead>
          <tbody>
            {winners
              .sort((a, b) => b.date.seconds - a.date.seconds)
              .map((winner, index) => (
                <tr key={index}>
                  <td>{formatDate(winner.date.seconds)}</td>
                  <td>{winner.name}</td>
                  <td>{winner.amount} cedis</td>
                  <td
                    style={{
                      fontWeight: "bold",
                      color: winner.claimed ? "blue" : "black",
                    }}
                  >
                    {winner.claimed ? "Claimed" : "Not Claimed"}
                  </td>
                  <td
                    style={{
                      fontWeight: "bold",
                      color: winner.paid === "Yes Paid" ? "blue" : "black",
                    }}
                  >
                    {winner.paid || "Not Paid"}
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
      </div>
    </div>
  );
};

export default MonthlyWinners;
