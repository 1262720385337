import React, { useState, useEffect, useContext } from 'react';
import { UserContext } from '../../contexts/UserContext';
import { projectFirestore } from '../../../firebase/config'; 
import { getFirestore, collection, getDocs, addDoc, getDoc, doc, updateDoc, deleteDoc, arrayUnion } from 'firebase/firestore';
import { Table, Button, Modal, Form, Row, Col, Card } from 'react-bootstrap';

const AdminDeliveryCategories = () => {
    const { currentUser } = useContext(UserContext);
    const [categories, setCategories] = useState([]);
    const [newCategoryName, setNewCategoryName] = useState('');
    const [newCategoryAttributes, setNewCategoryAttributes] = useState([{ key: '', value: '' }]);
    const [newSubCategoryName, setNewSubCategoryName] = useState('');
    const [newSubCategoryAttributes, setNewSubCategoryAttributes] = useState([{ key: '', value: '' }]);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const [selectedCategoryId, setSelectedCategoryId] = useState(null);
    const [editingCategoryId, setEditingCategoryId] = useState(null);
    const [editingSubCategoryId, setEditingSubCategoryId] = useState(null);
    const [editingCategoryName, setEditingCategoryName] = useState('');
    const [editingSubCategoryName, setEditingSubCategoryName] = useState('');

    const [showCategoryDeleteConfirmation, setShowCategoryDeleteConfirmation] = useState(false);
    const [showSubCategoryDeleteConfirmation, setShowSubCategoryDeleteConfirmation] = useState(false);
    const [categoryToDelete, setCategoryToDelete] = useState(null);
    const [subCategoryToDelete, setSubCategoryToDelete] = useState(null);

    const firestore = getFirestore();

    // Add a utility function to validate category or sub-category data
    const isValidCategoryData = (name, attributes) => {
        if (!name.trim()) return false; // Check if name is not empty
        // Ensure all attributes have non-empty keys and values
        return attributes.every(attr => attr.key.trim() && attr.value.trim());
    };

    

    useEffect(() => {
        const fetchCategories = async () => {
            try {
                setIsLoading(true);
                const snapshot = await getDocs(collection(projectFirestore, 'DeliveryCategories'));
                const categoriesData = snapshot.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data()
                }));
                setCategories(categoriesData);
            } catch (error) {
                setError(error.message);
            } finally {
                setIsLoading(false);
            }
        };

        fetchCategories();
    }, []);

    // Function to add a new attribute field for categories
    const addCategoryAttributeField = () => {
        setNewCategoryAttributes([...newCategoryAttributes, { key: '', value: '' }]);
    };

    // Function to remove an attribute field for categories
    const removeCategoryAttributeField = index => {
        setNewCategoryAttributes(newCategoryAttributes.filter((_, i) => i !== index));
    };

    // Function to handle category attribute changes
    const handleCategoryAttributeChange = (index, event) => {
        const updatedAttributes = newCategoryAttributes.map((attribute, i) => {
            if (i === index) {
                return { ...attribute, [event.target.name]: event.target.value };
            }
            return attribute;
        });
        setNewCategoryAttributes(updatedAttributes);
    };

    // Function to handle sub-category attribute changes
    const handleAttributeChange = (index, event) => {
        const updatedAttributes = newSubCategoryAttributes.map((attribute, i) => {
            if (i === index) {
                return { ...attribute, [event.target.name]: event.target.value };
            }
            return attribute;
        });
        setNewSubCategoryAttributes(updatedAttributes);
    };

    // Function to add a new attribute field for sub-categories
    const addAttributeField = () => {
        setNewSubCategoryAttributes([...newSubCategoryAttributes, { key: '', value: '' }]);
    };

    // Function to remove an attribute field for sub-categories
    const removeAttributeField = index => {
        setNewSubCategoryAttributes(newSubCategoryAttributes.filter((_, i) => i !== index));
    };

    // Function to add a new category
    const handleAddCategory = async () => {
        // Validate data before adding
        if (!isValidCategoryData(newCategoryName, newCategoryAttributes)) {
            setError("Please fill in all fields correctly.");
            return;
        }
    
        const attributesObject = newCategoryAttributes.reduce((obj, item) => {
            if (item.key) obj[item.key] = item.value;
            return obj;
        }, {});
    
        try {
            setIsLoading(true);
            const docRef = await addDoc(collection(projectFirestore, 'DeliveryCategories'), {
                name: newCategoryName,
                attributes: attributesObject,
                subCategories: []
            });
            setNewCategoryName('');
            setNewCategoryAttributes([{ key: '', value: '' }]);
            console.log("Category added with ID: ", docRef.id);
            await refreshCategories(); // Refresh categories after adding
        } catch (err) {
            setError(err.message);
        } finally {
            setIsLoading(false);
        }
    };

    // Function to refresh categories
    const refreshCategories = async () => {
        setIsLoading(true);
        try {
            const querySnapshot = await getDocs(collection(projectFirestore, 'DeliveryCategories'));
            const categoriesData = querySnapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data()
            }));
            setCategories(categoriesData);
        } catch (err) {
            setError(err.message);
        } finally {
            setIsLoading(false);
        }
    };

    // Function to add a new sub-category
    const handleAddSubCategory = async (categoryId) => {
        // Validate data before adding
        if (!isValidCategoryData(newSubCategoryName, newSubCategoryAttributes)) {
            setError("Please fill in all fields correctly.");
            return;
        }
    
        const attributesObject = newSubCategoryAttributes.reduce((obj, item) => {
            if (item.key) obj[item.key] = item.value;
            return obj;
        }, {});
    
        try {
            setIsLoading(true);
            const newSubCategory = {
                id: new Date().getTime().toString(), // Generate unique ID for the sub-category
                name: newSubCategoryName,
                attributes: attributesObject
            };
    
            console.log("New sub-category:", newSubCategory);
    
            const categoryRef = doc(projectFirestore, 'DeliveryCategories', selectedCategoryId);
            await updateDoc(categoryRef, {
                subCategories: arrayUnion(newSubCategory)
            });
    
            console.log("Sub-category added to Firestore successfully!");
    
            setNewSubCategoryName('');
            setNewSubCategoryAttributes([{ key: '', value: '' }]);
        } catch (err) {
            setError(err.message);
            console.error("Error adding sub-category:", err);
        } finally {
            setIsLoading(false);
            console.log("Loading state set to false.");
            await refreshCategories();
            console.log("Categories refreshed after adding sub-category.");
        }
    };

    // Function to delete a category
    const handleDeleteCategory = async (categoryId) => {
        try {
            setIsLoading(true);
            await deleteDoc(doc(projectFirestore, 'DeliveryCategories', categoryId));
        } catch (err) {
            setError(err.message);
        } finally {
            setIsLoading(false);
        }
    };

    // Function to delete a sub-category
    const handleDeleteSubCategory = async (categoryId, subCategoryId) => {
        console.log("Deleting sub-category with ID:", subCategoryId);
        try {
            setIsLoading(true);
            const categoryRef = doc(projectFirestore, 'DeliveryCategories', categoryId);
            const categoryDoc = await getDoc(categoryRef);
    
            if (!categoryDoc.exists()) {
                throw new Error("Category not found");
            }
    
            // Filter out the sub-category to delete
            const updatedSubCategories = categoryDoc.data().subCategories.filter(subCat => subCat.id !== subCategoryId);
    
            // Update the category with the new array of sub-categories
            await updateDoc(categoryRef, { subCategories: updatedSubCategories });
    
            console.log("Sub-category deleted, refreshing categories.");
            await refreshCategories();
        } catch (err) {
            console.error("Error deleting sub-category:", err);
            setError(err.message);
        } finally {
            setIsLoading(false);
        }
    };

    // Function to start editing a category
    const startEditingCategory = (category) => {
        setEditingCategoryId(category.id);
        setEditingCategoryName(category.name);
        const globalAttributes = Object.entries(category.attributes || {}).map(([key, value]) => ({ key, value }));
        setNewCategoryAttributes(globalAttributes);
    };

    // Function to start editing a sub-category
    const startEditingSubCategory = (categoryId, subCategory) => {
        setSelectedCategoryId(categoryId); // Set the selected category ID
        setEditingSubCategoryId(subCategory.id); // Set the editing sub-category ID
        setEditingSubCategoryName(subCategory.name); // Set the sub-category name for editing
    
        // Load specific attributes for editing
        const specificAttributes = Object.entries(subCategory.attributes || {}).map(([key, value]) => ({ key, value }));
        setNewSubCategoryAttributes(specificAttributes);
    };

    // Function to reset edit state
    const resetEditState = () => {
        setEditingCategoryId(null);
        setEditingSubCategoryId(null);
        setNewCategoryName('');
        setNewSubCategoryName('');
        setNewCategoryAttributes([{ key: '', value: '' }]);
        setNewSubCategoryAttributes([{ key: '', value: '' }]);
        setError(null); // Clear any existing error
    };

    // Function to handle updating a category
    const handleUpdateCategory = async () => {
        // Validate data before updating
        if (!isValidCategoryData(editingCategoryName, newCategoryAttributes)) {
            setError("Please fill in all fields correctly.");
            return;
        }
        const attributesObject = newCategoryAttributes.reduce((obj, item) => {
            if (item.key) obj[item.key] = item.value;
            return obj;
        }, {});
    
        try {
            setIsLoading(true);
            await updateDoc(doc(projectFirestore, 'DeliveryCategories', editingCategoryId), {
                name: editingCategoryName,
                attributes: attributesObject
            });
            await refreshCategories();
        } catch (err) {
            setError(err.message);
        } finally {
            setIsLoading(false);
            resetEditState();
        }
    };

    // Function to handle updating a sub-category
    const handleUpdateSubCategory = async () => {
        // Validate data before updating
        if (!isValidCategoryData(editingSubCategoryName, newSubCategoryAttributes)) {
            setError("Please fill in all fields correctly.");
            return;
        }
        const attributesObject = newSubCategoryAttributes.reduce((obj, item) => {
            if (item.key) obj[item.key] = item.value;
            return obj;
        }, {});
    
        try {
            setIsLoading(true);
            const categoryRef = doc(projectFirestore, 'DeliveryCategories', selectedCategoryId);
            const categoryDoc = await getDoc(categoryRef);
            const updatedSubCategories = categoryDoc.data().subCategories.map(subCat => {
                if (subCat.id === editingSubCategoryId) {
                    return { ...subCat, name: editingSubCategoryName, attributes: attributesObject };
                }
                return subCat;
            });
    
            await updateDoc(categoryRef, { subCategories: updatedSubCategories });
            await refreshCategories();
        } catch (err) {
            setError(err.message);
        } finally {
            setIsLoading(false);
            resetEditState();
        }
    };

    // Function to render attribute fields for sub-categories
    const renderAttributeFields = () => {
        return newSubCategoryAttributes.map((attribute, index) => (
            <div key={index}>
                <input
                    type="text"
                    placeholder="Attribute Key"
                    name="key"
                    value={attribute.key}
                    onChange={(e) => handleAttributeChange(index, e)}
                />
                <input
                    type="text"
                    placeholder="Attribute Value"
                    name="value"
                    value={attribute.value}
                    onChange={(e) => handleAttributeChange(index, e)}
                />
                <Button variant="danger" onClick={() => removeAttributeField(index)}>Remove</Button>
            </div>
        ));
    };

    // Function to render attributes for a category
    const renderCategoryAttributes = (attributes) => {
        return Object.entries(attributes || {}).map(([key, value]) => (
            <span key={key}>{key}: {value}; </span>
        ));
    };

    // Function to render attributes for a sub-category
    const renderSubCategoryAttributes = (subCategory, categoryAttributes) => {
        const combinedAttributes = { ...categoryAttributes, ...subCategory.attributes };
        return Object.entries(combinedAttributes).map(([key, value]) => (
            <span key={key}>{key}: {value}; </span>
        ));
    };

    const showCategoryDeleteModal = (category) => {
        setCategoryToDelete(category);
        setShowCategoryDeleteConfirmation(true);
    };
    
    const hideCategoryDeleteModal = () => {
        setCategoryToDelete(null);
        setShowCategoryDeleteConfirmation(false);
    };
    
    const showSubCategoryDeleteModal = (category, subCategory) => {
        setCategoryToDelete(category);
        setSubCategoryToDelete(subCategory);
        setShowSubCategoryDeleteConfirmation(true);
    };
    
    const hideSubCategoryDeleteModal = () => {
        setCategoryToDelete(null);
        setSubCategoryToDelete(null);
        setShowSubCategoryDeleteConfirmation(false);
    };
    

    return (
        <div className="adminDeliveryCategories">
            {/* Loading and error messages */}
            {isLoading && <p>Loading...</p>}
            {error && <p className="error-message">{error}</p>} {/* Display error message */}
    
            <h2>Manage Delivery Categories</h2>
    
                        {/* Form to add new category with global attributes */}
                        <Card>
                <Card.Body>
                    <Form>
                        <Form.Group as={Row} controlId="formCategoryName">
                            <Form.Label column sm={2}>Category Name</Form.Label>
                            <Col sm={10}>
                                <Form.Control
                                    type="text"
                                    placeholder="Category Name"
                                    value={editingCategoryId ? editingCategoryName : newCategoryName}
                                    onChange={(e) => editingCategoryId ? setEditingCategoryName(e.target.value) : setNewCategoryName(e.target.value)}
                                />
                            </Col>
                        </Form.Group>

                        {/* Category attributes input fields */}
                        {newCategoryAttributes.map((attribute, index) => (
                            <Row key={index}>
                                <Col>
                                    <Form.Control
                                        type="text"
                                        placeholder="Attribute Key"
                                        name="key"
                                        value={attribute.key}
                                        onChange={(e) => handleCategoryAttributeChange(index, e)}
                                    />
                                </Col>
                                <Col>
                                    <Form.Control
                                        type="text"
                                        placeholder="Attribute Value"
                                        name="value"
                                        value={attribute.value}
                                        onChange={(e) => handleCategoryAttributeChange(index, e)}
                                    />
                                </Col>
                                <Col>
                                    <Button variant="danger" onClick={() => removeCategoryAttributeField(index)}>Remove</Button>
                                </Col>
                            </Row>
                        ))}
                        <Button variant="secondary" onClick={addCategoryAttributeField}>Add Category Attribute</Button>

                        {editingCategoryId ?
                            <Button variant="primary" onClick={handleUpdateCategory}>Update Category</Button> :
                            <Button variant="success" onClick={handleAddCategory}>Add Category</Button>
                        }
                        {editingCategoryId && (
                            <Button variant="secondary" onClick={resetEditState}>Cancel Edit</Button>
                        )}
                    </Form>
                </Card.Body>
            </Card>

            {/* Form to add new sub-category */}
            <Card className="mt-3">
                <Card.Body>
                    <Form>
                        <Form.Group as={Row} controlId="formCategorySelection">
                            <Form.Label column sm={2}>Select Category</Form.Label>
                            <Col sm={10}>
                                <Form.Select onChange={(e) => setSelectedCategoryId(e.target.value)}>
                                    <option value="">Select a Category</option>
                                    {categories.map(category => (
                                        <option key={category.id} value={category.id}>{category.name}</option>
                                    ))}
                                </Form.Select>
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row} controlId="formSubCategoryName">
                            <Form.Label column sm={2}>Sub-Category Name</Form.Label>
                            <Col sm={10}>
                                <Form.Control
                                    type="text"
                                    placeholder="Sub-Category Name"
                                    value={editingSubCategoryId ? editingSubCategoryName : newSubCategoryName}
                                    onChange={(e) => editingSubCategoryId ? setEditingSubCategoryName(e.target.value) : setNewSubCategoryName(e.target.value)}
                                />
                            </Col>
                        </Form.Group>

                        {newSubCategoryAttributes.map((attribute, index) => (
                            <Row key={index}>
                                <Col>
                                    <Form.Control
                                        type="text"
                                        placeholder="Attribute Key"
                                        name="key"
                                        value={attribute.key}
                                        onChange={(e) => handleAttributeChange(index, e)}
                                    />
                                </Col>
                                <Col>
                                    <Form.Control
                                        type="text"
                                        placeholder="Attribute Value"
                                        name="value"
                                        value={attribute.value}
                                        onChange={(e) => handleAttributeChange(index, e)}
                                    />
                                </Col>
                                <Col>
                                    <Button variant="danger" onClick={() => removeAttributeField(index)}>Remove</Button>
                                </Col>
                            </Row>
                        ))}
                        <Button variant="secondary" onClick={addAttributeField}>Add Sub-Category Attribute</Button>
                        {editingSubCategoryId ?
                            <Button variant="primary" onClick={handleUpdateSubCategory}>Update Sub-Category</Button> :
                            <Button variant="success" onClick={() => handleAddSubCategory(selectedCategoryId)}>Add Sub-Category</Button>
                        }
                        {editingSubCategoryId && (
                            <Button variant="secondary" onClick={resetEditState}>Cancel Edit</Button>
                        )}
                    </Form>
                </Card.Body>
            </Card>
    
           
    
            {/* Display categories and sub-categories */}
            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th>Category Name</th>
                        <th>Attributes</th>
                        <th>Sub-Categories</th>
                        <th>Actions</th> {/* Add a column for actions */}
                    </tr>
                </thead>
                <tbody>
                    {categories.map(category => (
                        <tr key={category.id}>
                        <td>{category.name}</td>
                        <td>{renderCategoryAttributes(category.attributes)}</td>
                        <td>
                            <ul>
                                {category.subCategories.map(subCategory => (
                                    <li key={subCategory.id}>
                                        {subCategory.name} - {renderSubCategoryAttributes(subCategory, category.attributes)}
                                        <Button variant="info" onClick={() => startEditingSubCategory(category.id, subCategory)}>Edit</Button>
                                        <Button variant="danger" onClick={() => showSubCategoryDeleteModal(category, subCategory)}>Delete</Button>
                                    </li>
                                ))}
                            </ul>
                        </td>
                        <td>
                            <Button variant="info" onClick={() => startEditingCategory(category)}>Edit</Button>
                            <Button variant="danger" onClick={() => showCategoryDeleteModal(category)}>Delete</Button>
                        </td>
                    </tr>
                    
                    ))}
                </tbody>
            </Table>
    
            {/* Delete category confirmation modal */}
            <Modal show={showCategoryDeleteConfirmation} onHide={hideCategoryDeleteModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Delete Category</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Are you sure you want to delete the category {categoryToDelete && categoryToDelete.name}?
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={hideCategoryDeleteModal}>Cancel</Button>
                    <Button variant="danger" onClick={() => handleDeleteCategory(categoryToDelete.id)}>Delete</Button>
                </Modal.Footer>
            </Modal>
    
            {/* Delete sub-category confirmation modal */}
            <Modal show={showSubCategoryDeleteConfirmation} onHide={hideSubCategoryDeleteModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Delete Sub-Category</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Are you sure you want to delete the sub-category {subCategoryToDelete && subCategoryToDelete.name}?
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={hideSubCategoryDeleteModal}>Cancel</Button>
                    <Button variant="danger" onClick={() => handleDeleteSubCategory(categoryToDelete.id, subCategoryToDelete.id)}>Delete</Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
    
};

export default AdminDeliveryCategories;
