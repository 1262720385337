import { getFirestore } from "firebase/firestore";
import { 
  haversineDistance, 
  adjustDistance, 
  calculateDistanceFee, 
  calculateWeightCost, 
  calculateSizeCost, 
  calculateWeightSizeMultiplier,
  SERVICE_LEVEL_MULTIPLIER 
} from './DeliveryCostHelpers';

export const calculateDeliveryCost = async (packageDetails, serviceLevel, pickupAddress, deliveryAddress) => {
  try {
    const firestore = getFirestore();

    const weight = parseFloat(packageDetails.weight);
    const dimensions = {
      length: parseFloat(packageDetails.dimensions.length),
      width: parseFloat(packageDetails.dimensions.width),
      height: parseFloat(packageDetails.dimensions.height),
    };

    if (isNaN(weight) || isNaN(dimensions.length) || isNaN(dimensions.width) || isNaN(dimensions.height)) {
      throw new Error("Invalid package details provided for delivery cost calculation.");
    }

    const pickupCoords = {
      lat: parseFloat(pickupAddress.latitude),
      lng: parseFloat(pickupAddress.longitude)
    };

    const deliveryCoords = {
      lat: parseFloat(deliveryAddress.latitude),
      lng: parseFloat(deliveryAddress.longitude)
    };

    // Validate coordinates
    if (isNaN(pickupCoords.lat) || isNaN(pickupCoords.lng) || isNaN(deliveryCoords.lat) || isNaN(deliveryCoords.lng)) {
      throw new Error("Invalid coordinates for delivery cost calculation.");
    }

    const straightLineDistance = haversineDistance(pickupCoords, deliveryCoords);
    const adjustedDistance = adjustDistance(straightLineDistance);
    const distanceFee = calculateDistanceFee(adjustedDistance);
    const weightCost = calculateWeightCost(weight);
    const sizeCost = calculateSizeCost(dimensions);
    const weightSizeMultiplier = calculateWeightSizeMultiplier(weight, dimensions);
    const totalCost = (distanceFee + weightCost + sizeCost) * weightSizeMultiplier * SERVICE_LEVEL_MULTIPLIER[serviceLevel];

    return totalCost;
  } catch (error) {
    console.error("Error calculating delivery cost:", error);
    return 0;
  }
};
