// import React, { useState, useEffect } from 'react';
// import { projectFirestore } from '../../firebase/config';
// import { collection, query, orderBy, onSnapshot, doc, getDoc } from 'firebase/firestore';

// const BookingsList = () => {
//   const [bookings, setBookings] = useState([]);
//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState(null);

//   const fetchServiceName = async (serviceId) => {
//     try {
//       const serviceRef = doc(projectFirestore, 'services', serviceId);
//       const serviceSnap = await getDoc(serviceRef);
//       return serviceSnap.exists() ? serviceSnap.data().name : 'Unknown Service';
//     } catch (err) {
//       console.error('Error fetching service:', err);
//       return 'Unknown Service';
//     }
//   };

//   const fetchUserName = async (userId) => {
//     try {
//       const userRef = doc(projectFirestore, 'users', userId);
//       const userSnap = await getDoc(userRef);
//       return userSnap.exists() ? userSnap.data().displayName : 'Unknown User';
//     } catch (err) {
//       console.error('Error fetching user:', err);
//       return 'Unknown User';
//     }
//   };

//   useEffect(() => {
//     const bookingsCollection = collection(projectFirestore, 'cleaningOrders');
//     const q = query(bookingsCollection, orderBy('date', 'asc'));
//     const unsubscribe = onSnapshot(q, async (snapshot) => {
//       let fetchedBookings = [];
//       for (const doc of snapshot.docs) {
//         const bookingData = doc.data();
//         const serviceName = await fetchServiceName(bookingData.serviceId);
//         const userName = await fetchUserName(bookingData.userId);
//         fetchedBookings.push({
//           id: doc.id,
//           serviceName,
//           userName,
//           ...bookingData
//         });
//       }
//       setBookings(fetchedBookings);
//       setLoading(false);
//     }, (err) => {
//       console.error(err);
//       setError('Failed to fetch bookings.');
//       setLoading(false);
//     });

//     return () => unsubscribe();
//   }, []);

//   if (loading) {
//     return <p>Loading bookings...</p>;
//   }

//   if (error) {
//     return <p>{error}</p>;
//   }

//   return (
//     <div>
//       <h2>Bookings List</h2>
//       {bookings.length > 0 ? (
//         <ul>
//           {bookings.map(booking => (
//             <li key={booking.id}>
//               {booking.date} - {booking.time} - {booking.serviceName} - {booking.status} - {booking.userName}
//             </li>
//           ))}
//         </ul>
//       ) : (
//         <p>No bookings found.</p>
//       )}
//     </div>
//   );
// };

// export default BookingsList;


import React, { useState, useEffect } from 'react';
import { projectFirestore } from '../../firebase/config';
import { collection, query, orderBy, onSnapshot, doc, getDoc } from 'firebase/firestore';

const CleaningOrdersList = () => {
  const [cleaningOrders, setCleaningOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchServiceName = async (serviceId) => {
    try {
      const serviceRef = doc(projectFirestore, 'services', serviceId);
      const serviceSnap = await getDoc(serviceRef);
      return serviceSnap.exists() ? serviceSnap.data().name : 'Unknown Service';
    } catch (err) {
      console.error('Error fetching service:', err);
      return 'Unknown Service';
    }
  };

  const fetchUserName = async (userId) => {
    try {
      const userRef = doc(projectFirestore, 'users', userId);
      const userSnap = await getDoc(userRef);
      return userSnap.exists() ? userSnap.data().displayName : 'Unknown User';
    } catch (err) {
      console.error('Error fetching user:', err);
      return 'Unknown User';
    }
  };

  useEffect(() => {
    const cleaningOrdersCollection = collection(projectFirestore, 'cleaningOrders');
    const q = query(cleaningOrdersCollection, orderBy('date', 'asc'));
    const unsubscribe = onSnapshot(q, async (snapshot) => {
      let fetchedCleaningOrders = [];
      for (const doc of snapshot.docs) {
        const cleaningOrderData = doc.data();
        const serviceName = await fetchServiceName(cleaningOrderData.serviceId);
        const userName = await fetchUserName(cleaningOrderData.userId);
        fetchedCleaningOrders.push({
          id: doc.id,
          serviceName,
          userName,
          ...cleaningOrderData
        });
      }
      setCleaningOrders(fetchedCleaningOrders);
      setLoading(false);
    }, (err) => {
      console.error(err);
      setError('Failed to fetch cleaning orders.');
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  if (loading) {
    return <p>Loading cleaning orders...</p>;
  }

  if (error) {
    return <p>{error}</p>;
  }

  return (
    <div>
      <h2>Cleaning Orders List</h2>
      {cleaningOrders.length > 0 ? (
        <ul>
          {cleaningOrders.map(order => (
            <li key={order.id}>
              {order.date} - {order.time} - {order.serviceName} - {order.status} - {order.userName}
            </li>
          ))}
        </ul>
      ) : (
        <p>No cleaning orders found.</p>
      )}
    </div>
  );
};

export default CleaningOrdersList;
