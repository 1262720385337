
import React, { useState, useEffect } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { doc, updateDoc, getDoc } from 'firebase/firestore';
import { projectFirestore } from '../../../../firebase/config';

const EditSubCategoryModal = ({ show, onHide, categoryId, subCategory, categoryOptions = [] }) => {
  const [subCategoryData, setSubCategoryData] = useState({
    name: subCategory?.name || '',
    icon1: subCategory?.icon1 || '',
    option: subCategory?.option || '' // Assuming sub-categories might have an 'option' field
  });
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    // Initialize form values when the subCategory prop changes
    setSubCategoryData({
      name: subCategory?.name || '',
      icon1: subCategory?.icon1 || '',
      option: subCategory?.option || ''
    });
  }, [subCategory]);

  const handleSave = async () => {
    setIsLoading(true);
    try {
      const categoryRef = doc(projectFirestore, 'advertcategories', categoryId);
      const categoryDoc = await getDoc(categoryRef);
      if (!categoryDoc.exists()) {
        throw new Error("Category doesn't exist.");
      }
      const updatedSubCategories = categoryDoc.data().subCategories.map(subCat =>
        subCat.id === subCategory.id ? { ...subCat, ...subCategoryData } : subCat
      );
      await updateDoc(categoryRef, { subCategories: updatedSubCategories });
      alert('Sub-category updated successfully!');
    } catch (error) {
      console.error('Error updating sub-category:', error);
      alert(error.message);
    } finally {
      setIsLoading(false);
      onHide(); // Close the modal
    }
  };

  return (
    <Modal show={show} onHide={onHide} backdrop="static" keyboard={false}>
      <Modal.Header closeButton>
        <Modal.Title>Edit Sub-Category</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group className="mb-3">
            <Form.Label>Name</Form.Label>
            <Form.Control
              type="text"
              value={subCategoryData.name}
              onChange={(e) => setSubCategoryData({ ...subCategoryData, name: e.target.value })}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Icon</Form.Label>
            <Form.Control
              type="text"
              value={subCategoryData.icon1}
              onChange={(e) => setSubCategoryData({ ...subCategoryData, icon1: e.target.value })}
            />
          </Form.Group>
          {categoryOptions.length > 0 && (
          <Form.Group className="mb-3">
            <Form.Label>Category Type</Form.Label>
            <Form.Select 
              value={subCategoryData.option} 
              onChange={(e) => setSubCategoryData({ ...subCategoryData, option: e.target.value })}
            >
              <option value="">Select Type</option>
              {categoryOptions.map((option, index) => (
                <option key={index} value={option}>{option}</option>
              ))}
            </Form.Select>
          </Form.Group>
        )}

        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide} disabled={isLoading}>
          Close
        </Button>
        <Button variant="primary" onClick={handleSave} disabled={isLoading}>
          Save Changes
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default EditSubCategoryModal;
