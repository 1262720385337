// import React, { useEffect, useState, useContext } from "react";
// import { UserContext } from "../contexts/UserContext";
// import {
//   requestNotificationsPermissions,
//   saveMessagingDeviceToken,
// } from "../../firebase/messaging";

// const Notifications = () => {
//   const { currentUser } = useContext(UserContext);
//   const [token, setToken] = useState("");
//   const [notificationError, setNotificationError] = useState(null);

//   useEffect(() => {
//     console.log("Current user in effect:", currentUser);
//     const fetchToken = async () => {
//       if (currentUser) {
//         try {
//           console.log("Requesting notifications permissions...");
//           const permissionGranted = await requestNotificationsPermissions(currentUser.uid);
//           if (permissionGranted) {
//             console.log("Saving messaging device token...");
//             const token = await saveMessagingDeviceToken(currentUser.uid);
//             console.log("Token saved:", token);
//             setToken(token);
//           } else {
//             console.log("Notification permissions not granted.");
//             setNotificationError("Notification permissions not granted.");
//           }
//         } catch (error) {
//           console.error(
//             "Error requesting notifications permissions or saving token:",
//             error
//           );
//           setNotificationError(error.message);
//         }
//       } else {
//         console.log("No current user, setting token to empty.");
//         setToken("");
//       }
//     };

//     fetchToken();
//   }, [currentUser]);

//   const sendNotification = async () => {
//     try {
//       console.log("Sending notification...");
//       const response = await fetch(
//         "https://us-central1-allghanaians.cloudfunctions.net/sendNotificationToAllUsers",
//         {
//           method: "POST",
//           headers: {
//             "Content-Type": "application/json",
//           },
//           body: JSON.stringify({
//             title: "Test Notification",
//             body: "This is a test notification from the Notifications component.",
//           }),
//         }
//       );

//       if (!response.ok) {
//         throw new Error("Failed to send notification");
//       }

//       console.log("Notifications sent successfully.");
//     } catch (error) {
//       console.error("Error sending notification:", error);
//     }
//   };

//   return (
//     <div>
//       <h2>Notifications</h2>
//       {currentUser ? (
//         <div>
//           <p>User: {currentUser.email}</p>
//           <p>FCM Token: {token || "Requesting permissions..."}</p>
//           <button onClick={sendNotification}>Send Test Notification</button>
//           {notificationError && (
//             <p style={{ color: "red" }}>
//               {notificationError}
//               <br />
//               Please enable notifications in your browser settings and refresh
//               the page.
//               <br />
//               For Chrome: Go to Settings, Privacy and Security, Site Settings,
//               Notifications.
//               <br />
//               For Firefox: Go to Options, Privacy & Security, Permissions,
//               Notifications.
//             </p>
//           )}
//         </div>
//       ) : (
//         <p>Please log in to receive notifications.</p>
//       )}
//     </div>
//   );
// };

// export default Notifications;


import React, { useEffect, useState, useContext } from "react";
import { UserContext } from "../contexts/UserContext";
import {
  requestNotificationsPermissions,
  saveMessagingDeviceToken,
} from "../../firebase/messaging";

const Notifications = () => {
  const { currentUser } = useContext(UserContext);
  const [token, setToken] = useState("");
  const [notificationError, setNotificationError] = useState(null);
  const [title, setTitle] = useState("");
  const [body, setBody] = useState("");

  useEffect(() => {
    const fetchToken = async () => {
      if (currentUser) {
        try {
          const permissionGranted = await requestNotificationsPermissions();
          if (permissionGranted) {
            const token = await saveMessagingDeviceToken(currentUser.uid);
            setToken(token);
          } else {
            setNotificationError("Notification permissions not granted.");
          }
        } catch (error) {
          setNotificationError(error.message);
        }
      }
    };

    fetchToken();
  }, [currentUser]);

  const sendNotification = async () => {
    try {
      const response = await fetch(
        "https://us-central1-allghanaians.cloudfunctions.net/sendNotificationToAllUsers",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            title,
            body,
          }),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to send notification");
      }

      console.log("Notifications sent successfully.");
    } catch (error) {
      console.error("Error sending notification:", error);
    }
  };

  return (
    <div>
      <h2>Notifications</h2>
      {currentUser ? (
        <div>
          <p>User: {currentUser.email}</p>
          <p>FCM Token: {token || "Requesting permissions..."}</p>
          <div>
            <label>
              Title:
              <input
                type="text"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
            </label>
          </div>
          <div>
            <label>
              Body:
              <input
                type="text"
                value={body}
                onChange={(e) => setBody(e.target.value)}
              />
            </label>
          </div>
          <button onClick={sendNotification}>Send Notification</button>
          {notificationError && (
            <p style={{ color: "red" }}>
              {notificationError}
              <br />
              Please enable notifications in your browser settings and refresh
              the page.
              <br />
              For Chrome: Go to Settings, Privacy and Security, Site Settings,
              Notifications.
              <br />
              For Firefox: Go to Options, Privacy & Security, Permissions,
              Notifications.
            </p>
          )}
        </div>
      ) : (
        <p>Please log in to receive notifications.</p>
      )}
    </div>
  );
};

export default Notifications;
