
import React, { useState, useEffect, useContext } from 'react';
import { Form, Button, Container, Alert } from 'react-bootstrap';
import { useParams, useNavigate } from 'react-router-dom';
import { doc, getDoc, collection, addDoc, setDoc, getDocs, query, where } from 'firebase/firestore';
import { projectFirestore, projectStorage } from '../../../firebase/config';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { resizeImageFile } from 'react-image-file-resizer';
import imageCompression from 'browser-image-compression'; // Import imageCompression library

import { UserContext } from '../../contexts/UserContext';

const AnnouncementsForm = () => {
  const { categoryId, subCategoryId } = useParams();
  const [formData, setFormData] = useState({
    images: [],  // Ensure this is always an array
    startDate: '', // assuming you also have a startDate
    endDate: '',
    // Include other fields if needed
});

  const [formConfig, setFormConfig] = useState(null);
  const navigate = useNavigate();
  const { currentUser } = useContext(UserContext);
  const [categoryDetails, setCategoryDetails] = useState({
    categoryName: '',
    subCategoryName: '',
    option: '',
    categoryId: '',
    subCategoryId: ''
  });
  const [uploading, setUploading] = useState(false);
  const [extraImageCost, setExtraImageCost] = useState(0);

  useEffect(() => {
    const fetchDetails = async () => {
      const categoryRef = doc(projectFirestore, 'advertcategories', categoryId);
      const categorySnap = await getDoc(categoryRef);
      if (categorySnap.exists()) {
        const categoryData = categorySnap.data();
        const subCategory = categoryData.subCategories.find(sub => sub.subCategoryId === subCategoryId);
        if (subCategory) {
          setCategoryDetails({
            categoryName: categoryData.name,
            subCategoryName: subCategory.name,
            option: subCategory.option,
            categoryId: categoryId, // Saving categoryId from the URL
            subCategoryId: subCategoryId // Saving subCategoryId from the URL
          });
        } else {
          console.error("Sub-category not found");
        }
      } else {
        console.error("Category not found");
      }
    };

    if (categoryId && subCategoryId) {
      fetchDetails();
    }
  }, [categoryId, subCategoryId, categoryDetails ]);

  useEffect(() => {
    const fetchFormConfig = async () => {
      const querySnapshot = await getDocs(query(
        collection(projectFirestore, 'categoryConfigurationMapping'),
        where("categoryName", "==", categoryDetails.categoryName),
        where("subCategoryName", "==", categoryDetails.subCategoryName)
      ));

      if (!querySnapshot.empty) {
        const configId = querySnapshot.docs[0].data().configurationId;
        const formConfigRef = doc(projectFirestore, 'formsConfigurations', configId);
        const formConfigSnap = await getDoc(formConfigRef);

        if (formConfigSnap.exists()) {
          setFormConfig(formConfigSnap.data());
        } else {
          console.error("Form configuration not found");
        }
      } else {
        console.error("Mapping not found for the provided category and sub-category names");
      }
    };

    if (categoryDetails.categoryName && categoryDetails.subCategoryName) {
      fetchFormConfig();
    }
  }, [categoryDetails]);



const handleImageUpload = async (event) => {
  const files = Array.from(event.target.files); // Convert FileList to Array
  setUploading(true);

  // Calculate the number of images already uploaded if editing an existing announcement
  const existingImagesCount = formData.images ? formData.images.length : 0;

  // Calculate remaining free uploads and chargeable uploads
  const freeUploadsRemaining = Math.max(2 - existingImagesCount, 0);
  const freeImages = files.slice(0, freeUploadsRemaining);
  const chargeableImages = files.slice(freeUploadsRemaining);
  const costPerExtraImage = 5; // Cost per extra image

  // Helper function to upload an image and return its download URL
  const uploadImageAndGetUrl = async (file) => {
    // Compress and resize the image before uploading
    const options = {
      maxSizeMB: 0.2, // Increase to 200KB for better quality
      maxWidthOrHeight: 1920, // Suitable for high-quality images
      fileType: ['JPEG', 'JPG', 'PNG', 'WEBP'], // Specify the output file types
      mimeType: ['image/jpeg', 'image/jpeg', 'image/png', 'image/webp'], // Corresponding MIME types
    };

    // Compress and resize the image
    const compressedImage = await imageCompression(file, options);

    // Upload the compressed image to Firebase Storage
    const storageRef = ref(projectStorage, `announcementImages/${Date.now()}-${compressedImage.name}`);
    await uploadBytes(storageRef, compressedImage);

    // Return the download URL of the uploaded image
    return getDownloadURL(storageRef);
  };

  // Upload free images and get URLs
  const freeImageUploads = await Promise.all(freeImages.map(uploadImageAndGetUrl));

  // Upload chargeable images and get URLs, if any
  const chargeableImageUploads = await Promise.all(chargeableImages.map(uploadImageAndGetUrl));

  // Update the extra image cost based on chargeable images
  setExtraImageCost(chargeableImages.length * costPerExtraImage);

  // Combine all new image URLs with existing ones and update formData
  const allNewImageUrls = [...freeImageUploads, ...chargeableImageUploads];
  setFormData(prevData => ({
    ...prevData,
    images: [...prevData.images, ...allNewImageUrls] // Ensure existing images are preserved
  }));
  setUploading(false);
};



  const handleSubmit = async (e) => {
    e.preventDefault();
    const newDocRef = doc(collection(projectFirestore, 'announcements')); // Create a reference first
    const enrichedFormData = {
      ...formData,
      group: categoryDetails.option,
      userId: currentUser?.uid,
      advertOrderId: newDocRef.id, // Use the generated ID
      category: categoryDetails.categoryName,
      categoryId: categoryDetails.categoryId,
      subCategory: categoryDetails.subCategoryName,
      subCategoryId: categoryDetails.subCategoryId,
      startDate: formData.startDate, // Assuming startDate is managed in formData
      endDate: formData.endDate, // Assuming endDate is managed in formData
      createdAt: new Date(),
    };
    await setDoc(newDocRef, enrichedFormData); // Set the document with the enriched data
    navigate('/adverts-order-summary', { state: { advertData: enrichedFormData } });

    // try {
    //   const docRef = await addDoc(collection(projectFirestore, 'announcements'), enrichedFormData);
    //   // navigate(`/announcements/details/${docRef.id}`);
    //   // Navigate to order summary with enriched form data
    //   navigate('/adverts-order-summary', { state: { advertData: enrichedFormData } });
    // } catch (error) {
    //   console.error("Error submitting announcement:", error);
    // }
  };

  if (!formConfig) {
    return <div>Loading form configuration...</div>;
  }

  return (
    <Container>
    <Form onSubmit={handleSubmit}>
      {formConfig.fields.map((field, index) => (
        <Form.Group key={index} className="mb-3">
          <Form.Label>{field.label}</Form.Label>
          <Form.Control
            type={field.type || 'text'}
            value={formData[field.name] || ''}
            onChange={e => setFormData({ ...formData, [field.name]: e.target.value })}
            as={field.type === 'textarea' ? 'textarea' : 'input'}
          />
        </Form.Group>
      ))}
      <Form.Group className="mb-3">
          <Form.Label>Advert Type</Form.Label>
          <Form.Select
            value={formData.advertType || ''}
            onChange={e => setFormData({ ...formData, advertType: e.target.value })}
          >
            <option value="">Select Type</option>
            <option value="Personal">Personal</option>
            <option value="Business">Business</option>
            <option value="Commercial">Commercial</option>
            <option value="Governmental">Governmental</option>
            <option value="Religious">Religious</option>
            <option value="Public">Public</option>
            <option value="Educational">Educational</option>
            <option value="Academic">Academic</option>
          </Form.Select>
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>Upload Images (2 free, more at cost)</Form.Label>
          <Form.Control type="file" multiple onChange={handleImageUpload} />
          {uploading && <div>Uploading...</div>}
          {extraImageCost > 0 && <Alert variant="info">Extra Image Cost: ${extraImageCost}</Alert>}
        </Form.Group>
      <Button variant="primary" type="submit">Submit</Button>
    </Form>
  </Container>
  );
};

export default AnnouncementsForm;
