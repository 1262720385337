import React, { useState, useEffect } from 'react';
import { projectFirestore } from '../../../firebase/config';
import { collection, getDocs } from 'firebase/firestore';
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS

const AllServices = () => {
    const [services, setServices] = useState([]);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchServices = async () => {
            try {
                const servicesCollection = collection(projectFirestore, 'cleaningServices');
                const servicesSnapshot = await getDocs(servicesCollection);
                setServices(servicesSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })));
            } catch (err) {
                console.error("Error fetching services:", err);
                setError("Failed to fetch services. Please try again later.");
            }
        };

        fetchServices();
    }, []);

    return (
        <div className="container mt-5">
            <h2>All Services</h2>
            {error && <div className="alert alert-danger">{error}</div>}
            {services.length === 0 ? (
                <p>No services available.</p>
            ) : (
                <ul className="list-group">
                    {services.map(service => (
                        <li key={service.id} className="list-group-item d-flex justify-content-between align-items-center">
                            {service.name}
                            <span className="badge bg-primary rounded-pill">GHS {service.price}</span>
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
};

export default AllServices;
