// import React, { useEffect, useState } from 'react';
// import { collection, getDocs } from 'firebase/firestore';
// import { projectFirestore } from '../../firebase/config';
// import { Accordion, Card, Button } from 'react-bootstrap';
// import DOMPurify from 'dompurify';

// // const Policies = () => {
// //     const [policies, setPolicies] = useState([]);

// //     useEffect(() => {
// //         const fetchPolicies = async () => {
// //             const querySnapshot = await getDocs(collection(projectFirestore, 'policies'));
// //             const fetchedPolicies = querySnapshot.docs.map(doc => ({
// //                 id: doc.id,
// //                 ...doc.data()
// //             }));
// //             setPolicies(fetchedPolicies);
// //         };

// //         fetchPolicies();
// //     }, []);

// //     return (
// //         <div>
// //             <h2>Our Policies</h2>
// //             <Accordion defaultActiveKey="0">
// //                 {policies.map((policy, index) => (
// //                     <Card key={policy.id}>
// //                         <Card.Header>
// //                             <Accordion.Toggle as={Button} variant="link" eventKey={`${index}`}>
// //                                 {policy.name}
// //                             </Accordion.Toggle>
// //                         </Card.Header>
// //                         <Accordion.Collapse eventKey={`${index}`}>
// //                             <Card.Body>
// //                                 {/* Sanitize policy.description here, within the map function */}
// //                                 <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(policy.description) }} />
// //                             </Card.Body>
// //                         </Accordion.Collapse>
// //                     </Card>
// //                 ))}
// //             </Accordion>
// //         </div>
// //     );
// // };

// // const Policies = () => {
// //     return <div>Testing Policies</div>;
// //   };

// const Policies = () => {
//     return (
//       <Accordion defaultActiveKey="0">
//         <Card>
//           <Card.Header>
//             <Accordion.Toggle as={Button} variant="link" eventKey="0">
//               Click me!
//             </Accordion.Toggle>
//           </Card.Header>
//           <Accordion.Collapse eventKey="0">
//             <Card.Body>Hello! I'm the body</Card.Body>
//           </Accordion.Collapse>
//         </Card>
//       </Accordion>
//     );
//   };
  
  

// export default Policies;


import React, { useState, useEffect } from 'react';
import { collection, getDocs } from 'firebase/firestore';
import { projectFirestore } from '../../firebase/config';
import DOMPurify from 'dompurify';

const SimpleAccordion = ({ title, content }) => {
    const [isOpen, setIsOpen] = useState(false);

    return (
        <div className="card">
            <div className="card-header">
                <button className="btn btn-link" onClick={() => setIsOpen(!isOpen)}>
                    {title}
                </button>
            </div>
            {isOpen && (
                <div className="card-body" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(content) }} />
            )}
        </div>
    );
};

const Policies = () => {
    const [policies, setPolicies] = useState([]);

    useEffect(() => {
        const fetchPolicies = async () => {
            const querySnapshot = await getDocs(collection(projectFirestore, 'policies'));
            const fetchedPolicies = querySnapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data(),
            }));
            setPolicies(fetchedPolicies);
        };

        fetchPolicies();
    }, []);

    return (
        <div className="container mt-3">
            <h2>Our Policies</h2>
            {policies.map((policy) => (
                <SimpleAccordion key={policy.id} title={policy.name} content={policy.description} />
            ))}
        </div>
    );
};

export default Policies;
