import React, { useState, useEffect } from 'react';
import { projectFirestore, projectStorage } from '../../firebase/config';
import { ref, uploadBytes, getDownloadURL, deleteObject } from 'firebase/storage';
import { collection, addDoc, getDocs, updateDoc, doc, deleteDoc } from 'firebase/firestore';
import { Container, Button, Form, Row, Col, ListGroup, Modal } from 'react-bootstrap';
import { v4 as uuidv4 } from 'uuid';

const ProvidedServices = () => {
  const [services, setServices] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [currentService, setCurrentService] = useState(null); // For edit
  const [imageFile, setImageFile] = useState(null); // For image upload
  const [currentServiceId, setCurrentServiceId] = useState(null); // Used to identify if editing
  
  // Updated newService state to include icon fields
  const [newService, setNewService] = useState({
    name: '',
    description: '',
    imageUrl: '',
    icon1: '', // New icon fields
    icon2: '',
    icon3: '',
    redirectionPath: '',
  });

  useEffect(() => {
    fetchServices();
  }, []);

  const fetchServices = async () => {
    setLoading(true);
    try {
      const querySnapshot = await getDocs(collection(projectFirestore, 'providedservices'));
      const servicesData = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setServices(servicesData);
    } catch (error) {
      console.error('Error fetching services:', error);
    }
    setLoading(false);
  };

  const handleFileChange = (e) => {
    setImageFile(e.target.files[0]);
  };

  const handleServiceChange = (e) => {
    setNewService({ ...newService, [e.target.name]: e.target.value });
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("Form submission started");
    setLoading(true); // Correct use of setLoading for upload state
  
    let imageUrl = newService.imageUrl;
    if (imageFile) {
      try {
        const uniqueImageName = `${imageFile.name}-${uuidv4()}`; // Ensure a unique filename
        const imageRef = ref(projectStorage, `providedservicesimages/${uniqueImageName}`);
        const snapshot = await uploadBytes(imageRef, imageFile);
        imageUrl = await getDownloadURL(snapshot.ref);
        console.log("Image uploaded: ", imageUrl);
      } catch (error) {
        console.error('Error uploading image:', error);
        setLoading(false);
        return;
      }
    }
  
    const serviceData = {
      ...newService,
      imageUrl,
    };
  
    try {
      if (currentServiceId) {
        // Update existing service
        console.log("Updating service: ", currentServiceId);
        await updateDoc(doc(projectFirestore, 'providedservices', currentServiceId), serviceData);
      } else {
        // Add new service
        console.log("Adding new service");
        await addDoc(collection(projectFirestore, 'providedservices'), serviceData);
      }
      console.log("Service saved successfully");
      setShowModal(false);
      fetchServices(); // Re-fetch services to update the list
    } catch (error) {
      console.error('Error saving service:', error);
    }
    setLoading(false);
    setNewService({
        name: '',
        description: '',
        imageUrl: '',
        icon1: '', // Reset icon1 field
        icon2: '', // Reset icon2 field
        icon3: '', // Reset icon3 field
        redirectionPath: '', // Reset redirectionPath field
      });
      
    setImageFile(null); // Reset file input
    setCurrentServiceId(null); // Reset current editing service ID
  };
  
  

  // Open modal for editing
//   const handleEdit = (service) => {
//     setCurrentService(service);
//     setNewService({ name: service.name, description: service.description, imageUrl: service.imageUrl });
//     setShowModal(true);
//   };
const handleEdit = (service) => {
    setCurrentService(service);
    setNewService({
      name: service.name,
      description: service.description,
      imageUrl: service.imageUrl,
      icon1: service.icon1 || '', // Ensure to fallback to an empty string if undefined
      icon2: service.icon2 || '',
      icon3: service.icon3 || '',
      redirectionPath: service.redirectionPath || '',
    });
    setShowModal(true);
  };
  

  // Delete a service
  const handleDelete = async (serviceId, imageUrl) => {
    const confirmDelete = window.confirm('Are you sure you want to delete this service?');
    if (confirmDelete) {
      setLoading(true);
      try {
        const serviceRef = doc(projectFirestore, 'providedservices', serviceId);
        await deleteDoc(serviceRef);
        // Delete the image from storage
        const imageRef = ref(projectStorage, imageUrl);
        await deleteObject(imageRef);
      } catch (error) {
        console.error('Error deleting the service:', error);
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <Container>
      <Button onClick={() => setShowModal(true)}>Add New Service</Button>
      {loading ? (
        <div>Loading...</div>
      ) : (
        <ListGroup>
          {services.map((service) => (
            <ListGroup.Item key={service.id}>
              {service.name} - {service.description}
              <Button variant="info"
              onClick={() => handleEdit(service)} className="ms-2">
                Edit
              </Button>
              <Button variant="danger" onClick={() => handleDelete(service.id, service.imageUrl)} className="ms-2">
                Delete
              </Button>
            </ListGroup.Item>
          ))}
        </ListGroup>
      )}

      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>{currentService ? 'Edit Service' : 'Add New Service'}</Modal.Title>
        </Modal.Header>
        <Form onSubmit={handleSubmit}>
          <Modal.Body>
            <Form.Group className="mb-3" controlId="serviceName">
              <Form.Label>Service Name</Form.Label>
              <Form.Control type="text" placeholder="Enter service name" value={newService.name} onChange={(e) => setNewService({ ...newService, name: e.target.value })} required />
            </Form.Group>
            <Form.Group className="mb-3" controlId="serviceDescription">
              <Form.Label>Description</Form.Label>
              <Form.Control as="textarea" rows={3} placeholder="Service description" value={newService.description} onChange={(e) => setNewService({ ...newService, description: e.target.value })} required />
            </Form.Group>
            <Form.Group className="mb-3" controlId="serviceImage">
              <Form.Label>Image</Form.Label>
              <Form.Control type="file" onChange={(e) => setImageFile(e.target.files[0])} />
            </Form.Group>
            {newService.imageUrl && !imageFile && (
              <img src={newService.imageUrl} alt="Service" style={{ maxWidth: '100%', height: 'auto' }} />
            )}
            <Form.Group className="mb-3" controlId="icon1">
              <Form.Label>Icon 1</Form.Label>
              <Form.Control type="text" placeholder="Enter icon name (optional)" value={newService.icon1} onChange={(e) => setNewService({ ...newService, icon1: e.target.value })} />
            </Form.Group>
            <Form.Group className="mb-3" controlId="icon2">
              <Form.Label>Icon 2</Form.Label>
              <Form.Control type="text" placeholder="Enter icon name (optional)" value={newService.icon2} onChange={(e) => setNewService({ ...newService, icon2: e.target.value })} />
            </Form.Group>
            <Form.Group className="mb-3" controlId="icon3">
              <Form.Label>Icon 3</Form.Label>
              <Form.Control type="text" placeholder="Enter icon name (optional)" value={newService.icon3} onChange={(e) => setNewService({ ...newService, icon3: e.target.value })} />
            </Form.Group>
            <Form.Group className="mb-3" controlId="redirectionPath">
              <Form.Label>Redirection Path</Form.Label>
              <Form.Control type="text" placeholder="Enter service path" value={newService.redirectionPath} onChange={(e) => setNewService({ ...newService, redirectionPath: e.target.value })} />
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShowModal(false)}>
              Close
            </Button>
            <Button variant="primary" type="submit">
              Save Changes
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </Container>
  );
};

export default ProvidedServices;
