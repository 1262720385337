import React from 'react';
import { Container, Row, Col, Button, Card, Breadcrumb, Image, ListGroup } from 'react-bootstrap';
import { FaMapMarkerAlt, FaPhone, FaEnvelope, FaCalendarAlt } from 'react-icons/fa';

// Placeholder component for the map
const MapPlaceholder = () => (
  <div style={{ backgroundColor: '#ddd', height: '200px', textAlign: 'center', paddingTop: '90px' }}>
    Map Placeholder
  </div>
);

const AnnouncementDetail = () => {
  // Placeholder announcement data
  const announcement = {
    title: "Community Clean-Up Day",
    summary: "Join us for a day dedicated to cleaning up our neighborhood park!",
    description: "We're looking for volunteers to help with the community clean-up day. This event is family-friendly and a great way to give back to the community. All materials will be provided.",
    date: "April 22, 2024",
    location: "Sunshine Park, 123 Park Ave, Springfield",
    contactPhone: "(555) 123-4567",
    contactEmail: "volunteer@cleanupday.com",
    image: "https://via.placeholder.com/800x400",
    mapLocation: "Sunshine Park",
    cta: "Volunteer Now"
  };

  return (
    <Container>
      <Breadcrumb>
        <Breadcrumb.Item href="#">Home</Breadcrumb.Item>
        <Breadcrumb.Item active>Announcement</Breadcrumb.Item>
      </Breadcrumb>

      <Card className="bg-light text-center">
        <Card.Body>
          <h1>{announcement.title}</h1>
          <p>{announcement.summary}</p>
        </Card.Body>
      </Card>

      <Row className="mt-3">
        <Col md={8}>
          <Image src={announcement.image} alt="Announcement" thumbnail />
          <Card className="mt-3">
            <Card.Body>
              <Card.Title>Details</Card.Title>
              <Card.Text>{announcement.description}</Card.Text>
              <ListGroup className="list-group-flush">
                <ListGroup.Item><FaCalendarAlt /> {announcement.date}</ListGroup.Item>
                <ListGroup.Item><FaMapMarkerAlt /> {announcement.location}</ListGroup.Item>
                <ListGroup.Item><FaPhone /> {announcement.contactInfo}</ListGroup.Item>
                <ListGroup.Item><FaEnvelope /> {announcement.contactInfo}</ListGroup.Item>
              </ListGroup>
              <Button variant="primary" className="mt-3">{announcement.cta}</Button>
            </Card.Body>
          </Card>
        </Col>

        <Col md={4}>
          <MapPlaceholder />
        </Col>
      </Row>

      {/* Similar announcements would go here */}
      <footer className="mt-4">
        <p>For more information, please contact us at [info]</p>
      </footer>
    </Container>
  );
};

export default AnnouncementDetail;

