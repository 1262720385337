import React, { useState } from "react";
import { Container, Row, Col, Dropdown } from "react-bootstrap";
import { FaBars } from "react-icons/fa"; // Import icon for sidebar toggle
import { IoIosArrowDown } from "react-icons/io"; // Import icon for dropdown toggle

import Sidebar from "./Sidebar";
import CategoryManager from "../Adverts/Category/CategoryManager";
import FormsConfigurationsForm from "../Adverts/AdvertsForms/FormsConfigurationsForm";
import TransportDropdown from "../AllGDeliveries/TransportTypes/TransportDropdown";
import AllServices from "../Admin/AddService/AllServices";
import AddService from "../Admin/AddService/AddService";
import PartnerRegistration from "../Admin/PartnerRegistration/PartnerRegistration";
import PartnerProfile from "../Admin/AllServices/PartnerProfile";
import AdminDashboard from "../AdminDashboard/AdminDashboard";
import AdminPanel from "../Admin/AdminPanel/AdminPanel";
import AdminInterface from "../../components/Admin/AdminInterface/AdminInterface";
import ProvidedServices from "../services/ProvidedServices";
import PolicyEditor from "../Policies/PolicyEditor";
import PolicyList from "../Policies/PolicyList";
import AdvertTypePricesManager from "../Admin/Adverts/AdvertTypePricesManager";
import CategoryConfigurationMappingForm from "../Adverts/AdvertsForms/CategoryConfigurationMappingForm";
import FormsConfigurationsList from "../Adverts/AdvertsForms/FormsConfigurationsList";
import CategoryConfigurationList from "../Adverts/AdvertsForms/CategoryConfigurationList";
import AdminDeliveryCategories from "../Admin/AllGDeliveries/AdminDeliveryCategories";
import DeliveryPartnerAdminManager from "../Admin/DeliveryPartnerAdminManager";
import DeliveryPartnerAdminManagerList from "../Admin/DeliveryPartnerAdminManagerList";
import DeliveryPartnerOnboarding from "../Admin/AllGDeliveries/DeliveryPartnerOnboarding";
import TransportManager from "../AllGDeliveries/TransportTypes/TransportManager";
import PostAdvertCategorySelector from "../Adverts/AdvertsForms/PostAdvertCategorySelector";
import DeliveryHome from "../AllGDeliveries/DeliveryHome";
import OrderIntake from "../QuoteTool/OrderIntake";
import CategoryActivationList from "../Admin/AllGDeliveries/CategoryActivationList";
import WinnerOverviews from "./WinnerOverviews";
import PlayDashboard from "../PlayWinners/PlayDashboard/PlayDashboard";

const DashboardLayout = () => {
  const [selectedComponent, setSelectedComponent] = useState("");
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);

  // Function to handle component selection
  const handleComponentSelect = (component) => {
    setSelectedComponent(component);
  };

  // Function to toggle sidebar visibility
  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  // Render components based on the selected service
  const renderSelectedComponent = () => {
    switch (selectedComponent) {
      case "Advert Category Manager":
        return <CategoryManager />;
      case "Advert Forms Configurations Form":
        return <FormsConfigurationsForm />;
      case "Advert Forms Configurations List":
        return <FormsConfigurationsList />;
      case "Advert Category Configuration Mapping Form":
        return <CategoryConfigurationMappingForm />;
      case "Advert Category Configuration Mapping List":
        return <CategoryConfigurationList />;
      case "Delivery Transport Category Manager":
        return <TransportManager />;
      case "All Cleaning Services":
        return <AllServices />;
      case "Add Cleaning Service":
        return <AddService />;
      case "Cleaning Admin Dashboard":
        return <AdminDashboard />;
      case "Cleaning Partner Registration":
        return <PartnerRegistration />;
      case "Cleaning Partner Profile":
        return <PartnerProfile />;
      case "Admin Panel":
        return <AdminPanel />;
      case "Admin Interface":
        return <AdminInterface />;
      case "AllGhanaians Provided Services":
        return <ProvidedServices />;
      case "AllGhanaians Policy Editor":
        return <PolicyEditor />;
      case "AllGhanaians Policy List":
        return <PolicyList />;
      case "Advert Type Prices Manager":
        return <AdvertTypePricesManager />;
      case "Post Adverts":
        return <PostAdvertCategorySelector />;
      case "Admin Panel":
        return <AdminPanel />;
      case "Delivery Category Manager":
        return <AdminDeliveryCategories />;
      case "Delivery Partner Admin Manager":
        return <DeliveryPartnerAdminManager />;
      case "Delivery Partner Admin Manager List":
        return <DeliveryPartnerAdminManagerList />;
      case "Delivery Partner Onboarding":
        return <DeliveryPartnerOnboarding />;
      case "Delivery Home Page":
        return <DeliveryHome />;
      case "Category Activation List":
        return <CategoryActivationList />;
      case "Book Now":
        return <OrderIntake />;
      case "Your Wins & Referrals":
        return <WinnerOverviews />;
      case "Referral Links":
        return <PlayDashboard />;
      // Add other components here
      default:
        return <div></div>;
      // return <div>Please select a component</div>;
    }
  };

  return (
    <Container fluid>
      <Row>
        {/* Sidebar */}
        <Col md={3} className="sidebar">
          {/* Pass the onComponentSelect prop to the Sidebar */}
          <Sidebar onComponentSelect={handleComponentSelect} />
        </Col>
        {/* Main content area */}
        <Col md={9} className="main-content">
          {/* Render selected component */}
          {renderSelectedComponent()}
        </Col>
      </Row>
    </Container>
  );
};

export default DashboardLayout;
