import React from "react";
import useSendGridEmail from "../Hooks/useSendGridEmail";

const EmailComponent = () => {
  const { sendEmail, loading, error } = useSendGridEmail();

  const handleSendEmail = async () => {
    const emailData = {
      to: "ken.edem@gmail.com",
      from: "info@boameooo.com",
      subject: "Test Email from Boameooo App",
      text: "This is a test email sent from React.js using SendGrid.",
    };

    console.log("Attempting to send email...", emailData); // Log email sending attempt
    try {
      await sendEmail(emailData);
    } catch (error) {
      console.error("Error sending email:", error); // Log error if sending fails
    }
  };

  return (
    <div>
      <button onClick={handleSendEmail} disabled={loading}>
        {loading ? "Sending..." : "Send Email"}
      </button>
      {error && <div>Error: {error.message}</div>}
    </div>
  );
};

export default EmailComponent;
