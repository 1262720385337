import React, { useState } from "react";
import Container from "react-bootstrap/Container";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEnvelope,
  faPhone,
  faMapMarkerAlt,
} from "@fortawesome/free-solid-svg-icons";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import axios from "axios";

const Contact = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    contact: "",
    message: "",
  });

  const [validated, setValidated] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
    } else {
      e.preventDefault();
      console.log("Form is valid. Sending email with formData:", formData);
      try {
        const response = await axios.post(
          "/api/sendTestEmail",
          {
            ...formData,
            subject: `${formData.name}`,
            email: "info@boameooo.com", // Override email to send to default recipient
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        console.log("Email sent successfully:", response.data);
        setFormData({
          name: "",
          email: "",
          contact: "",
          message: "",
        });
        setValidated(true);
        setShowSuccessMessage(true);
        setTimeout(() => {
          window.location.href = "/"; // Redirect to home page
        }, 3000); // Display the success message for 3 seconds before redirecting
      } catch (error) {
        console.error(
          "Error in handleSubmit while sending email:",
          error.message
        );
      }
    }
  };

  return (
    <section id="contact" className="block contact-block bg-light py-5">
      <Container>
        <div className="title-holder text-center mb-5">
          <h2>Contact Us</h2>
          <p className="subtitle">Get connected with us</p>
        </div>
        {showSuccessMessage ? (
          <div className="alert alert-success text-center">
            Your Message is Successfully Sent
          </div>
        ) : (
          <Form
            className="contact-form"
            noValidate
            validated={validated}
            onSubmit={handleSubmit}
          >
            <Row className="mb-3">
              <Col sm={12} md={4}>
                <Form.Control
                  type="text"
                  placeholder="Enter your full name"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Please provide your full name.
                </Form.Control.Feedback>
              </Col>
              <Col sm={12} md={4}>
                <Form.Control
                  type="email"
                  placeholder="Enter your email address"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Please provide a valid email.
                </Form.Control.Feedback>
              </Col>
              <Col sm={12} md={4}>
                <Form.Control
                  type="tel"
                  placeholder="Enter your contact number"
                  name="contact"
                  value={formData.contact}
                  onChange={handleChange}
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Please provide a valid contact number.
                </Form.Control.Feedback>
              </Col>
            </Row>
            <Row className="mb-4">
              <Col sm={12}>
                <Form.Control
                  as="textarea"
                  rows={5}
                  placeholder="Enter your message"
                  name="message"
                  value={formData.message}
                  onChange={handleChange}
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Please provide a message.
                </Form.Control.Feedback>
              </Col>
            </Row>
            <div className="text-center">
              <Button variant="primary" type="submit">
                Submit
              </Button>
            </div>
          </Form>
        )}
      </Container>
      <div
        className="google-map"
        style={{ width: "100%", height: "400px", marginTop: "20px" }}
      >
        <iframe
          title="Our Location"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3969.9886950406094!2d-0.07742612434466949!3d5.7147647942671975!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xfdf8177cfe3cde5%3A0xa79a3b219c28747!2sMontgomery%20Golf%20Resort%20%26%20Private%20Residences!5e0!3m2!1sen!2snl!4v1711781717585!5m2!1sen!2snl"
          width="100%"
          height="100%"
          style={{ border: 0 }}
          allowFullScreen=""
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
        ></iframe>
      </div>
      <Container fluid className="mt-5">
        <div className="contact-info text-center">
          <ul className="list-unstyled">
            <li>
              <FontAwesomeIcon icon={faEnvelope} /> info@allghanaians.com
            </li>
            <li>
              <FontAwesomeIcon icon={faPhone} /> +233 555592621
            </li>
            <li>
              <FontAwesomeIcon icon={faMapMarkerAlt} /> Montgomery Golf Estates,
              11 Montgomery Drive, GK-0465-2907 Santoe, East Legon Hills, Accra,
              Ghana
            </li>
          </ul>
        </div>
      </Container>
    </section>
  );
};

export default Contact;
