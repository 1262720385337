import React from 'react';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';

const BusinessAnnouncementDetail = ({ announcement }) => {
  // Placeholder data for the component
  const placeholderData = {
    title: "Grand Opening: JavaBeans Cafe",
    date: "2024-05-01",
    time: "09:00 AM",
    location: "123 Brew Street, Coffeetown",
    description: "We are excited to announce the grand opening of JavaBeans Cafe! Join us for a taste of our specialty coffees and pastries.",
    contact: "Contact us at info@javabeanscafe.com for more information.",
    images: [
      "https://via.placeholder.com/800x400/007bff/ffffff/?text=JavaBeans+Cafe+1",
      "https://via.placeholder.com/800x400/007bff/ffffff/?text=JavaBeans+Cafe+2",
    ],
    additionalInfo: "The first 50 visitors will receive a free coffee voucher. We look forward to welcoming you to our new location!",
  };

  // Replace placeholderData with your actual announcement data when available
  const data = announcement || placeholderData;

  return (
    <Container className="mt-5">
      <Row>
        <Col md={12}>
          <Card>
            <Card.Body>
              <Card.Title>{data.title}</Card.Title>
              <Card.Text>Date: {data.date} at {data.time}</Card.Text>
              <Card.Text>Location: {data.location}</Card.Text>
              <Card.Text>{data.description}</Card.Text>
              <Card.Text>Contact: {data.contactInfo}</Card.Text>
              <Card.Text>{data.additionalInfo}</Card.Text>
              {data.images.map((img, index) => (
                <div key={index} className="mb-3">
                  <Card.Img variant="top" src={img} alt={`Business Announcement Image ${index + 1}`} />
                </div>
              ))}
              <Button variant="success">Learn More</Button>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default BusinessAnnouncementDetail;
