import React, { createContext, useState, useContext } from 'react';

const OrderContext = createContext();

export const useOrder = () => useContext(OrderContext);

export const OrderProvider = ({ children }) => {
    const [orderDetails, setOrderDetails] = useState({});
    
    const updateOrderDetails = (newDetails) => {
        setOrderDetails(prevDetails => ({ ...prevDetails, ...newDetails }));
    };

    return (
        <OrderContext.Provider value={{ orderDetails, updateOrderDetails }}>
            {children}
        </OrderContext.Provider>
    );
};
