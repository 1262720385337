import React, { useState, useEffect } from 'react';
import { GoogleMap, LoadScript, Marker, Autocomplete, Polyline } from '@react-google-maps/api';
import { useNavigate } from 'react-router-dom';
import { Form } from 'react-bootstrap';

const libraries = ['places'];

function GoogleGeolocations({ onLocationSelect, mapCenter, routePath }) {
  const [location, setLocation] = useState({ lat: 6.700071, lng: -1.630783 });
  const [marker, setMarker] = useState(null);
  const [autocomplete, setAutocomplete] = useState(null);
  const navigate = useNavigate();

  const onLoad = (autoC) => setAutocomplete(autoC);

  const onPlaceChanged = () => {
    if (autocomplete !== null) {
      const place = autocomplete.getPlace();
      const lat = place.geometry.location.lat();
      const lng = place.geometry.location.lng();
      setLocation({ lat, lng });
      setMarker({ lat, lng });
      onLocationSelect(lat, lng);
    }
  };

  const onMapClick = (event) => {
    const lat = event.latLng.lat();
    const lng = event.latLng.lng();
    setLocation({ lat, lng });
    setMarker({ lat, lng });
    onLocationSelect(lat, lng);
  };

  // Update map center when mapCenter prop changes
  useEffect(() => {
    if (mapCenter) {
      setLocation(mapCenter);
      setMarker(mapCenter);
    }
  }, [mapCenter]);

  return (
    <LoadScript googleMapsApiKey="YOUR_GOOGLE_MAPS_API_KEY" libraries={libraries}>
      <div>
        <Form.Control
          type="text"
          placeholder="Search location"
          style={{ width: '100%', height: '38px', marginBottom: '10px' }}
          ref={(input) => onLoad(input)}
          onChange={onPlaceChanged}
        />
      </div>

      <GoogleMap
        center={location}
        zoom={10}
        onClick={onMapClick}
      >
        {marker && <Marker position={marker} />}
        {/* Render the route if routePath is provided */}
        {routePath && (
          <Polyline path={routePath} options={{ strokeColor: '#FF0000' }} />
        )}
      </GoogleMap>

      {location && (
        <div>
          <p>Latitude: {location.lat}</p>
          <p>Longitude: {location.lng}</p>
        </div>
      )}
    </LoadScript>
  );
}

export default GoogleGeolocations;
