import React, { createContext, useContext, useState, useEffect } from "react";
import { projectAuth, projectFirestore } from "../../firebase/config";
import NotificationModal from "../MessageNotifications/NotificationModal";
import {
  onAuthStateChanged,
  setPersistence,
  browserLocalPersistence,
} from "firebase/auth";
import {
  doc,
  getDoc,
  updateDoc,
  collection,
  query,
  where,
  getDocs,
} from "firebase/firestore";

export const UserContext = createContext({
  currentUser: null,
  setCurrentUser: () => {},
  loading: true,
  isAdmin: false,
  role: "",
  setRole: () => {},
  updateUserRole: async () => {},
});

export const useUser = () => useContext(UserContext);

export const UserProvider = ({ children, navigate }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isAdmin, setIsAdmin] = useState(false);
  const [role, setRole] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [modalContent, setModalContent] = useState(""); 
  const handleClaimNow = () => {
    navigate("/dashboard"); // Make sure this is the desired path
  };

  useEffect(() => {
    const initAuth = async () => {
      await setPersistence(projectAuth, browserLocalPersistence);
      const unsubscribe = onAuthStateChanged(
        projectAuth,
        async (firebaseUser) => {
          if (firebaseUser) {
            const userRef = doc(projectFirestore, "users", firebaseUser.uid);
            const userSnap = await getDoc(userRef);
            if (userSnap.exists()) {
              const userData = { ...firebaseUser, ...userSnap.data() };
              setCurrentUser(userData);
              setRole(userData.role || "end-user");
              setIsAdmin(userData.role === "admin");
              setLoading(false);
            } else {
              setCurrentUser(firebaseUser);
              setLoading(false);
            }
          } else {
            setCurrentUser(null);
            setIsAdmin(false);
            setLoading(false);
          }
        }
      );

      return () => {
        unsubscribe(); // Correct cleanup function
      };
    };

    initAuth();
  }, []);

  useEffect(() => {
    if (currentUser && currentUser.uid) {
      checkUnclaimedWinnings(currentUser.uid)
        .then((hasUnclaimed) => {
          if (hasUnclaimed) {
            const content =
              "You have unclaimed gifts! Please click to claim your gifts.";
            setModalContent(content);
            // console.log("Modal content set:", content); // Debugging output
            setShowModal(true);
          }
        })
        .catch((error) => {
          console.error("Error during check for unclaimed winnings:", error);
        });
    }
  }, [currentUser]);


  const checkUnclaimedWinnings = async (userId) => {
    const collections = ["dailyWinners", "weeklyWinners", "monthlyWinners"];
    try {
      const promises = collections.map((coll) => {
        const q = query(
          collection(projectFirestore, coll),
          where("userId", "==", userId),
          where("claimed", "==", false)
        );
        return getDocs(q);
      });

      const results = await Promise.all(promises);
      return results.some((result) => !result.empty); // returns true if any winnings are unclaimed
    } catch (error) {
      console.error("Error fetching unclaimed winnings:", error);
      throw error; // Re-throw the error to be caught by the caller
    }
  };

  const updateUserRole = async (userId, newRole) => {
    const userRef = doc(projectFirestore, "users", userId);
    await updateDoc(userRef, { role: newRole });
    console.log(`Role updated to ${newRole} for user ${userId}`);
  };

  return (
    <UserContext.Provider
      value={{
        currentUser,
        setCurrentUser,
        loading,
        isAdmin,
        role,
        setRole,
        updateUserRole,
      }}
    >
      {children}

      
      <NotificationModal
        show={showModal}
        onHide={() => setShowModal(false)}
        title={`Congratulations, ${currentUser?.displayName}!`}
        body={<p>{modalContent}</p>}
        action={{
          label: "Claim Now",
          onClick: handleClaimNow,
        }}
      />
    </UserContext.Provider>
  );
};
