import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Button, Container } from 'react-bootstrap';
import { collection, getDocs, deleteDoc, doc } from 'firebase/firestore';
import { projectFirestore } from '../../../firebase/config';

const FormsConfigurationsList = () => {
    const [formsConfigurations, setFormsConfigurations] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchFormsConfigurations = async () => {
            const querySnapshot = await getDocs(collection(projectFirestore, 'formsConfigurations'));
            const configs = querySnapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data()
            }));
            setFormsConfigurations(configs);
        };
        fetchFormsConfigurations();
    }, []);

    const handleDelete = async (id) => {
        if (window.confirm('Are you sure you want to delete this form configuration?')) {
            await deleteDoc(doc(projectFirestore, 'formsConfigurations', id));
            setFormsConfigurations(formsConfigurations.filter(config => config.id !== id)); // Update the state to remove the deleted configuration
        }
    };

    return (
        <Container>
            <h2>Form Configurations</h2>
            {formsConfigurations.length > 0 ? (
                <table className="table">
                    <thead>
                        <tr>
                            <th>Document ID</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {formsConfigurations.map(config => (
                            <tr key={config.id}>
                                <td>{config.id}</td>
                                <td>
                                    <Button variant="primary" onClick={() => navigate(`/forms-configurations-form/${config.id}`)}>Edit</Button>
                                    {/* <Button variant="danger" onClick={() => handleDelete(config.id)}>Delete</Button> */}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            ) : <p>No form configurations found.</p>}
        </Container>
    );
};

export default FormsConfigurationsList;
