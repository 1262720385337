// iconMap.js
import { MdCleaningServices } from 'react-icons/md';
import { AiOutlineClear } from 'react-icons/ai';
import { BiBrush } from 'react-icons/bi';
import { MdAnnouncement } from 'react-icons/md';
//import { AiOutlineAdvertisement } from 'react-icons/ai';
import { BiBroadcast } from 'react-icons/bi';
import { FaBullhorn, FaAd } from 'react-icons/fa';
import { MdOutlineCampaign } from 'react-icons/md';
import { FaConciergeBell } from 'react-icons/fa';
import { MdMiscellaneousServices } from 'react-icons/md';
import { BiServiceMark } from 'react-icons/bi';
import { FaQuestionCircle } from 'react-icons/fa'; // Question mark circle for unknown/default
import { FaMotorcycle } from 'react-icons/fa'; // Generic motorcycle icon
import { MdTwoWheeler } from 'react-icons/md'; // Two-wheeler, can represent a motorcycle
import { RiMotorbikeFill } from 'react-icons/ri'; // Another motorcycle icon
import { FaHome } from 'react-icons/fa'; // Home icon
import { GiRockingChair } from 'react-icons/gi'; // Represents Furniture
import { MdKitchen } from 'react-icons/md'; // Represents Appliances, specifically kitchen ones
import { FaBuilding } from 'react-icons/fa';
import { FaCar } from 'react-icons/fa';
import { FaSign } from 'react-icons/fa';
import { FaBirthdayCake } from 'react-icons/fa';
import { FaRing } from 'react-icons/fa'; // FontAwesome ring, might be used for weddings
import { FaHeart } from 'react-icons/fa'; // FontAwesome heart, common love symbol
import { MdOutlineCake } from 'react-icons/md'; // MaterialDesign cake, could represent a wedding cake
import { FaGlassCheers } from 'react-icons/fa'; // Celebration, cheers
import { FaInfinity } from 'react-icons/fa'; // Infinity, symbolizing enduring love
import { FaRedo } from 'react-icons/fa'; // Symbolizing the act of renewing or redoing something
import { FaChair } from 'react-icons/fa';
import { MdCelebration } from 'react-icons/md';
import { AiOutlineGold } from 'react-icons/ai';
import { BiHappyBeaming } from 'react-icons/bi';
import { FaBlackTie } from 'react-icons/fa'; // Represents formal memorials
import { FaBookDead } from 'react-icons/fa'; // Directly represents obituaries and memorials
import { FaUsers } from 'react-icons/fa'; // Symbolizes a group or family gathering
import { FaGraduationCap } from 'react-icons/fa'; // Symbolizes graduation or academic achievement
import { FaAward } from 'react-icons/fa'; // Symbolizes academic achievements, honors, and awards
import { FaChurch } from 'react-icons/fa'; // Importing multiple icons
import { FaBaby, FaSurprise, FaTruckMoving, FaMedal, FaFlag, FaRibbon, FaSuitcaseRolling } from 'react-icons/fa'; // Importing multiple icons
import { FaUmbrellaBeach } from 'react-icons/fa'; // Represents relaxation and leisure, suitable for retirement
import { FaRegSadCry } from 'react-icons/fa'; // Represents sorrow and empathy, suitable for memorials and obituaries
import { FaGlobe } from 'react-icons/fa'; // Represents the world or global unity, conveying the idea of diversity and inclusivity across different cultures and religions
import { FaCrown } from 'react-icons/fa'; // Symbolizes elegance, royalty, and celebration, fitting for events marking the transition to adulthood
import { FaShieldAlt } from 'react-icons/fa'; // Represents protection and security
import { FaBriefcase } from 'react-icons/fa'; // Represents executive roles and professionalism
import { FaChartLine } from 'react-icons/fa'; // Represents financial data and stock market trends
import { FaChalkboardTeacher } from 'react-icons/fa'; // Symbolizes education, learning, and presentations
import { FaDesktop } from 'react-icons/fa'; // Symbolizes technology, computers, and online activities
import { FaHandsHelping } from 'react-icons/fa'; // Symbolizes support, assistance, and community involvement
import { FaBellSlash } from 'react-icons/fa'; // Represents muted notifications or alerts
//import { GiHeartBurning } from 'react-icons/gi'; // Represents charity events and fundraisers
import { IoMdHand } from 'react-icons/io'; // Represents charity events and fundraisers
import { BsExclamationTriangleFill, BsFileText, BsMegaphone } from 'react-icons/bs';
import { MdEvent, MdSports } from 'react-icons/md';
import { GiYinYang, GiPaintBrush, GiCookingPot } from 'react-icons/gi';

import { 
  FaHandHoldingHeart, // Represents care, support, and generosity
  FaPencilAlt, // Represents the act of registering or enrolling
  FaRegClipboard, // Symbolizes forms, registration, and administrative tasks
  FaRunning, // Represents endurance, athleticism, and the act of running a marathon
  FaTrophy, // Symbolizes victories, achievements, and setting personal bests
  FaRocket, // Represents new ventures, startups, or launching new products/services
  FaUndo, // Represents the action of undoing or reversing, suitable for recalls
  FaExclamationTriangle, // Represents warning or caution, also suitable for recalls
  FaHandshake, // Represents a partnership or deal agreement
  // FaUsers, // Symbolizes collaboration or partnership
  FaLink, // Can symbolize connection or joining forces
  FaSitemap, // Represents structure and connection, useful for mergers
  FaTruck,
  FaTaxi, // Represents ride services, taxis, and car transportation
  FaCheckCircle,
  FaUserPlus,
  FaUserMinus,
  FaMusic, 
  FaPeopleCarry,
  FaSearch,
  FaMedkit,
  FaComments,
  FaShoppingBasket, FaCalendarAlt, 
  FaSchool, FaUserCheck,
  FaMoneyCheckAlt, FaLightbulb, FaBrain,
  FaUniversity, FaPlane, FaDog,
} from 'react-icons/fa'; // Importing multiple icons





export const ICON_MAP = {
  MdCleaningServices: MdCleaningServices,
  AiOutlineClear: AiOutlineClear,
  BiBrush: BiBrush,
  // Advert service icons
  MdAnnouncement: MdAnnouncement,
  //AiOutlineAdvertisement: AiOutlineAdvertisement,
  BiBroadcast: BiBroadcast,
  FaBullhorn: FaBullhorn,
  FaAd: FaAd,
  MdOutlineCampaign: MdOutlineCampaign,
  FaConciergeBell: FaConciergeBell,
  MdMiscellaneousServices: MdMiscellaneousServices,
  //BiServiceMark: BiServiceMark,
  defaultIcon: FaQuestionCircle,
  FaMotorcycle: FaMotorcycle,
  MdTwoWheeler: MdTwoWheeler,
  RiMotorbikeFill: RiMotorbikeFill,
  harley1: FaMotorcycle, // Assuming the same icons for Harley-Davidson for the example
  harley2: MdTwoWheeler,
  harley3: RiMotorbikeFill,
  Home: FaHome,
  Furniture: GiRockingChair,
  Kitchen: MdKitchen,
  FaBuilding: FaBuilding,
  FaCar: FaCar,
  PropertyForSale: FaSign,
  BirthdayCelebrations: FaBirthdayCake,
  // Vow Renewals icon
  Weddings: FaRing,
  FaRedo: FaRedo,
  WeddingAnniversaries: FaHeart,
  MdOutlineCake: MdOutlineCake,
  WeddingAnniversaries: FaGlassCheers,
  VowRenewals: FaInfinity,
  FaChair:FaChair,
  // Memorial and Obituary icon
  FaBlackTie: FaBlackTie,
  FaBookDead: FaBookDead,
  FamilyReunions: FaUsers, // Family Reunions icon
  Graduations: FaGraduationCap, // For Graduations
  AwardandRecognition: FaAward,
  FaChurch: FaChurch, // Symbolizes religious ceremonies and places of worship
  CompanyAnniversaries: FaBirthdayCake, // Symbolizes coming of age celebrations and significant milestones
  PregnancyAnnouncements: FaBaby, // Symbolizes pregnancy announcements and the joy of a new addition to the family
  FaSurprise: FaSurprise, // Symbolizes the surprise and joy of gender reveal parties
  MovingorRelocation: FaTruckMoving, // Symbolizes moving or relocation, capturing the essence of transition and new beginnings
  AcademicAchievements: FaMedal, // Symbolizes honor and achievement, relevant for military service
  FaFlag: FaFlag, // Represents patriotism and national service, fitting for enlistment or return
  FaRibbon: FaRibbon, // Symbolizes grand openings or reopenings, capturing the essence of celebration and new beginnings
  BusinessRelocation: FaBuilding, // Represents the business aspect of relocation
  FaSuitcaseRolling: FaSuitcaseRolling, // Represents the mobility and dynamic nature of business travel or relocation
  FaHandHoldingHeart: FaHandHoldingHeart, // Symbolizes charity events and fundraisers, emphasizing themes of support and giving
  FaPencilAlt: FaPencilAlt, // Symbolizes the act of enrolling or signing up for school
  SchoolEnrollmentandRegistration: FaRegClipboard, // Represents the administrative aspect of school registration
  FaRunning: FaRunning, // Ideal for representing marathon completions and athletic endeavors
  PersonalAchievements: FaTrophy, // Perfect for signifying personal achievements and victories
  NewProductorServiceLaunches: FaRocket,
  
  // Additional icons for Business Partnerships or Mergers
  BusinessPartnershipsorMergers: FaHandshake,
  FaUsers: FaUsers,
  VolunteerOpportunities: FaLink,
  FaSitemap: FaSitemap,
  FaTruck: FaTruck,
  FaTaxi: FaTaxi,
  Retirement: FaUmbrellaBeach,
  MemorialsandObituaries: FaRegSadCry,
  ReligiousCeremonies: FaGlobe,
  ComingofAgeCelebrations: FaCrown,
  MilitaryEnlistmentorReturn: FaShieldAlt,
  ProductRecalls: FaExclamationTriangle,
  ExecutiveAppointmentsandPromotions: FaBriefcase,
  StockMarketListings: FaChartLine,
  WorkshopsSeminarsandConferences: FaChalkboardTeacher,
  WebinarsandOnlineCourses: FaDesktop,
  CorporateSocialResponsibilityInitiatives: FaHandsHelping,
  SafetyAlertsandNotices: FaBellSlash,
  ProjectKickoffsandCompletions: FaCheckCircle,
  HiringAnnouncements: FaUserPlus,
  LayoffsandDownsizing: FaUserMinus,
  //GiHeartBurning: GiHeartBurning,
  CharityEventsandFundraisers: IoMdHand,
  FestivalsandFairs: FaMusic,
  PublicDemonstrationsandRallies: FaPeopleCarry,
  LostandFoundNotices: FaSearch,
  PublicHealthAnnouncements: FaMedkit,
  EmergencyAlerts: BsExclamationTriangleFill,
  GovernmentPoliciesandNotices: BsFileText,
  PublicServiceAnnouncements: BsMegaphone,
  CulturalExhibitionsandPerformances: MdEvent,
  SportsEventsandCompetitions: MdSports,
  yoga: GiYinYang,
  CommunityClasses: GiPaintBrush,
  cooking: GiCookingPot,
  BookClubsandReadingSessions: FaComments,
  LocalMarketDays: FaShoppingBasket,
  calendar: FaCalendarAlt,
  ScholarshipOpportunities: FaMoneyCheckAlt,
  opportunity: FaLightbulb,
  CampusEvents: FaUniversity,
  school: FaSchool,
  SchoolEnrollmentandRegistration: FaUserCheck,
  AcademicCompetitions: FaBrain,
  StudyAbroadPrograms: FaPlane,
  LostPets: FaDog, // or FaCat
  

 


};
