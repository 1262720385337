import React, { useState, useEffect } from "react";
import drawService from "./Services/drawService";

const TestGame = () => {
  const [dailyWinners, setDailyWinners] = useState([]);
  const [weeklyWinners, setWeeklyWinners] = useState([]);
  const [monthlyWinners, setMonthlyWinners] = useState([]);
  const [selectedDraw, setSelectedDraw] = useState("daily");

  useEffect(() => {
    const fetchWinners = async () => {
      try {
        const dailyWinnersData = await drawService.getDailyWinners();
        setDailyWinners(dailyWinnersData);

        const weeklyWinnersData = await drawService.getWeeklyWinners();
        setWeeklyWinners(weeklyWinnersData);

        const monthlyWinnersData = await drawService.getMonthlyWinners();
        setMonthlyWinners(monthlyWinnersData);
      } catch (error) {
        console.error("Error fetching winners:", error);
      }
    };

    fetchWinners();
  }, []);

  const handleAddWinner = async () => {
    const newWinner = {
      type: selectedDraw,
      name:
        selectedDraw === "daily"
          ? "Daily Test Winner"
          : selectedDraw === "weekly"
          ? "Weekly Test Winner"
          : "Monthly Test Winner",
      prize:
        selectedDraw === "daily"
          ? "Daily Test Prize"
          : selectedDraw === "weekly"
          ? "Weekly Test Prize"
          : "Monthly Test Prize",
      isTest: true,
    };

    try {
      await drawService.addWinner(newWinner);
      console.log("Winner added successfully");
    } catch (error) {
      console.error("Error adding winner:", error);
    }
  };

  return (
    <div>
      <h1>Test Game Functions</h1>
      <div>
        <label>Select Draw Type: </label>
        <select
          value={selectedDraw}
          onChange={(e) => setSelectedDraw(e.target.value)}
        >
          <option value="daily">Daily</option>
          <option value="weekly">Weekly</option>
          <option value="monthly">Monthly</option>
        </select>
      </div>
      <button onClick={handleAddWinner}>Add Test Winner</button>
      <h2>Daily Winners</h2>
      <ul>
        {dailyWinners.map((winner, index) => (
          <li key={index}>
            {winner.name}: {winner.prize}
          </li>
        ))}
      </ul>
      <h2>Weekly Winners</h2>
      <ul>
        {weeklyWinners.map((winner, index) => (
          <li key={index}>
            {winner.name}: {winner.prize}
          </li>
        ))}
      </ul>
      <h2>Monthly Winners</h2>
      <ul>
        {monthlyWinners.map((winner, index) => (
          <li key={index}>
            {winner.name}: {winner.prize}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default TestGame;
