import React from "react";
import ReactDOM from "react-dom/client";
import { AddressUpdateProvider } from "./components/contexts/AddressUpdateContext";
import "bootstrap/dist/css/bootstrap.min.css";
import "./index.css";
import App from "./App";

if ("serviceWorker" in navigator) {
  navigator.serviceWorker
    .register("/firebase-messaging-sw.js")
    .then((registration) => {
      console.log(
        "Service Worker registration successful with scope: ",
        registration.scope
      );
    })
    .catch((error) => {
      console.error("Service Worker registration failed: ", error);
    });
}

// }

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <AddressUpdateProvider>
      <App />
    </AddressUpdateProvider>
  </React.StrictMode>
);

