// import React, { useState, useEffect, useContext } from "react";
// import { useNavigate } from "react-router-dom";
// import { projectFirestore } from "../../firebase/config";
// import { doc, getDoc, updateDoc } from "firebase/firestore";
// import { UserContext } from "../contexts/UserContext";
// import { Alert, Form, Button, Container, Card } from "react-bootstrap";
// import PhoneNumberInput from "./PhoneNumberInput"; // Assume this is your custom component for phone number

// const ProfileCompletion = () => {
//   const [profileData, setProfileData] = useState({
//     phoneNumber: "",
//     momoNumber: "",
//     momoServiceProvider: "",
//     address: {
//       street: "",
//       postcode: "",
//       suburb: "",
//       city: "",
//       country: "Ghana", // Default country to Ghana
//     },
//   });
//   const [loading, setLoading] = useState(false);
//   const [error, setError] = useState("");
//   const navigate = useNavigate();
//   const { currentUser } = useContext(UserContext);

//   useEffect(() => {
//     const fetchUserProfile = async () => {
//       if (currentUser) {
//         const userProfileRef = doc(projectFirestore, "users", currentUser.uid);
//         const userProfileSnap = await getDoc(userProfileRef);
//         if (userProfileSnap.exists()) {
//           const userData = userProfileSnap.data();
//           setProfileData({
//             phoneNumber: userData.phoneNumber || "",
//             momoNumber: userData.momoNumber || "",
//             momoServiceProvider: userData.momoServiceProvider || "",
//             address: userData.address || {
//               street: "",
//               postcode: "",
//               suburb: "",
//               city: "",
//               country: "Ghana",
//             },
//           });
//         } else {
//           console.error("User profile not found.");
//         }
//       }
//     };
//     fetchUserProfile();
//   }, [currentUser]);

//   const handleInputChange = (e) => {
//     const { name, value } = e.target;
//     if (name.startsWith("address.")) {
//       const addressKey = name.split(".")[1];
//       setProfileData((prevData) => ({
//         ...prevData,
//         address: {
//           ...prevData.address,
//           [addressKey]: value,
//         },
//       }));
//     } else {
//       setProfileData((prevData) => ({
//         ...prevData,
//         [name]: value,
//       }));
//     }
//   };

//   const handlePhoneNumberChange = (value, name) => {
//     // Ensure the phone number starts with +233 for Ghana
//     if (value && !value.startsWith("+233")) {
//       value = "+233" + value.replace(/^0+/, ""); // Remove leading zeros and add +233
//     }
//     setProfileData((prevData) => ({
//       ...prevData,
//       [name]: value,
//     }));
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     setLoading(true);
//     setError("");

//     if (!profileData.phoneNumber) {
//       setError("Please enter a phone number.");
//       setLoading(false);
//       return;
//     }

//     if (!profileData.momoNumber) {
//       setError("Please enter a Momo number.");
//       setLoading(false);
//       return;
//     }

//     if (!profileData.momoServiceProvider) {
//       setError("Please select a Momo service provider.");
//       setLoading(false);
//       return;
//     }

//     try {
//       const userProfileRef = doc(projectFirestore, "users", currentUser.uid);
//       await updateDoc(userProfileRef, {
//         ...profileData,
//         profileCompleted: true,
//       });
//       console.log("Updating profile with data:", profileData);

//       console.log("Profile updated successfully.");
//       navigate("/");
//     } catch (err) {
//       console.error("Error updating profile:", err);
//       setError("Failed to update profile. Please try again later.");
//     }
//     setLoading(false);
//   };

//   return (
//     <Container className="my-5">
//       <Card>
//         <Card.Body>
//           <Card.Title>Edit Profile</Card.Title>
//           {error && <Alert variant="danger">{error}</Alert>}
//           <Form onSubmit={handleSubmit}>
//             <Form.Group className="mb-3">
//               <Form.Label>Phone Number</Form.Label>
//               <PhoneNumberInput
//                 value={profileData.phoneNumber}
//                 onChange={(value) =>
//                   handlePhoneNumberChange(value, "phoneNumber")
//                 }
//                 placeholder="Enter phone number"
//                 defaultCountry="GH" // Set default country to Ghana
//               />
//             </Form.Group>

//             <Form.Group className="mb-3">
//               <Form.Label>Momo Number</Form.Label>
//               <PhoneNumberInput
//                 value={profileData.momoNumber}
//                 onChange={(value) =>
//                   handlePhoneNumberChange(value, "momoNumber")
//                 }
//                 placeholder="Enter Momo number"
//                 defaultCountry="GH" // Set default country to Ghana
//               />
//             </Form.Group>

//             <Form.Group className="mb-3">
//               <Form.Label>Momo Service Provider</Form.Label>
//               <Form.Control
//                 as="select"
//                 name="momoServiceProvider"
//                 value={profileData.momoServiceProvider}
//                 onChange={handleInputChange}
//               >
//                 <option value="">Select Service Provider</option>
//                 <option value="MTN">MTN</option>
//                 <option value="VodafoneTelecel">VodafoneTelecel</option>
//                 <option value="AirtelTigo">AirtelTigo</option>
//               </Form.Control>
//             </Form.Group>

//             <Form.Group className="mb-3">
//               <Form.Label>Street</Form.Label>
//               <Form.Control
//                 type="text"
//                 placeholder="Enter street"
//                 name="address.street"
//                 value={profileData.address.street}
//                 onChange={handleInputChange}
//               />
//             </Form.Group>

//             <Form.Group className="mb-3">
//               <Form.Label>Postcode</Form.Label>
//               <Form.Control
//                 type="text"
//                 placeholder="Enter postcode"
//                 name="address.postcode"
//                 value={profileData.address.postcode}
//                 onChange={handleInputChange}
//               />
//             </Form.Group>

//             <Form.Group className="mb-3">
//               <Form.Label>Suburb</Form.Label>
//               <Form.Control
//                 type="text"
//                 placeholder="Enter suburb"
//                 name="address.suburb"
//                 value={profileData.address.suburb}
//                 onChange={handleInputChange}
//               />
//             </Form.Group>

//             <Form.Group className="mb-3">
//               <Form.Label>City</Form.Label>
//               <Form.Control
//                 type="text"
//                 placeholder="Enter city"
//                 name="address.city"
//                 value={profileData.address.city}
//                 onChange={handleInputChange}
//               />
//             </Form.Group>

//             <Form.Group className="mb-3">
//               <Form.Label>Country</Form.Label>
//               <Form.Control
//                 type="text"
//                 placeholder="Enter country"
//                 name="address.country"
//                 value={profileData.address.country}
//                 onChange={handleInputChange}
//                 disabled // Disable the input to ensure country remains Ghana
//               />
//             </Form.Group>

//             <Button variant="primary" type="submit" disabled={loading}>
//               {loading ? "Updating..." : "Update Profile"}
//             </Button>
//           </Form>
//         </Card.Body>
//       </Card>
//     </Container>
//   );
// };

// export default ProfileCompletion;

import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { projectFirestore } from "../../firebase/config";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { UserContext } from "../contexts/UserContext";
import { Alert, Form, Button, Container, Card } from "react-bootstrap";
import PhoneNumberInput from "./PhoneNumberInput"; // Assume this is your custom component for phone number

const ProfileCompletion = () => {
  const [profileData, setProfileData] = useState({
    phoneNumber: "",
    momoNumber: "",
    momoServiceProvider: "",
    address: {
      street: "",
      postcode: "",
      suburb: "",
      city: "",
      country: "Ghana", // Default country to Ghana
    },
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const { currentUser } = useContext(UserContext);

  useEffect(() => {
    const fetchUserProfile = async () => {
      if (currentUser) {
        const userProfileRef = doc(projectFirestore, "users", currentUser.uid);
        const userProfileSnap = await getDoc(userProfileRef);
        if (userProfileSnap.exists()) {
          const userData = userProfileSnap.data();
          setProfileData({
            phoneNumber: userData.phoneNumber || "",
            momoNumber: userData.momoNumber || "",
            momoServiceProvider: userData.momoServiceProvider || "",
            address: userData.address || {
              street: "",
              postcode: "",
              suburb: "",
              city: "",
              country: "Ghana",
            },
          });
        } else {
          console.error("User profile not found.");
        }
      }
    };
    fetchUserProfile();
  }, [currentUser]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name.startsWith("address.")) {
      const addressKey = name.split(".")[1];
      setProfileData((prevData) => ({
        ...prevData,
        address: {
          ...prevData.address,
          [addressKey]: value,
        },
      }));
    } else {
      setProfileData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const handlePhoneNumberChange = (value, name) => {
    // Ensure the phone number starts with +233 for Ghana
    if (value && !value.startsWith("+233")) {
      value = "+233" + value.replace(/^0+/, ""); // Remove leading zeros and add +233
    }
    setProfileData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError("");

    // Check for all required fields
    if (!profileData.phoneNumber) {
      setError("Please enter a phone number.");
      setLoading(false);
      return;
    }

    if (!profileData.momoNumber) {
      setError("Please enter a Momo number.");
      setLoading(false);
      return;
    }

    if (!profileData.momoServiceProvider) {
      setError("Please select a Momo service provider.");
      setLoading(false);
      return;
    }

    if (!profileData.address.street) {
      setError("Please enter a street.");
      setLoading(false);
      return;
    }

    if (!profileData.address.postcode) {
      setError("Please enter a postcode.");
      setLoading(false);
      return;
    }

    if (!profileData.address.suburb) {
      setError("Please enter a suburb.");
      setLoading(false);
      return;
    }

    if (!profileData.address.city) {
      setError("Please enter a city.");
      setLoading(false);
      return;
    }

    try {
      const userProfileRef = doc(projectFirestore, "users", currentUser.uid);
      await updateDoc(userProfileRef, {
        ...profileData,
        profileCompleted: true,
      });
      console.log("Updating profile with data:", profileData);

      console.log("Profile updated successfully.");
      navigate("/");
    } catch (err) {
      console.error("Error updating profile:", err);
      setError("Failed to update profile. Please try again later.");
    }
    setLoading(false);
  };

  return (
    <Container className="my-5">
      <Card>
        <Card.Body>
          <Card.Title>Edit Profile</Card.Title>
          {error && <Alert variant="danger">{error}</Alert>}
          <Form onSubmit={handleSubmit}>
            <Form.Group className="mb-3">
              <Form.Label>Call Phone Number</Form.Label>
              <PhoneNumberInput
                value={profileData.phoneNumber}
                onChange={(value) =>
                  handlePhoneNumberChange(value, "phoneNumber")
                }
                placeholder="Enter your call phone number"
                defaultCountry="GH" // Set default country to Ghana
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Momo Number</Form.Label>
              <PhoneNumberInput
                value={profileData.momoNumber}
                onChange={(value) =>
                  handlePhoneNumberChange(value, "momoNumber")
                }
                placeholder="Enter Mobile Money payment number"
                defaultCountry="GH" // Set default country to Ghana
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Momo Service Provider</Form.Label>
              <Form.Control
                as="select"
                name="momoServiceProvider"
                value={profileData.momoServiceProvider}
                onChange={handleInputChange}
              >
                <option value="">Select Service Provider</option>
                <option value="MTN">MTN</option>
                <option value="VodafoneTelecel">VodafoneTelecel</option>
                <option value="AirtelTigo">AirtelTigo</option>
              </Form.Control>
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Street</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter street"
                name="address.street"
                value={profileData.address.street}
                onChange={handleInputChange}
                required
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Postcode</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter postcode"
                name="address.postcode"
                value={profileData.address.postcode}
                onChange={handleInputChange}
                required
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Suburb</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter suburb"
                name="address.suburb"
                value={profileData.address.suburb}
                onChange={handleInputChange}
                required
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>City</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter city"
                name="address.city"
                value={profileData.address.city}
                onChange={handleInputChange}
                required
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Country</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter country"
                name="address.country"
                value={profileData.address.country}
                onChange={handleInputChange}
                disabled // Disable the input to ensure country remains Ghana
                required
              />
            </Form.Group>

            <Button variant="primary" type="submit" disabled={loading}>
              {loading ? "Updating..." : "Update Profile"}
            </Button>
          </Form>
        </Card.Body>
      </Card>
    </Container>
  );
};

export default ProfileCompletion;
