// import { initializeApp } from "firebase/app";
// import { getFirestore, serverTimestamp } from "firebase/firestore";
// import { getAuth, GoogleAuthProvider } from "firebase/auth";
// import { getMessaging,  isSupported, getToken, onMessage } from "firebase/messaging";
// import { getStorage } from "firebase/storage";
// import { getFunctions } from "firebase/functions";

// const firebaseConfig = {
//   apiKey: "AIzaSyAgtOiOuXIuMYrjB6TSvkeWjtCbxx2Zl78",
//   authDomain: "allghanaians.firebaseapp.com",
//   projectId: "allghanaians",
//   storageBucket: "allghanaians.appspot.com",
//   messagingSenderId: "831662496211",
//   appId: "1:831662496211:web:687d7d11f4d05e27e63726",
//   measurementId: "G-RRV2PFFDJK",
// };

// const app = initializeApp(firebaseConfig);

// const projectAuth = getAuth(app);
// const projectFirestore = getFirestore(app);
// const projectStorage = getStorage(app);
// const googleProvider = new GoogleAuthProvider();
// const projectFunctions = getFunctions(app);
// export const messaging = async () => (await isSupported()) && getMessaging(app);


// const generateToken = async () => {
//   try {
//     const permission = await Notification.requestPermission();
//     console.log("Notification permission status:", permission);
//     if (permission === "granted") {
//       const token = await getToken(messaging, {
//         vapidKey:
//           "BLUYgZWYnMYBR9gTxyqVg4eAciTxAeCuvSPR5HsERN8Kcd7EO5m8pRKfnfB-7gG6p0M_DiAqJdUXQOuWfhQ4XFg",
//       });
//       console.log("FCM Token:", token);
//       return token; // Return the token so it can be used in the component
//     } else {
//       console.log("Unable to get permission to notify.");
//       return null;
//     }
//   } catch (error) {
//     console.error("An error occurred while retrieving token. ", error);
//     return null;
//   }
// };
  

// export {
//   projectAuth,
//   projectFirestore,
//   projectStorage,
//   googleProvider,
//   projectFunctions,
//   generateToken,
//   serverTimestamp,
// };


// // config.js
// import { initializeApp } from "firebase/app";
// import { getFirestore, serverTimestamp } from "firebase/firestore";
// import { getAuth, GoogleAuthProvider } from "firebase/auth";
// import { getMessaging, isSupported, getToken, onMessage } from "firebase/messaging";
// import { getStorage } from "firebase/storage";
// import { getFunctions } from "firebase/functions";

// const firebaseConfig = {
//   apiKey: "AIzaSyAgtOiOuXIuMYrjB6TSvkeWjtCbxx2Zl78",
//   authDomain: "allghanaians.firebaseapp.com",
//   projectId: "allghanaians",
//   storageBucket: "allghanaians.appspot.com",
//   messagingSenderId: "831662496211",
//   appId: "1:831662496211:web:687d7d11f4d05e27e63726",
//   measurementId: "G-RRV2PFFDJK",
// };

// const app = initializeApp(firebaseConfig);

// const projectAuth = getAuth(app);
// const projectFirestore = getFirestore(app);
// const projectStorage = getStorage(app);
// const googleProvider = new GoogleAuthProvider();
// const projectFunctions = getFunctions(app);

// let messagingInstance = null;
// isSupported().then((supported) => {
//   if (supported) {
//     messagingInstance = getMessaging(app);
//   }
// });

// const generateToken = async () => {
//   if (!messagingInstance) {
//     console.log("Messaging is not supported in this browser.");
//     return null;
//   }

//   try {
//     const permission = await Notification.requestPermission();
//     console.log("Notification permission status:", permission);
//     if (permission === "granted") {
//       const token = await getToken(messagingInstance, {
//         vapidKey:
//           "BLUYgZWYnMYBR9gTxyqVg4eAciTxAeCuvSPR5HsERN8Kcd7EO5m8pRKfnfB-7gG6p0M_DiAqJdUXQOuWfhQ4XFg",
//       });
//       console.log("FCM Token:", token);
//       return token;
//     } else {
//       console.log("Unable to get permission to notify.");
//       return null;
//     }
//   } catch (error) {
//     console.error("An error occurred while retrieving token. ", error);
//     return null;
//   }
// };

// export {
//   projectAuth,
//   projectFirestore,
//   projectStorage,
//   googleProvider,
//   projectFunctions,
//   generateToken,
//   serverTimestamp,
//   messagingInstance as messaging,
// };


import { initializeApp } from "firebase/app";
import { getFirestore, serverTimestamp } from "firebase/firestore";
import { getAuth, GoogleAuthProvider } from "firebase/auth";
import {
  getMessaging,
  isSupported,
  getToken,
  onMessage,
} from "firebase/messaging";
import { getStorage } from "firebase/storage";
import { getFunctions } from "firebase/functions";

const firebaseConfig = {
  apiKey: "AIzaSyAgtOiOuXIuMYrjB6TSvkeWjtCbxx2Zl78",
  authDomain: "allghanaians.firebaseapp.com",
  projectId: "allghanaians",
  storageBucket: "allghanaians.appspot.com",
  messagingSenderId: "831662496211",
  appId: "1:831662496211:web:687d7d11f4d05e27e63726",
  measurementId: "G-RRV2PFFDJK",
};

const app = initializeApp(firebaseConfig);

const projectAuth = getAuth(app);
const projectFirestore = getFirestore(app);
const projectStorage = getStorage(app);
const googleProvider = new GoogleAuthProvider();
const projectFunctions = getFunctions(app);

let messagingInstance = null;
isSupported().then((supported) => {
  if (supported) {
    messagingInstance = getMessaging(app);
  }
});

const generateToken = async () => {
  if (!messagingInstance) {
    console.log("Messaging is not supported in this browser.");
    return null;
  }

  try {
    const permission = await Notification.requestPermission();
    console.log("Notification permission status:", permission);
    if (permission === "granted") {
      const token = await getToken(messagingInstance, {
        vapidKey:
          "BLUYgZWYnMYBR9gTxyqVg4eAciTxAeCuvSPR5HsERN8Kcd7EO5m8pRKfnfB-7gG6p0M_DiAqJdUXQOuWfhQ4XFg",
      });
      console.log("FCM Token:", token);
      return token;
    } else {
      console.log("Unable to get permission to notify.");
      return null;
    }
  } catch (error) {
    console.error("An error occurred while retrieving token. ", error);
    return null;
  }
};

export {
  projectAuth,
  projectFirestore,
  projectStorage,
  googleProvider,
  projectFunctions,
  generateToken,
  serverTimestamp,
  messagingInstance as messaging,
};
