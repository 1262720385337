import React, { useState } from 'react';
import { Form, Button, Alert } from 'react-bootstrap';
import { projectFirestore } from '../../../firebase/config';

const RescheduleDelivery = ({ orderId }) => {
  const [newDate, setNewDate] = useState('');
  const [newTime, setNewTime] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    try {
      await projectFirestore.collection('deliveryorders').doc(orderId).update({
        rescheduledDate: newDate,
        rescheduledTime: newTime
      });

      alert('Delivery date and time rescheduled successfully.');
      setNewDate('');
      setNewTime('');
    } catch (err) {
      setError(err.message);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="reschedule-container">
      <h3 className="title">Reschedule Delivery</h3>
      <Form onSubmit={handleSubmit} className="form">
        <Form.Control
          type="date"
          value={newDate}
          onChange={(e) => setNewDate(e.target.value)}
          required
        />
        <Form.Control
          type="time"
          value={newTime}
          onChange={(e) => setNewTime(e.target.value)}
          required
        />
        <Button type="submit" disabled={isSubmitting} className="submit-button">
          {isSubmitting ? 'Rescheduling...' : 'Reschedule'}
        </Button>
      </Form>
      {error && <Alert variant="danger">{error}</Alert>}
    </div>
  );
};

export default RescheduleDelivery;
