import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Form, Button, Container } from 'react-bootstrap';
import { collection, getDocs } from 'firebase/firestore';
import { projectFirestore } from '../../../firebase/config';

const PostAdvertCategorySelector = () => {
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('');
  const [subCategories, setSubCategories] = useState([]);
  const [selectedSubCategory, setSelectedSubCategory] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const fetchCategories = async () => {
      const querySnapshot = await getDocs(collection(projectFirestore, 'advertcategories'));
      setCategories(querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })));
    };
    fetchCategories();
  }, []);

  const handleCategoryChange = (e) => {
    const category = categories.find(cat => cat.id === e.target.value);
    setSelectedCategory(category.id);
    setSubCategories(category.subCategories || []);
    setSelectedSubCategory(''); // Reset sub-category selection
  };

  const handleSubCategoryChange = (e) => {
    setSelectedSubCategory(e.target.value);
  };

  const handleCreateAnnouncement = (e) => {
    e.preventDefault();
    if (selectedCategory && selectedSubCategory) {
      navigate(`/post-announcement/${selectedCategory}/${selectedSubCategory}`);
    } else {
      alert("Please select both a category and a sub-category.");
    }
  };

  return (
    <Container>
      <Form onSubmit={handleCreateAnnouncement}>
        <Form.Group>
          <Form.Label>Category</Form.Label>
          <Form.Select value={selectedCategory} onChange={handleCategoryChange}>
            <option value="">Select a Category</option>
            {categories.map(category => (
              <option key={category.id} value={category.id}>
                {category.name}
              </option>
            ))}
          </Form.Select>
        </Form.Group>
        
        <Form.Group>
          <Form.Label>Sub-Category</Form.Label>
          <Form.Select value={selectedSubCategory} onChange={handleSubCategoryChange}>
            <option value="">Select a Sub-Category</option>
            {subCategories.map(sub => (
              <option key={sub.subCategoryId} value={sub.subCategoryId}>
                {sub.name}
              </option>
            ))}
          </Form.Select>
        </Form.Group>
        
        <Button type="submit" disabled={!selectedCategory || !selectedSubCategory}>
          Create Announcement
        </Button>
      </Form>
    </Container>
  );
};

export default PostAdvertCategorySelector;

