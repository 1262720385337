import React from 'react';
import { Container, Row, Col, Card, Button, ListGroup } from 'react-bootstrap';

const CommunityPublicInterestDetail = ({ announcement }) => {
  // Placeholder data for the component
  const placeholderData = {
    title: "Community Cleanup Drive",
    date: "2024-08-15",
    time: "08:00 AM - 12:00 PM",
    location: "River Park, 456 Greenway Drive",
    description: "Join us for our annual cleanup drive to keep our park beautiful and litter-free. Volunteers are needed!",
    contact: "Register at cleanup@ourgreenpark.com or call us at (555) 123-4567.",
    images: [
      "https://via.placeholder.com/800x400/28a745/ffffff/?text=Cleanup+Drive+1",
      "https://via.placeholder.com/800x400/28a745/ffffff/?text=Cleanup+Drive+2",
    ],
    additionalInfo: "Supplies will be provided. All participants will receive a certificate of appreciation.",
    mapUrl: "https://via.placeholder.com/800x400/cccccc/000000/?text=Map+Placeholder",
  };

  // Replace placeholderData with your actual announcement data when available
  const data = announcement || placeholderData;

  return (
    <Container className="mt-5">
      <Row>
        <Col lg={8}>
          <Card>
            <Card.Body>
              <Card.Title>{data.title}</Card.Title>
              <Card.Subtitle className="mb-2 text-muted">{data.date} | {data.time}</Card.Subtitle>
              <Card.Text>{data.description}</Card.Text>
              <ListGroup variant="flush" className="mb-3">
                <ListGroup.Item><strong>Location:</strong> {data.location}</ListGroup.Item>
                <ListGroup.Item><strong>Contact:</strong> {data.contactInfo}</ListGroup.Item>
                <ListGroup.Item>{data.additionalInfo}</ListGroup.Item>
              </ListGroup>
              {data.images.map((img, index) => (
                <Card.Img key={index} variant="top" src={img} alt={`Community Event Image ${index + 1}`} className="mb-3"/>
              ))}
              <Button variant="primary">Register Now</Button>
            </Card.Body>
          </Card>
        </Col>
        <Col lg={4}>
          <Card>
            <Card.Body>
              <Card.Title>Event Location</Card.Title>
              <Card.Img src={data.mapUrl} alt="Map Location" />
              <Card.Text className="mt-2">
                <small className="text-muted">
                  If you're not sure how to get to River Park, click on the map or reach out to us for directions.
                </small>
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default CommunityPublicInterestDetail;
