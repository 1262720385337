import React, { useState } from "react";
import sendEmail from "./00sendEmail";
import { Form, Button, Container, Row, Col } from "react-bootstrap";

const SendEmailForm = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    recipients: "",
    subject: "",
    message: "",
  });

  const [validated, setValidated] = useState(false);
  const [statusMessage, setStatusMessage] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.stopPropagation();
    } else {
      try {
        await sendEmail(formData);
        setStatusMessage("Email sent successfully");
        setFormData({
          name: "",
          email: "",
          recipients: "",
          subject: "",
          message: "",
        });
      } catch (error) {
        setStatusMessage("Error sending email");
        console.error(error);
      }
    }
    setValidated(true);
  };

  return (
    <Container className="mt-5">
      <Row className="justify-content-md-center">
        <Col md="8">
          <h2 className="mb-4 text-center">Send Mail</h2>
          <Form
            noValidate
            validated={validated.toString()}
            onSubmit={handleSubmit}
          >
            <Form.Group controlId="formName">
              <Form.Label>Name</Form.Label>
              <Form.Control
                required
                type="text"
                placeholder="Enter your name"
                name="name"
                value={formData.name}
                onChange={handleChange}
              />
              <Form.Control.Feedback type="invalid">
                Please provide your name.
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group controlId="formEmail" className="mt-3">
              <Form.Label>Email</Form.Label>
              <Form.Control
                required
                type="email"
                placeholder="Enter your email"
                name="email"
                value={formData.email}
                onChange={handleChange}
              />
              <Form.Control.Feedback type="invalid">
                Please provide a valid email.
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group controlId="formRecipients" className="mt-3">
              <Form.Label>Recipients</Form.Label>
              <Form.Control
                required
                type="text"
                placeholder="Enter recipients' emails, separated by commas"
                name="recipients"
                value={formData.recipients}
                onChange={handleChange}
              />
              <Form.Control.Feedback type="invalid">
                Please provide at least one recipient email.
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group controlId="formSubject" className="mt-3">
              <Form.Label>Subject</Form.Label>
              <Form.Control
                required
                type="text"
                placeholder="Enter the subject"
                name="subject"
                value={formData.subject}
                onChange={handleChange}
              />
              <Form.Control.Feedback type="invalid">
                Please provide a subject.
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group controlId="formMessage" className="mt-3">
              <Form.Label>Message</Form.Label>
              <Form.Control
                required
                as="textarea"
                rows={5}
                placeholder="Enter your message"
                name="message"
                value={formData.message}
                onChange={handleChange}
              />
              <Form.Control.Feedback type="invalid">
                Please provide a message.
              </Form.Control.Feedback>
            </Form.Group>

            <Button className="mt-4 w-100" type="submit">
              Send Mail
            </Button>
          </Form>
          {statusMessage && <p className="mt-3 text-center">{statusMessage}</p>}
        </Col>
      </Row>
    </Container>
  );
};

export default SendEmailForm;
