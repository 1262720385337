import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import { projectFirestore } from '../../firebase/config';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { UserContext } from '../contexts/UserContext';

const AdvertsList = () => {
  const [adverts, setAdverts] = useState([]);
  const { currentUser } = useContext(UserContext);

  useEffect(() => {
    const fetchAdverts = async () => {
      if (currentUser) {
        const q = query(collection(projectFirestore, 'adverts'), where("userId", "==", currentUser.uid));
        const querySnapshot = await getDocs(q);
        const fetchedAdverts = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
          createdAt: doc.data().createdAt?.toDate()
        }));
        setAdverts(fetchedAdverts);
      }
    };

    fetchAdverts();
  }, [currentUser]);

  return (
    <div className="container mt-5">
      <h2 className="mb-4">My Adverts</h2>
      <table className="table table-hover">
        <thead className="thead-light">
          <tr>
            <th>ID</th>
            <th>Picture</th>
            <th>Title</th>
            <th>Date Posted</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {adverts.map((advert) => (
            <tr key={advert.id}>
              <td>{advert.id}</td>
              <td>
                {advert.images && advert.images[0] ? (
                  <img src={advert.images[0]} alt="Advert" className="img-fluid" style={{ maxWidth: '100px', maxHeight: '100px' }} />
                ) : (
                  <span className="text-muted">No Image</span>
                )}
              </td>
              <td>{advert.title}</td>
              <td>{advert.createdAt?.toLocaleDateString()}</td>
              <td>
                <Link to={`/edit-advert/${advert.id}`} className="btn btn-primary btn-sm me-2">Edit</Link>
                {/* Consider adding a delete button with a confirmation dialog */}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default AdvertsList;


// import React, { useState, useEffect, useContext } from 'react';
// import { Link } from 'react-router-dom';
// import { projectFirestore } from '../../firebase/config';
// import { collection, query, where, getDocs } from 'firebase/firestore';
// import { UserContext } from '../contexts/UserContext'; // Adjust the import path as needed

// const AdvertsList = () => {
//   const [adverts, setAdverts] = useState([]);
//   const { currentUser } = useContext(UserContext); // Use currentUser from context

//   useEffect(() => {
//     const fetchAdverts = async () => {
//       // Fetch only adverts created by the logged-in user
//       const q = query(collection(projectFirestore, 'adverts'), where("userId", "==", currentUser?.uid));
//       const querySnapshot = await getDocs(q);
//       const fetchedAdverts = querySnapshot.docs.map(doc => ({
//         id: doc.id,
//         ...doc.data(),
//         createdAt: doc.data().createdAt?.toDate() // Convert Firestore Timestamp to JavaScript Date object
//       }));
//       setAdverts(fetchedAdverts);
//     };

//     if (currentUser) {
//       fetchAdverts();
//     }
//   }, [currentUser]);

//   return (
//     <div className="container mt-5">
//       <h2 className="mb-4">My Adverts</h2>
//       <div className="table-responsive">
//         <table className="table table-striped">
//           <thead>
//             <tr>
//               <th>ID</th>
//               <th>Picture</th>
//               <th>Title</th>
//               <th>Date Posted</th>
//               <th>Actions</th>
//             </tr>
//           </thead>
//           <tbody>
//             {adverts.map((advert) => (
//               <tr key={advert.id}>
//                 <td>{advert.id}</td>
//                 <td>
//                   {advert.images && advert.images.length > 0 ? (
//                     <img src={advert.images[0]} alt="Advert" style={{ width: '80px', height: '80px', objectFit: 'cover' }} />
//                   ) : (
//                     <img src="/path/to/default-placeholder.png" alt="Default placeholder" style={{ width: '80px', height: '80px', objectFit: 'cover' }} />
//                   )}
//                 </td>
//                 <td>{advert.title}</td>
//                 <td>{advert.createdAt?.toLocaleDateString()}</td>
//                 <td>
//                   <Link to={`/edit-advert/${advert.id}`} className="btn btn-primary btn-sm me-2">Edit</Link>
//                   {/* Additional buttons can be added here */}
//                 </td>
//               </tr>
//             ))}
//           </tbody>
//         </table>
//       </div>
//     </div>
//   );
// };

// export default AdvertsList;


// import React, { useState, useEffect, useContext } from 'react';
// import { Link } from 'react-router-dom';
// import { projectFirestore } from '../../firebase/config';
// import { collection, getDocs, query, where } from 'firebase/firestore';
// import { UserContext } from '../contexts/UserContext'; // Adjust the import path according to your file structure

// const AdvertsList = () => {
//   const [adverts, setAdverts] = useState([]);
//   const { currentUser } = useContext(UserContext); // Assuming you have a context that provides currentUser

//   useEffect(() => {
//     const fetchAdverts = async () => {
//       let q;
//       if (currentUser) {
//         // Fetch only adverts created by the logged-in user
//         q = query(collection(projectFirestore, 'adverts'), where('userId', '==', currentUser.uid));
//       } else {
//         // Fallback or default query (adjust as needed)
//         q = query(collection(projectFirestore, 'adverts'));
//       }
//       const querySnapshot = await getDocs(q);
//       const fetchedAdverts = querySnapshot.docs.map(doc => {
//         const data = doc.data();
//         return {
//           id: doc.id,
//           ...data,
//           createdAt: data.createdAt ? data.createdAt.toDate() : new Date() // Convert Timestamp to Date
//         };
//       });
//       setAdverts(fetchedAdverts);
//     };

//     fetchAdverts();
//   }, [currentUser]); // Rerun when currentUser changes

//   return (
//     <div>
//       <h2>My Adverts</h2>
//       <table>
//         <thead>
//           <tr>
//             <th>Picture</th>
//             <th>Title</th>
//             <th>Date Posted</th>
//             <th>Actions</th>
//           </tr>
//         </thead>
//         <tbody>
//           {adverts.map((advert) => (
//             <tr key={advert.id}>
//               <td>
//                 <img src={advert.images && advert.images.length > 0 ? advert.images[0] : 'default-placeholder.png'} alt="Advert" style={{ width: '50px', height: '50px' }} />
//               </td>
//               <td>{advert.title}</td>
//               <td>{advert.createdAt.toDateString()}</td>
//               <td>
//                 <Link to={`/edit-advert/${advert.id}`}>Edit</Link>
//                 {/* Add Delete functionality here if needed */}
//               </td>
//             </tr>
//           ))}
//         </tbody>
//       </table>
//     </div>
//   );
// };

// export default AdvertsList;



