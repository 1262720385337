// import React, { useState, useEffect } from "react";
// import drawService from "../Services/drawService";
// import { Table } from "react-bootstrap";

// const DailyWinners = () => {
//   const [winners, setWinners] = useState([]);

//   useEffect(() => {
//     drawService.getDailyWinners().then(setWinners);
//   }, []);

//   const formatDate = (seconds) => {
//     const date = new Date(seconds * 1000);
//     const day = date.getDate().toString().padStart(2, "0");
//     const month = (date.getMonth() + 1).toString().padStart(2, "0");
//     const year = date.getFullYear();
//     return `${day}-${month}-${year}`;
//   };

//   const today = new Date();
//   const todayString = `${today.getDate().toString().padStart(2, "0")}-${(
//     today.getMonth() + 1
//   )
//     .toString()
//     .padStart(2, "0")}-${today.getFullYear()}`;

//   return (
//     <div>
//       <h2 className="mt-4">Daily Draw Winners</h2>
//       <div className="table-responsive">
//         <Table striped bordered hover>
//           <thead>
//             <tr>
//               <th>Date</th>
//               <th>Name</th>
//               <th>Claimed</th>
//               <th>Amount</th>
//               <th>Paid</th>
//             </tr>
//           </thead>
//           <tbody>
//             {winners
//               .sort((a, b) => b.date.seconds - a.date.seconds)
//               .map((winner, index) => (
//                 <tr
//                   key={index}
//                   className={
//                     formatDate(winner.date.seconds) === todayString
//                       ? "table-primary"
//                       : ""
//                   }
//                   style={
//                     formatDate(winner.date.seconds) === todayString
//                       ? { fontWeight: "bold" }
//                       : {}
//                   }
//                 >
//                   <td>{formatDate(winner.date.seconds)}</td>
//                   <td>{winner.name}</td>
//                   <td>{winner.claimed}</td>
//                   <td>{winner.amount} cedis</td>
//                   <td>{winner.paid}</td>
//                 </tr>
//               ))}
//           </tbody>
//         </Table>
//       </div>
//     </div>
//   );
// };

// export default DailyWinners;

import React, { useState, useEffect } from "react";
import drawService from "../Services/drawService";
import { Table } from "react-bootstrap";

const DailyWinners = () => {
  const [winners, setWinners] = useState([]);

  useEffect(() => {
    drawService.getDailyWinners().then(setWinners);
  }, []);

  const formatDate = (seconds) => {
    const date = new Date(seconds * 1000);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };

  const today = new Date();
  const todayString = `${today.getDate().toString().padStart(2, "0")}-${(
    today.getMonth() + 1
  )
    .toString()
    .padStart(2, "0")}-${today.getFullYear()}`;

  return (
    <div>
      <h2 className="mt-4">Daily Draw Winners</h2>
      <div className="table-responsive">
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Date</th>
              <th>Name</th>
              <th>Amount</th>
              <th>Claimed</th>
              <th>Paid</th>
            </tr>
          </thead>
          <tbody>
            {winners
              .sort((a, b) => b.date.seconds - a.date.seconds)
              .map((winner, index) => (
                <tr
                  key={index}
                  className={
                    formatDate(winner.date.seconds) === todayString
                      ? "table-primary"
                      : ""
                  }
                >
                  <td>{formatDate(winner.date.seconds)}</td>
                  <td>{winner.name}</td>
                  <td>{winner.amount} cedis</td>
                  <td
                    style={{
                      fontWeight: "bold",
                      color: winner.claimed ? "blue" : "black",
                    }}
                  >
                    {winner.claimed ? "Claimed" : "Not Claimed"}
                  </td>

                  <td
                    style={{
                      fontWeight: "bold",
                      color: winner.paid === "Yes Paid" ? "blue" : "black",
                    }}
                  >
                    {winner.paid || "Not Paid"}
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
      </div>
    </div>
  );
};

export default DailyWinners;
