import React, { useState } from 'react';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { projectFirestore } from '../../firebase/config';
// import DeliveryLayout from '../../components/Hoc/DeliveryLayout/DeliveryLayout';

function DeliveryHelp() {
  const [ticket, setTicket] = useState('');
  const navigate = useNavigate();

  const handleTicketSubmission = async () => {
    try {
      // Check if ticket is not empty
      if (!ticket.trim()) {
        throw new Error('Ticket description is required.');
      }

      // Save ticket to Firestore
      await projectFirestore.collection('tickets').add({
        description: ticket,
        submittedAt: new Date()
      });

      // Reset the ticket field after submission
      setTicket('');
      // Navigate to some confirmation page or home page
      navigate('/confirmation');
    } catch (error) {
      console.error('Error submitting ticket:', error.message);
    }
  };

  return (
    // <DeliveryLayout>
      <Container>
        <Row className="mt-4">
          <Col>
            <h1>Help & Support</h1>
          </Col>
        </Row>

        {/* FAQs and Guides */}
        <Row className="mt-4">
          <Col>
            <h2>FAQs & Guides</h2>
            {/* List FAQs here */}
          </Col>
        </Row>

        {/* Ticket System */}
        <Row className="mt-4">
          <Col>
            <h2>Raise a Ticket</h2>
            <Form>
              <Form.Group className="mb-3">
                <Form.Label>Ticket Description</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={4}
                  value={ticket}
                  onChange={(e) => setTicket(e.target.value)}
                  placeholder="Describe your issue or question"
                />
              </Form.Group>
              <Button variant="primary" onClick={handleTicketSubmission}>Submit Ticket</Button>
            </Form>
          </Col>
        </Row>

        {/* Live Chat Support */}
        <Row className="mt-4">
          <Col>
            <h2>Live Chat Support</h2>
            {/* Implement live chat widget or link */}
          </Col>
        </Row>

        {/* Contact Information */}
        <Row className="mt-4">
          <Col>
            <h2>Contact Us</h2>
            <p>Email: support@deliveryapp.com</p>
            <p>Phone: +123 456 7890</p>
            {/* Additional contact details */}
          </Col>
        </Row>

        {/* Feedback and Suggestions */}
        <Row className="mt-4">
          <Col>
            <h2>Feedback & Suggestions</h2>
            <Form>
              <Form.Group className="mb-3">
                <Form.Label>Your Feedback or Suggestions</Form.Label>
                <Form.Control as="textarea" rows={4} placeholder="Your feedback or suggestions" />
              </Form.Group>
              <Button variant="primary">Submit Feedback</Button>
            </Form>
          </Col>
        </Row>

        {/* Push Notifications Settings */}
        <Row className="mt-4">
          <Col>
            <h2>Notification Settings</h2>
            {/* Implement settings toggles or links */}
          </Col>
        </Row>

        {/* Troubleshooting Tips */}
        <Row className="mt-4">
          <Col>
            <h2>Troubleshooting Tips</h2>
            {/* List common issues and solutions */}
          </Col>
        </Row>
      </Container>
    // </DeliveryLayout>
  );
}

export default DeliveryHelp;
