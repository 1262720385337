import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { projectFirestore, projectStorage } from '../../../firebase/config';
import { collection, addDoc, getDocs, doc, getDoc, updateDoc, deleteDoc } from 'firebase/firestore'; 
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';  

const toolbarOptions = [
    ['bold', 'italic', 'underline', 'strike'],  // toggled buttons
  
    ['blockquote', 'code-block'],  // blocks
  
    [{ 'header': 1 }, { 'header': 2 }, { 'header': [3, 4, 5, 6, false] }], // headers
    
    [{ 'list': 'ordered'}, { 'list': 'bullet' }],  // lists
    
    [{ 'script': 'sub'}, { 'script': 'super' }],  // superscript/subscript
    
    [{ 'indent': '-1'}, { 'indent': '+1' }],  // outdent/indent
    
    [{ 'direction': 'rtl' }],  // text direction
    
    [{ 'size': ['small', false, 'large', 'huge'] }],  // font size
    
    [{ 'header': [1, 2, 3, 4, 5, 6, false] }],  // custom header levels
    
    [{ 'color': [] }, { 'background': [] }],  // text/background color
    
    [{ 'font': [] }],  // font family
    
    [{ 'align': [] }],  // text alignment
    
    ['clean'],  // remove formatting
    
    ['link', 'image', 'video']  // link, image and video
  ];

function AddService() {
    const [serviceName, setServiceName] = useState('');
    const [servicePrice, setServicePrice] = useState('');
    const [serviceDescription, setServiceDescription] = useState('');
    const [serviceImages, setServiceImages] = useState([]);
    const [uploading, setUploading] = useState(false);
    const [selectedServiceId, setSelectedServiceId] = useState('');
    const [existingServices, setExistingServices] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const navigate = useNavigate();
    const [cleaningType, setCleaningType] = useState('');


    useEffect(() => {
      const fetchServices = async () => {
        const querySnapshot = await getDocs(collection(projectFirestore, "cleaningServices"));
        const servicesList = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setExistingServices(servicesList);
      };
    
      fetchServices();
    }, []);
    
    // Function to handle image upload and return URLs
    const uploadServiceImages = async () => {
      try {
          const uploadPromises = serviceImages.map(async (imageObj) => {
              const storageRef = ref(projectStorage, `cleaningServices/${imageObj.file.name}`);
              const uploadTaskSnapshot = await uploadBytes(storageRef, imageObj.file);
              return getDownloadURL(uploadTaskSnapshot.ref);
          });
  
          const imageUrls = await Promise.all(uploadPromises);
          return imageUrls;
      } catch (error) {
          console.error('Error uploading images:', error);
          // Handle error, e.g., set an error message in your component's state
          setError('Error uploading images. Please try again.');
          return []; // return an empty array or handle this case appropriately
      }
  };
  

  // Function to reset form fields and redirect
  const resetFormAndRedirect = () => {
    resetFormFields();
    navigate('/add-service'); // Redirect to /add-service
  };


const handleSubmit = async (e) => {
  e.preventDefault();
  setUploading(true); // start uploading state

  try {
      const parsedPrice = parseFloat(servicePrice);
      if (!isNaN(parsedPrice)) {
          const imageUrls = await uploadServiceImages();

          const serviceData = {
              name: serviceName, // Keep the name in Title Case
              price: parsedPrice,
              description: serviceDescription,
              images: imageUrls,
              cleaningType,
          };

          const docRef = await addDoc(collection(projectFirestore, 'cleaningServices'), serviceData);
          console.log("Document written with ID: ", docRef.id); // Log the new document ID

          resetFormAndRedirect();
      } else {
          console.error('Invalid price entered');
          setError('Invalid price. Please enter a valid number.');
      }
  } catch (error) {
      console.error('Error processing service data:', error);
      setError('Error processing service data. Please try again.');
  } finally {
      setUploading(false); // stop uploading state
  }
};

const handleUpdate = async () => {
  setUploading(true); // start uploading state

  try {
      const parsedPrice = parseFloat(servicePrice);
      if (!isNaN(parsedPrice) && cleaningType) {
          const serviceRef = doc(projectFirestore, 'cleaningServices', selectedServiceId);
          const updateData = {
              name: serviceName, // Keep the name in Title Case
              price: parsedPrice,
              description: serviceDescription,
              cleaningType, // Include the cleaningType in the update
          };

          // Only upload images if new images have been added
          if (serviceImages.length > 0) {
              const imageUrls = await uploadServiceImages();
              updateData.images = imageUrls;
          }

          // If the document ID is not in the existing data, add it
          const docSnap = await getDoc(serviceRef);
          if (!docSnap.exists() || !docSnap.data().id) {
              updateData.id = selectedServiceId;
          }

          await updateDoc(serviceRef, updateData);
          console.log("Document updated with ID: ", selectedServiceId); // Log the updated document ID

          resetFormAndRedirect();
      } else {
          console.error('Invalid price entered');
          setError('Invalid price. Please enter a valid number.');
      }
  } catch (error) {
      console.error('Error updating service:', error);
      setError('Error updating service. Please try again.');
  } finally {
      setUploading(false); // stop uploading state
  }
};

        // This function will populate the form with the data of the selected service
    const handleServiceSelect = (serviceId) => {
      setSelectedServiceId(serviceId);
      const service = existingServices.find(service => service.id === serviceId);
      if (service) {
        setServiceName(service.name);
        setServicePrice(service.price.toString());
        setServiceDescription(service.description);
        setCleaningType(service.cleaningType); // Set cleaning type
        setSelectedServiceId(serviceId);
        // Handle service images if needed
      }
    };


    const handleDelete = async (serviceId) => {
      const serviceRef = doc(projectFirestore, 'cleaningServices', serviceId);
      await deleteDoc(serviceRef);
      // Update existingServices state to reflect the deletion
      setExistingServices(existingServices.filter(service => service.id !== serviceId));
    };

    

    const handleImageChange = (e) => {
        const filesArray = Array.from(e.target.files).map((file) => ({
          file,
          preview: URL.createObjectURL(file),
        }));
    
        setServiceImages((prevImages) => [...prevImages, ...filesArray]);
      };
    
    //   const handleImageUpload = async () => {
    //     setUploading(true);
    //     const uploadPromises = serviceImages.map(async (imageObj) => {
    //       const storageRef = ref(projectStorage, `cleaningServices/${imageObj.file.name}`);
    //       const uploadTaskSnapshot = await uploadBytes(storageRef, imageObj.file);
    //       const downloadUrl = await getDownloadURL(uploadTaskSnapshot.ref);
    //       return downloadUrl;
    //     });
    
    //     const imageUrls = await Promise.all(uploadPromises);
    
    //     const serviceDocRef = await addDoc(collection(projectFirestore, 'cleaningServices'), {
    //       name: serviceName,
    //       price: servicePrice,
    //       description: serviceDescription,
    //       images: imageUrls,
    //     });
    
    //     setServiceName('');
    //     setServicePrice('');
    //     setServiceDescription('');
    //     setServiceImages([]);
    //     setUploading(false);
    //   };
    
      
      // Function to reset form fields after submission
      const resetFormFields = () => {
        setServiceName('');
        setServicePrice('');
        setServiceDescription('');
        setServiceImages([]);
        setCleaningType(''); // Reset cleaning type
        setSelectedServiceId('');
        setUploading(false);
      };
      

      return (
        <div className="container mt-4">
            {/* Display loading indicator and error messages */}
            {loading && <p>Loading...</p>}
            {error && <p className="text-danger">{error}</p>}
            <h2 className="mb-4 text-center">Add or Update a Service</h2>
            {/* Service Selection Dropdown and Action Buttons */}
            <div className="d-flex justify-content-between mb-4">
                <div className="flex-grow-1 me-2">
                    <select className="form-select" onChange={(e) => handleServiceSelect(e.target.value)} value={selectedServiceId}>
                        <option value="">Select a Service to Update</option>
                        {existingServices.map((service) => (
                            <option key={service.id} value={service.id}>{service.name}</option>
                        ))}
                    </select>
                </div>
                <button className="btn btn-primary me-2" onClick={handleUpdate}>Update Service</button>
                <button className="btn btn-danger" onClick={() => handleDelete(selectedServiceId)}>Delete Service</button>
            </div>

            <h3 className="mb-4">{selectedServiceId ? 'Edit Service' : 'Add a New Service'}</h3>

            {/* Service Form for Adding/Updating Service */}
            <form onSubmit={handleSubmit}>
                <div className="mb-3">
                    <label htmlFor="serviceName" className="form-label">Service Name</label>
                    <input
                        type="text"
                        className="form-control"
                        id="serviceName"
                        placeholder="Service Name"
                        value={serviceName}
                        onChange={(e) => setServiceName(e.target.value)}
                    />
                </div>
                <div className="mb-3">
                    <label htmlFor="servicePrice" className="form-label">Service Price</label>
                    <input
                        type="text"
                        className="form-control"
                        id="servicePrice"
                        placeholder="Service Price"
                        value={servicePrice}
                        onChange={(e) => setServicePrice(e.target.value)}
                    />
                </div>
                <div className="mb-3">
                    <label htmlFor="cleaningType" className="form-label">Cleaning Type</label>
                    <select
                        className="form-select"
                        id="cleaningType"
                        value={cleaningType}
                        onChange={(e) => setCleaningType(e.target.value)}
                    >
                        <option value="">Select Cleaning Type</option>
                        <option value="Domestic/Home">Domestic/Home</option>
                        <option value="Business/Commercial">Business/Commercial</option>
                        <option value="Industry">Industry</option>
                        <option value="Specialised">Specialised</option>
                    </select>
                </div>

                <div className="mb-3">
                    <label htmlFor="serviceDescription" className="form-label">Service Description</label>
                    <ReactQuill
                        theme="snow"
                        value={serviceDescription}
                        onChange={setServiceDescription}
                        placeholder="Service Description"
                        className="mb-3"
                    />
                </div>
                <div className="mb-3">
                    <label htmlFor="serviceImages" className="form-label">Service Images</label>
                    <input type="file" className="form-control" id="serviceImages" multiple onChange={handleImageChange} />
                </div>
                <button type="submit" className="btn btn-success">{selectedServiceId ? 'Update' : 'Add'} Service</button>
            </form>

            {/* Image Previews */}
            <div className="mt-3">
                {serviceImages.map((image, index) => (
                    <img key={index} src={image.preview} alt={image.file.name} style={{ width: '100px', height: 'auto', marginRight: '10px' }} />
                ))}
            </div>
        </div>
    );
}

export default AddService;
    


  