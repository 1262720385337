import React, { useState, useContext, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { UserContext } from "../../contexts/UserContext";
import { projectFirestore, projectStorage } from "../../../firebase/config";
import {
  getFirestore,
  doc,
  getDoc,
  updateDoc,
  addDoc,
  setDoc,
  collection,
  serverTimestamp,
} from "firebase/firestore";
import fetchGeolocation from "../../utils/fetchGeolocation";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import TransportDropdown from "../../AllGDeliveries/TransportTypes/TransportDropdown";
import DeliveryDropdown from "./DeliveryDropdown";

import { Form, Button, Card, Row, Col } from "react-bootstrap";

export default function DeliveryPartnerOnboarding({ partner }) {
  const { currentUser } = useContext(UserContext);
  const { partnerId } = useParams();

  const navigate = useNavigate();
  const firestore = getFirestore();
  const storage = getStorage();
  const [partnerType, setPartnerType] = useState("Individual");
  const [partnershipType, setPartnershipType] = useState([]);
  const [experience, setExperience] = useState("");
  const [fullBusinessName, setFullBusinessName] = useState("");
  const [partnerData, setPartnerData] = useState({});
  const [depotAndLocationData, setDepotAndLocationData] = useState([]);
  const [geolocation, setGeolocation] = useState({
    latitude: "",
    longitude: "",
  });

  const [error, setError] = useState("");
  const [selectedDeliveryCategory, setSelectedDeliveryCategory] = useState([]);
  const [selectedDeliverySubcategory, setSelectedDeliverySubcategory] =
    useState([]);
  const [
    selectedDeliveryCategoryAttributes,
    setSelectedDeliveryCategoryAttributes,
  ] = useState({});
  const [
    selectedDeliverySubCategoryAttributes,
    setSelectedDeliverySubCategoryAttributes,
  ] = useState({});

  const [selectedTransportCategory, setSelectedTransportCategory] =
    useState("");
  const [selectedTransportSubcategory, setSelectedTransportSubcategory] =
    useState("");
  const [
    selectedTransportCategoryAttributes,
    setSelectedTransportCategoryAttributes,
  ] = useState({});

  const [
    selectedTransportSubCategoryAttributes,
    setSelectedTransportSubCategoryAttributes,
  ] = useState({});

  const [availability, setAvailability] = useState("");

  const [personalIdentification, setPersonalIdentification] = useState(null);
  const [personalIdentificationPreview, setPersonalIdentificationPreview] =
    useState("");
  const [backgroundCheckReport, setBackgroundCheckReport] = useState(null);
  const [backgroundCheckReportPreview, setBackgroundCheckReportPreview] =
    useState("");
  const [miscellaneousUpload, setMiscellaneousUpload] = useState(null);
  const [miscellaneousUploadPreview, setMiscellaneousUploadPreview] =
    useState("");
  const [insuranceInfo, setInsuranceInfo] = useState("");
  // const [employees, setEmployees] = useState([
  //   { name: "", email: "", phone: "" },
  // ]);
  const [editData, setEditData] = useState(null);
  const [paymentDetails, setPaymentDetails] = useState({
    bankAccount: "",
    mobileAccount: "",
    preferredPaymentMethod: "",
  });
  const [taxInformation, setTaxInformation] = useState("");
  const [businessDocuments, setBusinessDocuments] = useState({
    hasRegistrationCertificate: "",
    hasTaxIdentificationNumber: "",
  });
  const [operationalInfo, setOperationalInfo] = useState("");
  const [contactInfo, setContactInfo] = useState({
    email: currentUser?.email || "",
    phone: currentUser?.phoneNumber || "",
  });

  const [transportType, setTransportType] = useState({
    categories: [],
    subcategories: [],
  });

  const [deliveryType, setDeliveryType] = useState({
    categories: [],
    subcategories: [],
  });

  const docRef = partnerId
    ? doc(projectFirestore, "deliveryPartner", partnerId)
    : null;

  const [workers, setWorkers] = useState([]);

  const [formData, setFormData] = useState({
    fullBusinessName: "",
    partnerType: "Individual",
    personalIdentification: null,
    backgroundCheckReport: null,
    miscellaneousUpload: null,
    partnershipType: [],
    deliveryType: {
      categories: [],
      subcategories: [],
    },
    transportType: {
      categories: [],
      subcategories: [],
    },
    workers: [],
    experience: "",
    availability: "",
    contactInfo: {
      email: "",
      phone: "",
    },
    taxInformation: "",
    paymentDetails: {
      bankAccount: "",
      mobileAccount: "",
      preferredPaymentMethod: "Bank",
    },
    businessDocuments: {
      hasRegistrationCertificate: "",
      hasTaxIdentificationNumber: "",
      // Add other business document fields here
    },
    geolocation,
    operationalInfo: "",
    // Add depotAndLocationData here
    depotAndLocationData: [],
  });

  // const handleFetchGeolocation = () => {
  //   fetchGeolocation(
  //     (location) => {
  //       // Update geolocation state
  //       setGeolocation(location);
  //       // Update formData state for latitude and longitude
  //       setFormData((prevFormData) => ({
  //         ...prevFormData,
  //         geolocation: location,
  //       }));
  //     },
  //     (errorMessage) => setError(errorMessage)
  //   );
  // };

  const handleFetchGeolocation = (index) => {
    fetchGeolocation(
      (location) => {
        // Update geolocation state for the specific location index
        handleLocationDataChange(index, "latitude", location.latitude);
        handleLocationDataChange(index, "longitude", location.longitude);

        // Optionally, update the overall geolocation state
        setGeolocation(location);
      },
      (errorMessage) => setError(errorMessage)
    );
  };

  // const handleInputChange = (e) => {
  //   const { name, value } = e.target;
  //   if (name === "latitude" || name === "longitude") {
  //     // If the input name is latitude or longitude, update the geolocation state
  //     setGeolocation((prevGeolocation) => ({
  //       ...prevGeolocation,
  //       [name]: value,
  //     }));
  //   } else {
  //     // For other inputs, update the formData directly
  //     setFormData((prevFormData) => ({
  //       ...prevFormData,
  //       [name]: value,
  //     }));
  //   }
  // };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "latitude" || name === "longitude") {
      // If the input name is latitude or longitude, update the geolocation state
      setGeolocation((prevGeolocation) => ({
        ...prevGeolocation,
        [name]: value,
      }));
    } else if (name === "geolocation" && value === "fetch") {
      // Trigger fetch geolocation action
      handleFetchGeolocation();
    } else {
      // For other inputs, update the formData directly
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value,
      }));
    }
  };

  const handleAddWorker = (newWorker) => {
    setWorkers([...workers, newWorker]);
  };

  const handleRemoveWorker = (index) => {
    setWorkers(workers.filter((_, i) => i !== index));
  };

  const handleWorkerChange = (index, field, value) => {
    const updatedWorkers = [...workers];
    updatedWorkers[index][field] = value;
    setWorkers(updatedWorkers);
  };

  // Define fetchPartnerData globally or move it outside the useEffect hook
  // const fetchPartnerData = async () => {
  //   try {
  //     const partnerRef = doc(projectFirestore, "deliveryPartner", partnerId);
  //     const partnerSnapshot = await getDoc(partnerRef);
  //     if (partnerSnapshot.exists()) {
  //       setPartnerData({ id: partnerSnapshot.id, ...partnerSnapshot.data() });
  //       if (partnerSnapshot.data().DepotAndLocationData) {
  //         setDepotAndLocationData([
  //           ...partnerSnapshot.data().DepotAndLocationData,
  //         ]);
  //       } else {
  //         setDepotAndLocationData([]);
  //       }
  //     } else {
  //       setPartnerData(null);
  //       setDepotAndLocationData([]);
  //     }
  //   } catch (error) {
  //     console.error("Error fetching delivery partner data:", error);
  //   }
  // };

  const fetchPartnerData = async () => {
    try {
      if (!partnerId) {
        console.error("Partner ID is undefined");
        return;
      }

      const partnerRef = doc(projectFirestore, "deliveryPartner", partnerId);
      const partnerSnapshot = await getDoc(partnerRef);

      if (partnerSnapshot.exists()) {
        setPartnerData({ id: partnerSnapshot.id, ...partnerSnapshot.data() });

        if (partnerSnapshot.data().DepotAndLocationData) {
          setDepotAndLocationData([
            ...partnerSnapshot.data().DepotAndLocationData,
          ]);
        } else {
          setDepotAndLocationData([]);
        }
      } else {
        console.error("Partner does not exist");
        // Handle case where partner does not exist
      }
    } catch (error) {
      console.error("Error fetching delivery partner data:", error);
    }
  };

  // Update the fetchPartnerData call inside the useEffect hook
  useEffect(() => {
    fetchPartnerData();
  }, [partnerId, geolocation]);

  useEffect(() => {
    if (partnerData) {
      setFullBusinessName(partnerData.fullBusinessName || "");
      setPartnerType(partnerData.partnerType || "Individual");
      setPersonalIdentification(partnerData.personalIdentification || null);
      setBackgroundCheckReport(partnerData.backgroundCheckReport || null);
      setMiscellaneousUpload(partnerData.miscellaneousUpload || null);
      setPartnershipType(partnerData.partnershipType || []);
      setDeliveryType(
        partnerData.deliveryType || { categories: [], subcategories: [] }
      );
      setTransportType(
        partnerData.transportType || { categories: [], subcategories: [] }
      );

      setExperience(partnerData.experience || "");
      setAvailability(partnerData.availability || "");
      setTaxInformation(partnerData.taxInformation || "");
      setPaymentDetails({
        bankAccount: partnerData.paymentDetails?.bankAccount || "",
        mobileAccount: partnerData.paymentDetails?.mobileAccount || "",
        preferredPaymentMethod:
          partnerData.paymentDetails?.preferredPaymentMethod || "Bank",
      });

      setContactInfo({
        email: partnerData.contactInfo?.email || "",
        phone: partnerData.contactInfo?.phone || "",
      });
      setInsuranceInfo(partnerData.insuranceInfo || "");
      // setEmployees(
      //   partnerData.employees || [{ name: "", email: "", phone: "" }]
      // );
      setBusinessDocuments({
        hasRegistrationCertificate:
          partnerData.businessDocuments?.hasRegistrationCertificate || "",
        hasTaxIdentificationNumber:
          partnerData.businessDocuments?.hasTaxIdentificationNumber || "",
      });
      setOperationalInfo(partnerData.operationalInfo || "");
      // Populate other form fields similarly

      // Update delivery categories and subcategories
      setSelectedDeliveryCategory(partnerData.deliveryCategories || []);
      setSelectedDeliverySubcategory(partnerData.deliverySubcategories || []);

      // Update delivery attributes
      setSelectedDeliveryCategoryAttributes({
        additionalOptions:
          partnerData.selectedDeliveryAttributes?.additionalOptions || [],
        additionalServices:
          partnerData.selectedDeliveryAttributes?.additionalServices || [],
        shippingSpeed:
          partnerData.selectedDeliveryAttributes?.shippingSpeed || [],
        // Update other attributes similarly
      });

      // Update transport categories and subcategories
      setSelectedTransportCategory(partnerData.transportCategories || []);
      setSelectedTransportSubcategory(partnerData.transportSubcategories || []);

      // Update transport attributes
      setSelectedTransportCategoryAttributes({
        accessibility:
          partnerData.selectedTransportAttributes?.accessibility || [],
        deliveryRange:
          partnerData.selectedTransportAttributes?.deliveryRange || [],
        specialHandling:
          partnerData.selectedTransportAttributes?.specialHandling || [],
        specialization:
          partnerData.selectedTransportAttributes?.specialization || [],
        // Update other attributes similarly
      });
      // // Handle nested array data
      if (partnerData.workerData) {
        const workers = partnerData.workerData.map((worker) => ({
          fullName: worker.fullName || "",
          email: worker.email || "",
          mobileNumber: worker.mobileNumber || "",
        }));
        setWorkers(workers);
      } else {
        setWorkers([]);
      }
    }
  }, [partnerData]);

  // Function to handle changes in depot and location data
  // const handleLocationDataChange = (index, field, value) => {
  //   const updatedData = [...depotAndLocationData];
  //   updatedData[index][field] = value;
  //   setDepotAndLocationData(updatedData);
  // };

  const handleLocationDataChange = (index, field, value) => {
    // Ensure that the location object at the given index exists
    if (depotAndLocationData[index]) {
      // Initialize the geolocation object if it's not already initialized
      if (!depotAndLocationData[index].geolocation) {
        depotAndLocationData[index].geolocation = {};
      }
      // Set the value of the specified field for the location at the given index
      depotAndLocationData[index][field] = value;
      // If the field is latitude or longitude, update the geolocation object
      if (field === "latitude" || field === "longitude") {
        depotAndLocationData[index].geolocation[field] = value;
      }
      // Update the state with the modified depotAndLocationData array
      setDepotAndLocationData([...depotAndLocationData]);
    }
  };

  // Function to add a new empty location entry
  const addNewLocationEntry = () => {
    setDepotAndLocationData([
      ...depotAndLocationData,
      {
        name: "",
        address: "",
        postcode: "",
        city: "",
        geolocation: { latitude: "", longitude: "" },
        mobileNumber: "",
        email: "",
        // Add other fields here
      },
    ]);
  };

  // Function to remove a location entry
  const removeLocationEntry = (index) => {
    const updatedData = [...depotAndLocationData];
    updatedData.splice(index, 1);
    setDepotAndLocationData(updatedData);
  };

  // Function to update the partner data with the modified depot and location data
  const handleUpdate = async () => {
    try {
      if (partnerData) {
        const partnerRef = doc(
          projectFirestore,
          "deliveryPartner",
          partnerData.id
        );
        await updateDoc(partnerRef, {
          ...partnerData,
          DepotAndLocationData: depotAndLocationData,
        });
        fetchPartnerData(); // Refresh partner data after update
      }
    } catch (error) {
      console.error("Error updating partner data:", error);
    }
  };

  const handleSave = async () => {
    try {
      const payload = {
        /* form data */
      };
      if (partnerId) {
        await updateDoc(docRef, payload);
      } else {
        await addDoc(collection(projectFirestore, "deliveryPartner"), payload);
      }
      navigate("/delivery-partner-list");
    } catch (error) {
      console.error("Error saving delivery partner:", error);
    }
  };

  const handleArchive = async () => {
    try {
      await updateDoc(docRef, { archived: true });
      navigate("/delivery-partner-list");
    } catch (error) {
      console.error("Error archiving delivery partner:", error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Combine selectedDeliveryCategoryAttributes and selectedDeliverySubCategoryAttributes into one object
    const selectedDeliveryAttributes = {
      ...selectedDeliveryCategoryAttributes,
      ...selectedDeliverySubCategoryAttributes,
    };

    // Combine selectedTransportCategoryAttributes and selectedTransportSubCategoryAttributes into one object
    const selectedTransportAttributes = {
      ...selectedTransportCategoryAttributes,
      ...selectedTransportSubCategoryAttributes,
    };

    // Check if currentUser exists
    if (!currentUser) {
      console.error("Current user not found.");
      return;
    }

    // Create a new array to store worker data
    const workerData = workers.map((worker) => ({
      fullName: worker.fullName,
      mobileNumber: worker.mobileNumber,
      email: worker.email,
    }));

    // console.log("Formatted worker data:", workerData);

    // Assign status as "On-Hold"
    const status = "On-Hold";

    try {
      const personalIdUrl = personalIdentification
        ? await uploadFile(
            personalIdentification,
            "personal_identification/" + personalIdentification.name
          )
        : null;
      const backgroundCheckUrl = backgroundCheckReport
        ? await uploadFile(
            backgroundCheckReport,
            "background_checks/" + backgroundCheckReport.name
          )
        : null;
      const miscellaneousUrl = miscellaneousUpload
        ? await uploadFile(
            miscellaneousUpload,
            "miscellaneous_upload/" + miscellaneousUpload.name
          )
        : null;

      // const selectedVehicleTypes = getSelectedVehicleTypes();

      // Initialize formData here
      const formData = {
        userId: currentUser.uid,
        createdAt: serverTimestamp(),
        partnerType,
        // vehicleTypes: selectedVehicleTypes,
        deliveryCategories: selectedDeliveryCategory,
        deliverySubcategories: selectedDeliverySubcategory,
        selectedDeliveryAttributes, // Include selected attributes
        transportCategories: selectedTransportCategory,
        transportSubcategories: selectedTransportSubcategory,
        selectedTransportAttributes, // Include selected attributes
        workerData,
        geolocation: {
          latitude: geolocation.latitude,
          longitude: geolocation.longitude,
        },
        partnershipType,
        status, // Assign status
        fullBusinessName, // Include fullBusinessName for both partner types
        experience, // Include experience for both partner types
        availability, // Include availability for both partner types
        personalIdentificationUrl: personalIdUrl, // Include personalIdentificationUrl for both partner types
        backgroundCheckReportUrl: backgroundCheckUrl, // Include backgroundCheckReportUrl for both partner types
        miscellaneousUploadUrl: miscellaneousUrl, // Include miscellaneousUploadUrl for both partner types
        insuranceInfo, // Include insuranceInfo for both partner types
        // employees: employees.filter(
        //   (emp) => emp.name || emp.email || emp.phone
        // ), // Include employees for both partner types
        hasRegistrationCertificate:
          businessDocuments.hasRegistrationCertificate, // Include hasRegistrationCertificate for both partner types
        hasTaxIdentificationNumber:
          businessDocuments.hasTaxIdentificationNumber, // Include hasTaxIdentificationNumber for both partner types
        operationalInfo, // Include operationalInfo for both partner types
        contactInfo, // Include contactInfo for both partner types
        taxInformation, // Include taxInformation for both partner types
        paymentDetails, // Include paymentDetails for both partner types
        depotAndLocationData: depotAndLocationData.map((depot) => ({
          ...depot,
          geolocation: {
            latitude: depot.geolocation.latitude,
            longitude: depot.geolocation.longitude,
          },
        })),
      };

      console.log("FormData:", formData);

      // Save formData to Firestore
      const deliveryPartnerRef = doc(
        projectFirestore,
        "deliveryPartner",
        currentUser.uid
      );
      await setDoc(deliveryPartnerRef, formData, { merge: true });

      console.log("Delivery partner details saved successfully.");

      navigate("/delivery-partner-admin-manager");
    } catch (error) {
      console.error("Error saving delivery partner details:", error);
      alert(
        "An error occurred while saving your information. Please try again."
      );
    }
  };

  const handleTypeChange = (value) => {
    if (partnershipType.includes(value)) {
      // If the selected value is already included, remove it
      setPartnershipType(partnershipType.filter((type) => type !== value));
    } else {
      // Otherwise, clear the current partnershipType and set the selected value
      setPartnershipType([value]);
    }
  };
  const handleContactInfoChange = (e) => {
    const { name, value } = e.target;
    setContactInfo((prevInfo) => ({
      ...prevInfo,
      [name]: value,
    }));
  };

  const handlePaymentDetailsChange = (e) => {
    const { name, value } = e.target;
    setPaymentDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
  };

  const handleBusinessDocumentsChange = (e) => {
    const { name, value } = e; // Change this line
    setBusinessDocuments((prevDocs) => ({
      ...prevDocs,
      [name]: value,
    }));
  };

  const handleFileChange = (e, setFile, setPreview) => {
    const file = e.target.files[0];
    if (file) {
      setFile(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleInsuranceChange = (e) => {
    setInsuranceInfo(e.target.value);
  };

  const uploadFile = async (file, path) => {
    if (!file) return;

    const storageRef = ref(projectStorage, path);
    await uploadBytes(storageRef, file);
    return await getDownloadURL(storageRef);
  };

  // Function to handle edit
  const handleEdit = (data) => {
    setEditData(data);
  };

  // Function to cancel edit
  const handleCancelEdit = () => {
    setEditData(null);
  };

  // Function to fetch user's current geolocation
  const fetchUserGeolocation = () => {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        const latitude = position.coords.latitude;
        const longitude = position.coords.longitude;
        setGeolocation({ latitude, longitude });
      },
      (error) => {
        console.error("Error fetching geolocation:", error);
      }
    );
  };

  // Function to handle input change for geolocation
  const handleGeolocationChange = (key, value) => {
    setGeolocation({
      ...geolocation,
      [key]: value,
    });
  };
  // Function to handle saving geolocation for a specific depot

  return (
    <div className="container">
      <Form onSubmit={handleSubmit}>
        <h2>Delivery Partner Onboarding</h2>
        <p>
          The process of onboarding a partner to become a Delivery partner for
          Boameooo. Complete the form processing and we will provide you with
          feedback in a few days.
        </p>
        <Form.Label>
          <span className="fw-bold">Full Business Name:</span>
        </Form.Label>
        <Form.Control
          type="text"
          value={fullBusinessName}
          onChange={(e) => setFullBusinessName(e.target.value)}
          placeholder="Enter Your Full Name or Full Business Name"
          required
        />

        <Form.Label>
          <span className="fw-bold">Partner Type:</span>
        </Form.Label>
        <Form.Select
          value={partnerType}
          onChange={(e) => setPartnerType(e.target.value)}
          required
        >
          <option value="Individual">Individual</option>
          <option value="Business">Business</option>
        </Form.Select>

        <Form.Label>
          <span className="fw-bold">Has Registration Certificate:</span>
        </Form.Label>
        <Form.Select
          value={businessDocuments.hasRegistrationCertificate}
          onChange={(e) =>
            handleBusinessDocumentsChange({
              name: "hasRegistrationCertificate",
              value: e.target.value,
            })
          }
          required
        >
          <option value="Yes">Yes</option>
          <option value="No">No</option>
        </Form.Select>
        <Form.Label>
          <span className="fw-bold">Has Tax Identification Number:</span>
        </Form.Label>
        <Form.Select
          value={businessDocuments.hasTaxIdentificationNumber}
          onChange={(e) =>
            handleBusinessDocumentsChange({
              name: "hasTaxIdentificationNumber",
              value: e.target.value,
            })
          }
          required
        >
          <option value="Yes">Yes</option>
          <option value="No">No</option>
        </Form.Select>

        <Form.Label>
          <span className="fw-bold">Ecowas Identity Card:</span>
        </Form.Label>
        <Form.Control
          type="file"
          onChange={(e) =>
            handleFileChange(
              e,
              setPersonalIdentification,
              setPersonalIdentificationPreview
            )
          }
        />
        {personalIdentification && (
          <img
            src={URL.createObjectURL(personalIdentification)}
            alt="Preview"
          />
        )}

        <Form.Label>
          <span className="fw-bold">Police Background Check Report:</span>
        </Form.Label>
        <Form.Control
          type="file"
          onChange={(e) =>
            handleFileChange(
              e,
              setBackgroundCheckReport,
              setBackgroundCheckReportPreview
            )
          }
        />
        {backgroundCheckReport && (
          <img src={URL.createObjectURL(backgroundCheckReport)} alt="Preview" />
        )}

        <Form.Label>
          <span className="fw-bold">Miscellaneous Report:</span>
        </Form.Label>
        <Form.Control
          type="file"
          onChange={(e) =>
            handleFileChange(
              e,
              setMiscellaneousUpload,
              setMiscellaneousUploadPreview
            )
          }
        />
        {miscellaneousUpload && (
          <img src={URL.createObjectURL(miscellaneousUpload)} alt="Preview" />
        )}

        <Form.Label>
          <span className="fw-bold">Partnership Type:</span>
        </Form.Label>
        <Form.Group>
          <Form.Check
            type="checkbox"
            label="Delivery Partner (Mobile)"
            value="delivery"
            checked={partnershipType.includes("delivery")}
            onChange={() => handleTypeChange("delivery")}
          />
          <Form.Check
            type="checkbox"
            label="Pickup/DropOff Location Partner"
            value="pickup-dropoff"
            checked={partnershipType.includes("pickup-dropoff")}
            onChange={() => handleTypeChange("pickup-dropoff")}
          />
        </Form.Group>

        <DeliveryDropdown
          // Pass selected category and subcategory data and callback functions to update state
          selectedDeliveryCategory={selectedDeliveryCategory}
          selectedDeliverySubcategory={selectedDeliverySubcategory}
          selectedDeliveryCategoryAttributes={
            selectedDeliveryCategoryAttributes
          }
          selectedDeliverySubCategoryAttributes={
            selectedDeliverySubCategoryAttributes
          }
          setSelectedDeliveryCategory={setSelectedDeliveryCategory}
          setSelectedDeliverySubcategory={setSelectedDeliverySubcategory}
          setSelectedDeliveryCategoryAttributes={
            setSelectedDeliveryCategoryAttributes
          }
          setSelectedDeliverySubCategoryAttributes={
            setSelectedDeliverySubCategoryAttributes
          }
          // onLog={(message) => console.log("Delivery Dropdown:", message)} // Log changes in DeliveryDropdown
        />

        <TransportDropdown
          // Pass selected category and subcategory data and callback functions to update state
          selectedTransportCategory={selectedTransportCategory}
          selectedTransportSubcategory={selectedTransportSubcategory}
          selectedTransportCategoryAttributes={
            selectedTransportCategoryAttributes
          }
          selectedTransportSubCategoryAttributes={
            selectedTransportSubCategoryAttributes
          }
          setSelectedTransportCategory={setSelectedTransportCategory}
          setSelectedTransportSubcategory={setSelectedTransportSubcategory}
          setSelectedTransportCategoryAttributes={
            setSelectedTransportCategoryAttributes
          }
          setSelectedTransportSubCategoryAttributes={
            setSelectedTransportSubCategoryAttributes
          }
          onLog={(message) => console.log("Transport Dropdown:", message)} // Log changes in TransportDropdown
        />

        {/* Render WorkerForm component */}
        <div className="container">
          <h2>Enter Your Dispatch Riders or Drivers</h2>
          <Card>
            <Card.Body>
              {workers.map((worker, index) => (
                <Card key={index} className="mb-3">
                  <Card.Body>
                    <Card.Title>Worker {index + 1}</Card.Title>
                    <Form.Group controlId={`fullName${index}`}>
                      <Form.Label>Full Name</Form.Label>
                      <Form.Control
                        type="text"
                        value={worker.fullName}
                        onChange={(e) =>
                          handleWorkerChange(index, "fullName", e.target.value)
                        }
                      />
                    </Form.Group>
                    <Form.Group controlId={`mobileNumber${index}`}>
                      <Form.Label>Mobile Number</Form.Label>
                      <Form.Control
                        type="text"
                        value={worker.mobileNumber}
                        onChange={(e) =>
                          handleWorkerChange(
                            index,
                            "mobileNumber",
                            e.target.value
                          )
                        }
                      />
                    </Form.Group>
                    <Form.Group controlId={`email${index}`}>
                      <Form.Label>Email</Form.Label>
                      <Form.Control
                        type="email"
                        value={worker.email}
                        onChange={(e) =>
                          handleWorkerChange(index, "email", e.target.value)
                        }
                      />
                    </Form.Group>
                    <Button
                      variant="danger"
                      onClick={() => handleRemoveWorker(index)}
                    >
                      Remove
                    </Button>
                  </Card.Body>
                </Card>
              ))}
            </Card.Body>
          </Card>

          <h2>Add Worker</h2>
          <Button onClick={handleAddWorker}>Add Worker</Button>
        </div>

        {/* Geolocation Button (Trigger fetchGeolocation on click) */}
        <div className="container">
          <h2>To be Deleted Geolocation</h2>
          <div>
            <Button onClick={handleFetchGeolocation}>Fetch Geolocation</Button>
            {error && <p className="text-danger">{error}</p>}

            <Form.Group controlId="latitude">
              <Form.Label>Latitude:</Form.Label>
              <Form.Control
                type="text"
                name="latitude"
                value={geolocation.latitude}
                onChange={handleInputChange}
                placeholder="Enter latitude"
              />
            </Form.Group>
            <Form.Group controlId="longitude">
              <Form.Label>Longitude:</Form.Label>
              <Form.Control
                type="text"
                name="longitude"
                value={geolocation.longitude}
                onChange={handleInputChange}
                placeholder="Enter longitude"
              />
            </Form.Group>
          </div>
        </div>

        <div className="container">
          {/* <h2 className="mt-5">Delivery Partner Admin Manager</h2> */}
          <div>
            <h4>Depot and Location Data</h4>
            {depotAndLocationData.map((location, index) => (
              <div key={index}>
                <Row className="mb-3">
                  <Col>
                    <Form.Label>Location {index + 1}</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Name"
                      value={location.name || ""}
                      onChange={(e) =>
                        handleLocationDataChange(index, "name", e.target.value)
                      }
                    />
                    {/* Add other form fields for address, postcode, city, geolocation, mobileNumber, email */}
                  </Col>

                  <Col>{/* Add partnershipType selection here */}</Col>
                </Row>
                <Row className="mb-3">
                  <Col>
                    <Form.Label>Address</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Address"
                      value={location.address || ""}
                      onChange={(e) =>
                        handleLocationDataChange(
                          index,
                          "address",
                          e.target.value
                        )
                      }
                    />
                  </Col>
                  <Col>
                    <Form.Label>Postcode</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Postcode"
                      value={location.postcode || ""}
                      onChange={(e) =>
                        handleLocationDataChange(
                          index,
                          "postcode",
                          e.target.value
                        )
                      }
                    />
                  </Col>
                  <Col>
                    <Form.Label>City</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="City"
                      value={location.city || ""}
                      onChange={(e) =>
                        handleLocationDataChange(index, "city", e.target.value)
                      }
                    />
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col>
                    <h2>Geolocation for Depot and PickUp/DropOFF</h2>
                    <div>
                      <Button onClick={fetchUserGeolocation}>
                        Fetch Geolocation
                      </Button>
                      <Form.Group controlId="latitude">
                        <Form.Label>Latitude:</Form.Label>
                        <Form.Control
                          type="text"
                          value={geolocation.latitude}
                          onChange={(e) =>
                            handleGeolocationChange("latitude", e.target.value)
                          }
                          placeholder="Enter latitude"
                        />
                      </Form.Group>
                      <Form.Group controlId="longitude">
                        <Form.Label>Longitude:</Form.Label>
                        <Form.Control
                          type="text"
                          value={geolocation.longitude}
                          onChange={(e) =>
                            handleGeolocationChange("longitude", e.target.value)
                          }
                          placeholder="Enter longitude"
                        />
                      </Form.Group>
                    </div>
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col>
                    <Form.Label>Mobile Number</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Mobile Number"
                      value={location.mobileNumber || ""}
                      onChange={(e) =>
                        handleLocationDataChange(
                          index,
                          "mobileNumber",
                          e.target.value
                        )
                      }
                    />
                  </Col>
                  <Col>
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                      type="email"
                      placeholder="Email"
                      value={location.email || ""}
                      onChange={(e) =>
                        handleLocationDataChange(index, "email", e.target.value)
                      }
                    />
                  </Col>
                </Row>

                <Button
                  variant="danger"
                  onClick={() => removeLocationEntry(index)}
                >
                  Remove Location
                </Button>
              </div>
            ))}
            <Button variant="primary" onClick={addNewLocationEntry}>
              Add New Location
            </Button>
            <Button variant="success" onClick={handleUpdate}>
              Update
            </Button>
          </div>
        </div>

        <Form.Label>
          <span className="fw-bold">Experience:</span>
        </Form.Label>
        <Form.Control
          as="textarea"
          value={experience}
          onChange={(e) => setExperience(e.target.value)}
          required
          placeholder="Provide details about your experience in delivery"
        />

        <Form.Label>
          <span className="fw-bold">Availability:</span>
        </Form.Label>
        <Form.Control
          as="textarea"
          value={availability}
          onChange={(e) => setAvailability(e.target.value)}
          required
          placeholder="Provide details about your availability"
        />

        <Form.Label>
          <span className="fw-bold">Insurance Info:</span>
        </Form.Label>
        <Form.Control
          as="textarea"
          value={insuranceInfo}
          onChange={(e) => setInsuranceInfo(e.target.value)}
          required
          placeholder="Provide details about your insurance information"
        />

        <Form.Label>
          <span className="fw-bold">Operational Info:</span>
        </Form.Label>
        <Form.Control
          as="textarea"
          value={operationalInfo}
          onChange={(e) => setOperationalInfo(e.target.value)}
          required
          placeholder="Provide details about your operational information"
        />

        {/* Common Fields for Both Individual and Business */}
        <Form.Label>
          <span className="fw-bold">Email:</span>
        </Form.Label>
        <Form.Control
          type="email"
          name="email"
          value={contactInfo.email || ""}
          onChange={handleContactInfoChange}
          required
          placeholder="Enter Your Email Contact Address"
        />

        <Form.Label>
          <span className="fw-bold">Phone Number:</span>
        </Form.Label>
        <Form.Control
          type="tel"
          name="phone"
          value={contactInfo.phone}
          onChange={handleContactInfoChange}
          required
          placeholder="Enter Your Contact Phone Number"
        />

        <Form.Label>
          <span className="fw-bold">Tax Indentification Number:</span>
        </Form.Label>
        <Form.Control
          type="text"
          name="taxInformation"
          value={taxInformation}
          onChange={(e) => setTaxInformation(e.target.value)}
          placeholder="Enter Your Tax Indentification Number"
        />

        <h3>Payment Details</h3>
        <p>Please fill in both and check mark your preferred payment option:</p>

        <Form.Label>
          <span className="fw-bold">Bank Account Details:</span>
        </Form.Label>
        <Form.Control
          type="text"
          name="bankAccount"
          value={paymentDetails.bankAccount}
          onChange={handlePaymentDetailsChange}
          placeholder="Enter Your Bank Account Details"
        />

        <Form.Label>
          <span className="fw-bold">Mobile Account Number:</span>
        </Form.Label>
        <Form.Control
          type="tel"
          name="mobileAccount"
          value={paymentDetails.mobileAccount}
          onChange={handlePaymentDetailsChange}
          placeholder="Enter Your Momo Account Number"
        />

        <Form.Check
          type="radio"
          label="Bank Account (Preferred)"
          name="preferredPaymentMethod"
          value="bank"
          checked={paymentDetails.preferredPaymentMethod === "bank"}
          onChange={handlePaymentDetailsChange}
        />
        <Form.Check
          type="radio"
          label="Mobile Account (Preferred)"
          name="preferredPaymentMethod"
          value="mobile"
          checked={paymentDetails.preferredPaymentMethod === "mobile"}
          onChange={handlePaymentDetailsChange}
        />

        <Button type="submit">Complete Onboarding</Button>
      </Form>
    </div>
  );
}
