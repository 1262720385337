

import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Container, Row, Col, Card, ListGroup } from 'react-bootstrap';
import { projectFirestore } from '../../firebase/config';
import { getDocs, collection } from 'firebase/firestore';
import { ICON_MAP } from '../utils/iconMap';

// Custom style for the card to control its size and content alignment
const cardStyle = {
  maxWidth: '350px', // This limits the card's maximum width
  margin: '0 auto', // This centers the card in its column
  display: 'flex', // Enables flexbox for children alignment
  flexDirection: 'column', // Stack children vertically
  justifyContent: 'center', // Center children vertically
  alignItems: 'center', // Center children horizontally
  cursor: 'pointer', // Changes cursor to pointer on hover to indicate clickability
};

const AdvertsHomePage = () => {
  const [categories, setCategories] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchCategories = async () => {
      const snapshot = await getDocs(collection(projectFirestore, 'advertcategories'));
      setCategories(snapshot.docs.map(doc => ({ ...doc.data(), id: doc.id })));
    };
    fetchCategories();
  }, []);

  const handleCardClick = (categoryId) => {
    navigate(`/sub-categories-list/${categoryId}`);
  };

  return (
    <>
      <Container className="my-4">
        <Row>
          <Col>
            {/* Search form omitted for brevity */}
          </Col>
        </Row>
      </Container>

      <Container fluid>
        <Row>
          <Col sm={3} className="sidebar-column">
            <ListGroup>
              {categories.map(category => (
                <ListGroup.Item key={category.id} action onClick={() => handleCardClick(category.id)}>
                  {category.name}
                </ListGroup.Item>
              ))}
            </ListGroup>
          </Col>

          <Col sm={9}>
            <Row className="g-4">
              {categories.map(category => (
                <Col key={category.id} xs={6} md={4} lg={3}>
                  <Card className="h-100 shadow-sm" style={cardStyle} onClick={() => handleCardClick(category.id)}>
                    <Card.Body className="d-flex justify-content-center align-items-center" style={{ flex: '1 0 auto' }}>
                      {React.createElement(ICON_MAP[category.icon1] || ICON_MAP['defaultIcon'], {
                        style: { fontSize: '35px', color: '#23843c' }
                      })}
                    </Card.Body>
                    <Card.Body>
                      <Card.Title className="mt-3" style={{ fontSize: '1.1rem' }}>{category.name}</Card.Title>
                    </Card.Body>
                  </Card>
                </Col>
              ))}
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default AdvertsHomePage;

