import React, { useState, useEffect } from 'react';
import { Form, Button, Alert } from 'react-bootstrap';
import scheduler from '../../../assets/daily-schedule-icon.png';
import payment from '../../../assets/money-icon.png';
import ghanaianCedi from '../../../assets/ghana-cedis-icon.png'
import { collection, addDoc, getDocs, getFirestore, serverTimestamp, doc, updateDoc } from 'firebase/firestore';
import { projectFirestore } from '../../../firebase/config'; 
import fetchGeolocation from '../../utils/fetchGeolocation';
import { useUser } from '../../contexts/UserContext';
import PhoneNumberInput from '../../Signup/PhoneNumberInput';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';


const PartnerRegistration = () => {

const { currentUser } = useUser();
const [geolocation, setGeolocation] = useState(null);
const [serviceOptions, setServiceOptions] = useState({});
const [partnerData, setPartnerData] = useState({
    name: '',
    dob: '', // for date of birth
    businessYear: '', // in business since year
    momoNumber: '',
    ghanaCard: '',
    geolocation: '',
    reference: '',
    sortCode: '',
    emergencyContact: '',
    services: [],
    availability: '',
    additionalInfo: '',
});

const [submitted, setSubmitted] = useState(false);
const [error, setError] = useState('');
const [partnerType, setPartnerType] = useState('');

useEffect(() => {
    const fetchServices = async () => {
      try {
        const querySnapshot = await getDocs(collection(projectFirestore, 'cleaningServices'));
        const servicesData = {};
        querySnapshot.forEach((doc) => {
          const service = doc.data();
          if (servicesData[service.cleaningType]) {
            servicesData[service.cleaningType].push(service.name);
          } else {
            servicesData[service.cleaningType] = [service.name];
          }
        });
        setServiceOptions(servicesData);
      } catch (error) {
        console.error('Error fetching services:', error);
        setError('Failed to fetch services');
      }
    };

    fetchServices();
  }, []);



const handleInputChange = (e) => {
    const { name, value } = e.target;
    setPartnerData({ ...partnerData, [name]: value });
};

// const handleServiceChange = (e) => {
//     const { value, checked } = e.target;
//     if (checked) {
//       // Corrected to use setPartnerData and partnerData
//       setPartnerData({ ...partnerData, services: [...partnerData.services, value] });
//     } else {
//       // Corrected to use setPartnerData and partnerData
//       setPartnerData({ ...partnerData, services: partnerData.services.filter(service => service !== value) });
//     }
// };

const handleServiceChange = (e) => {
    const { value, checked } = e.target;
    setPartnerData(prevData => {
      const newServices = prevData.services.filter(s => s.name !== value);
      if (checked) {
        newServices.push({ name: value, price: '' });
      }
      return { ...prevData, services: newServices };
    });
  };
  
const handleFetchGeolocation = () => {
  fetchGeolocation(
    (location) => setGeolocation(location), // Success callback to update geolocation
    (errorMessage) => setError(errorMessage) // Error callback to update error
  );
};
// const fetchGeolocation = () => {
//     if ("geolocation" in navigator) {
//         navigator.geolocation.getCurrentPosition((position) => {
//             setPartnerData({
//                 ...partnerData,
//                 geolocation: `${position.coords.latitude}, ${position.coords.longitude}`,
//                 latitude: position.coords.latitude.toString(),
//                 longitude: position.coords.longitude.toString(),
//             });
//         });
//     } else {
//         setError('Geolocation is not supported by this browser.');
//     }
// };

const handlePriceChange = (service, value) => {
    setPartnerData(prevData => ({
      ...prevData,
      services: prevData.services.map(s =>
        s.name === service ? { ...s, price: value } : s
      ),
    }));
  };


const handleSubmit = async (e) => {
    e.preventDefault();
    try {
        // Assume partner registration data is to be stored under the user's document
        const userRef = doc(projectFirestore, "users", currentUser.uid);
        await updateDoc(userRef, {
            ...partnerData,
            partnerType,
            role: getNewRole(partnerType), // Dynamically set new role based on partner type
            cleaningServiceStatus: "onHold", // Specific to Cleaning Service
        });
        setSubmitted(true);
        setError('');
    } catch (err) {
        setError('Error registering partner: ' + err.message);
        setSubmitted(false);
    }
};

// Function to determine new role based on partner type
const getNewRole = (partnerType) => {
    switch(partnerType) {
        case "Individual (Non registered provider)": return "individual-partner";
        case "Individual (Registered company)": return "individual-biz-partner";
        case "Business (Registered company)": return "business-partner";
        default: return currentUser.role; // Default to current role if not matched
    }
};




  if (submitted) {
    return <Alert variant="success">Partner registration successful!</Alert>;
  }

  return (
    <div className="container mt-5">
      <h2 className="mb-4 text-center">Partner Registration</h2>
      <div className="container my-5">
        <div className="text-center mb-5">
          <h4>Start Earning with AllGhanaians Cleaning Service!</h4>
          <p className="lead">
            Join our network and gain access to numerous jobs in your area. Take
            control of your work-life balance with a flexible schedule and enjoy
            the benefit of direct deposits after job completion. Become a part
            of a community dedicated to quality service and customer
            satisfaction.
          </p>
        </div>
        <div className="row text-center">
          {/* GREAT PAY Card */}
          <div className="col-md-4 mb-4">
            <div className="card h-100">
              <div className="card-body">
                <img
                  src={ghanaianCedi}
                  alt="Great Pay"
                  className="card-img-top"
                  style={{ height: "200px", objectFit: "contain" }}
                />
                <h5 className="card-title">GREAT PAY</h5>
                <p className="card-text">
                  Make great pay, with the opportunity for bonuses and tips on a
                  job well done!
                </p>
              </div>
            </div>
          </div>

          {/* FLEXIBLE SCHEDULE Card */}
          <div className="col-md-4 mb-4">
            <div className="card h-100">
              <div className="card-body">
                <img
                  src={scheduler}
                  alt="Flexible Schedule"
                  className="card-img-top"
                  style={{ height: "200px", objectFit: "contain" }}
                />
                <h5 className="card-title">FLEXIBLE SCHEDULE</h5>
                <p className="card-text">
                  You choose when you want to work and how much. Build a full
                  schedule or simply add a few jobs on the side.
                </p>
              </div>
            </div>
          </div>

          {/* EASY PAYMENTS Card */}
          <div className="col-md-4 mb-4">
            <div className="card h-100">
              <div className="card-body">
                <img
                  src={payment}
                  alt="Easy Payments"
                  className="card-img-top"
                  style={{ height: "200px", objectFit: "contain" }}
                />
                <h5 className="card-title">EASY PAYMENTS</h5>
                <p className="card-text">
                  No more tracking down your customers for payments. Your
                  payments are direct deposited into your bank account soon
                  after the job is complete.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <form onSubmit={handleSubmit} className="row g-3">
        <div className="col-12">
          <fieldset>
            <legend>Partner Type:</legend>
            <div className="form-check">
              <input
                className="form-check-input"
                type="radio"
                name="partnerType"
                id="individualNonRegistered"
                value="Individual (Non registered provider)"
                checked={partnerType === "Individual (Non registered provider)"}
                onChange={(e) => setPartnerType(e.target.value)}
              />
              <label
                className="form-check-label"
                htmlFor="individualNonRegistered"
              >
                Individual (Non registered provider)
              </label>
            </div>
            <div className="form-check">
              <input
                className="form-check-input"
                type="radio"
                name="partnerType"
                id="individualRegistered"
                value="Individual (Registered company)"
                checked={partnerType === "Individual (Registered company)"}
                onChange={(e) => setPartnerType(e.target.value)}
              />
              <label
                className="form-check-label"
                htmlFor="individualRegistered"
              >
                Individual (Registered company)
              </label>
            </div>
            <div className="form-check">
              <input
                className="form-check-input"
                type="radio"
                name="partnerType"
                id="businessRegistered"
                value="Business (Registered company)"
                checked={partnerType === "Business (Registered company)"}
                onChange={(e) => setPartnerType(e.target.value)}
              />
              <label className="form-check-label" htmlFor="businessRegistered">
                Business (Registered company)
              </label>
            </div>
          </fieldset>
        </div>
        <div className="col-md-6">
          <label htmlFor="name" className="form-label">
            Name or Business Name:
          </label>
          <input
            type="text"
            id="name"
            name="name"
            className="form-control"
            value={partnerData.name}
            onChange={handleInputChange}
            required
          />
        </div>
        <div className="col-md-6">
          <label htmlFor="email" className="form-label">
            Email:
          </label>
          <input
            type="email"
            id="email"
            name="email"
            className="form-control"
            value={partnerData.email}
            onChange={handleInputChange}
            required
          />
        </div>
        {/*  For Phone Number */}
        <div className="col-12">
          <label htmlFor="phone" className="form-label">
            Phone Number:
          </label>
          <PhoneNumberInput
            value={partnerData.phone}
            onChange={(value) =>
              setPartnerData({ ...partnerData, phone: value })
            }
          />
        </div>
        <div className="col-12">
          <label htmlFor="address" className="form-label">
            Address:
          </label>
          <input
            type="text"
            id="address"
            name="address"
            className="form-control"
            value={partnerData.address}
            onChange={handleInputChange}
            required
          />
        </div>
        {/*  Conditional rendering for Date of Birth field */}
        {partnerType === "Individual (Non registered provider)" && (
          <div className="col-md-6">
            <label htmlFor="dob" className="form-label">
              Date of Birth:
            </label>
            <input
              type="date"
              id="dob"
              name="dob"
              className="form-control"
              value={partnerData.dob}
              onChange={handleInputChange}
            />
          </div>
        )}

        {/*  Conditional disabling for In Business Since (Year) field */}
        <div className="col-md-6">
          <label htmlFor="businessYear" className="form-label">
            In Business Since (Year):
          </label>
          <input
            type="number"
            id="businessYear"
            name="businessYear"
            className="form-control"
            value={partnerData.businessYear}
            onChange={handleInputChange}
            disabled={partnerType === "Individual (Non registered provider)"}
          />
        </div>

        {/* MoMo Number Field */}
        <div className="col-12">
          <label htmlFor="momoNumber" className="form-label">
            Mobile Money Number:
          </label>
          <PhoneNumberInput
            value={partnerData.momoNumber}
            onChange={(value) =>
              setPartnerData({ ...partnerData, momoNumber: value })
            }
          />
        </div>
        {/* Ghana Card Field */}
        <div className="col-12">
          <label htmlFor="ghanaCard" className="form-label">
            Ghana Card ID:
          </label>
          <input
            type="text"
            id="ghanaCard"
            name="ghanaCard"
            className="form-control"
            value={partnerData.ghanaCard}
            onChange={handleInputChange}
          />
        </div>

        {/* Reference Field */}
        <div className="col-12">
          <label htmlFor="reference" className="form-label">
            Reference:
          </label>
          <input
            type="text"
            id="reference"
            name="reference"
            className="form-control"
            value={partnerData.reference}
            onChange={handleInputChange}
          />
        </div>

        {/* Sort Code Field */}
        <div className="col-12">
          <label htmlFor="sortCode" className="form-label">
            Bank Sort Code:
          </label>
          <input
            type="text"
            id="sortCode"
            name="sortCode"
            className="form-control"
            value={partnerData.sortCode}
            onChange={handleInputChange}
          />
        </div>

        {/* Emergency Contact Field */}
        <div className="col-12">
          <label htmlFor="emergencyContact" className="form-label">
            Emergency Contact:
          </label>
          <PhoneNumberInput
            value={partnerData.emergencyContact}
            onChange={(value) =>
              setPartnerData({ ...partnerData, emergencyContact: value })
            }
          />
        </div>
        {/* Geolocation Button (Trigger fetchGeolocation on click)
            <div className="col-12 text-center">
                <button type="button" className="btn btn-info" onClick={fetchGeolocation}>Fetch Geolocation</button>
                {/* Display the fetched geolocation 
                {partnerData.geolocation && <p>Geolocation: {partnerData.geolocation}</p>}
            </div>

             Geolocation Button (Trigger fetchGeolocation on click)
            <div className="col-12">
                <label htmlFor="latitude" className="form-label">Latitude:</label>
                <input type="text" id="latitude" name="latitude" className="form-control" value={partnerData.latitude} onChange={handleInputChange} />
            </div>
            <div className="col-12">
                <label htmlFor="longitude" className="form-label">Longitude:</label>
                <input type="text" id="longitude" name="longitude" className="form-control" value={partnerData.longitude} onChange={handleInputChange} />
            </div> */}
        {/* Geolocation Button (Trigger fetchGeolocation on click) */}
        <div className="col-12 text-center">
          <button
            type="button"
            className="btn btn-info"
            onClick={handleFetchGeolocation}
          >
            Fetch Geolocation
          </button>
          {/* Display any errors */}
          {error && <p className="text-danger">{error}</p>}
          {/* Display the fetched geolocation */}
          {geolocation && (
            <>
              <div className="col-12">
                <label htmlFor="latitude" className="form-label">
                  Latitude:
                </label>
                <input
                  type="text"
                  id="latitude"
                  name="latitude"
                  className="form-control"
                  value={geolocation.latitude}
                  onChange={handleInputChange}
                />
              </div>
              <div className="col-12">
                <label htmlFor="longitude" className="form-label">
                  Longitude:
                </label>
                <input
                  type="text"
                  id="longitude"
                  name="longitude"
                  className="form-control"
                  value={geolocation.longitude}
                  onChange={handleInputChange}
                />
              </div>
            </>
          )}
        </div>

        <div className="col-12">
          {Object.keys(serviceOptions).map((cleaningType) => (
            <fieldset key={cleaningType}>
              <legend>{cleaningType} Services:</legend>
              <div className="col-sm-10">
                {serviceOptions[cleaningType].map((service) => (
                  <div className="form-check" key={service}>
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value={service}
                      id={service}
                      onChange={handleServiceChange}
                      checked={partnerData.services.some(
                        (s) => s.name === service
                      )}
                    />
                    <label className="form-check-label" htmlFor={service}>
                      {service}
                    </label>
                    {partnerData.services.some((s) => s.name === service) && (
                      <input
                        type="number"
                        placeholder="Set your price"
                        className="form-control"
                        value={
                          partnerData.services.find((s) => s.name === service)
                            ?.price || ""
                        }
                        onChange={(e) =>
                          handlePriceChange(service, e.target.value)
                        }
                        min="0"
                        step="0.01"
                      />
                    )}
                  </div>
                ))}
              </div>
            </fieldset>
          ))}
        </div>

        <div className="col-12">
          <label htmlFor="availability" className="form-label">
            Availability:
          </label>
          <input
            type="text"
            id="availability"
            name="availability"
            className="form-control"
            value={partnerData.availability}
            onChange={handleInputChange}
            required
          />
        </div>
        <div className="col-12">
          <label htmlFor="additionalInfo" className="form-label">
            Additional Information:
          </label>
          <ReactQuill
            theme="snow"
            value={partnerData.additionalInfo || ""}
            onChange={(content) =>
              setPartnerData({ ...partnerData, additionalInfo: content })
            }
          />
        </div>

        <div className="col-12 text-center">
          <button type="submit" className="btn btn-primary">
            Register
          </button>
        </div>
      </form>
    </div>
  );
};

export default PartnerRegistration;