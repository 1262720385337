import React, { useEffect } from 'react';
import { useNavigation } from 'react-router-dom';
import { projectFunctions as functions } from '../firebase/config';

function PaymentResponseComponent() {
    const history = useNavigation();

    useEffect(() => {
        // Extract payment details from the URL
        const urlParams = new URLSearchParams(window.location.search);
        const transactionId = urlParams.get('transaction_id'); // Adjust based on the actual parameter name from Flutterwave

        if (!transactionId) {
            console.error('Transaction ID not found in URL');
            // Redirect to an error page or show an error message
            return;
        }

        // Verify the payment with Flutterwave's API
        // You should create a Cloud Function to handle this verification on the server side
        // For now, we'll assume a function named 'verifyPayment' exists
        functions.httpsCallable('verifyPayment')({ transactionId })
            .then(response => {
                if (response.data.status === 'successful') {
                    // Payment was successful
                    // Update the booking status in Firestore and redirect the user
                    history.push('/booking-success');
                } else {
                    // Payment failed
                    // Show an error message to the user
                    history.push('/booking-failure');
                }
            })
            .catch(error => {
                console.error('Error verifying payment:', error);
                // Show an error message to the user
            });
    }, [history]);

    return (
        <div>
            <p>Processing payment...</p>
            {/* You can show a loading spinner here */}
        </div>
    );
}

export default PaymentResponseComponent;
