import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { doc, getDoc, getDocs, collection } from 'firebase/firestore';
import { projectFirestore } from '../../firebase/config';

import OrderIntake from '../QuoteTool/OrderIntake';

import styles from './ServiceDetail.module.css';

const ServiceDetail = () => {
  const { id } = useParams();
  const [service, setService] = useState(null);
  const [selectedServiceId, setSelectedServiceId] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [otherServices, setOtherServices] = useState([]); // You'll need to fetch these similarly to how you fetch the main service


  useEffect(() => {
    const fetchService = async () => {
      const docRef = doc(projectFirestore, 'cleaningServices', id);
      const docSnap = await getDoc(docRef);
  
      if (docSnap.exists()) {
        const data = docSnap.data();
        console.log("Fetched service data: ", data); // Debug log
        setService({ id: docSnap.id, ...data });
        // Ensure there's at least one image before setting it
        if (data.images && data.images.length > 0) {
          setSelectedImage(data.images[0]);
        } else {
          console.warn("No images found for service:", id);
        }
      } else {
        console.log("No such document!");
      }
    };
  
    const fetchOtherServices = async () => {
      const querySnapshot = await getDocs(collection(projectFirestore, 'cleaningServices'));
      let services = [];
      querySnapshot.forEach((doc) => {
        // Exclude the current service
        if (doc.id !== id) {
          services.push({ id: doc.id, ...doc.data() });
        }
      });
  
      // Shuffle the array and pick the first 4 items
      services = services.sort(() => 0.5 - Math.random()).slice(0, 4);
      setOtherServices(services);
    };
  
    if (id) {
      fetchService();
      fetchOtherServices();
    }
  }, [id]);
  

  if (!service) {
    return <div className="text-center my-5">Loading...</div>;
  }

  return (
    <div className="container my-5">
      <div className="row">
        {/* Left column for images */}
        <div className="col-md-6">
          {service.images && service.images.length > 0 && (
            <img src={selectedImage} alt={service.name} className="img-fluid mb-3" />
          )}
          <div className={`d-flex ${styles.thumbnailContainer}`}>
            {service.images && service.images.map((image, index) => (
              <img key={index} src={image} alt={`Thumbnail ${index}`} className={`${styles.thumbnail} p-1`} onClick={() => setSelectedImage(image)} />
            ))}
          </div>
        </div>

        {/* Right column with service details */}
        <div className="col-md-6">
          <h1 className={styles.serviceName}>{service.name}</h1>
          <p className={styles.servicePrice}>Price: GHS {service.price}</p>
          <OrderIntake 
            selectedServiceId={id}
            // ... other props you need to pass
          />
          <div className={styles.serviceDescription} dangerouslySetInnerHTML={{ __html: service.description }}></div>
          
        </div>
      </div>

      {/* Footer section for other services */}
<div className="my-4">
  <h2 className="text-center mb-4" style={{ color: '#2aa2e6' }}>YOU MAY ALSO LIKE</h2>
  <div className="row row-cols-1 row-cols-md-4 g-4">
    {otherServices.map((otherService, index) => {
      const price = parseFloat(otherService.price);
      const formattedPrice = !isNaN(price) ? price.toFixed(2) : '...';

      return (
        <div key={index} className="col">
          <Link to={`/cleaningServices/${otherService.id}`} className={`card h-100 border-0 text-decoration-none ${styles.serviceCard}`}>
            <img src={otherService.images[0]} alt={otherService.name} className={`${styles.cardImgTop}`} />
            <div className="card-body text-center">
              <h5 className="card-title">{otherService.name}</h5>
              <p className="card-text">from GHS {formattedPrice}</p>
            </div>
          </Link>
        </div>
      );
    })}
  </div>
</div>

    </div>
  );
};

export default ServiceDetail;
