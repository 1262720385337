import React from 'react';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';

const CelebrationDetail = ({ announcement }) => {
  // Placeholder data for the component
  const placeholderData = {
    title: "John and Jane's Wedding",
    date: "2023-12-25",
    time: "16:00",
    location: "Central Park, New York",
    description: "Join us for the celebration of John and Jane's wedding at the lovely Central Park followed by dinner and dancing.",
    contact: "RSVP to Jane at jane@example.com",
    images: [
      "https://via.placeholder.com/800x400/000000/FFFFFF/?text=First+Image",
      "https://via.placeholder.com/800x400/000000/FFFFFF/?text=Second+Image",
    ],
    additionalInfo: "Dress code is semi-formal. Gifts are not necessary, but your presence is greatly appreciated.",
  };

  // Replace placeholderData with your actual announcement data when available
  const data = announcement || placeholderData;

  return (
    <Container className="mt-5">
      <Row>
        <Col md={12}>
          <Card>
            <Card.Body>
              <Card.Title>{data.title}</Card.Title>
              <Card.Text>Date: {data.date} at {data.time}</Card.Text>
              <Card.Text>Location: {data.location}</Card.Text>
              <Card.Text>{data.description}</Card.Text>
              <Card.Text>Contact: {data.contactInfo}</Card.Text>
              <Card.Text>{data.additionalInfo}</Card.Text>
              {data.images.map((img, index) => (
                <div key={index} className="mb-3">
                  <Card.Img variant="top" src={img} alt={`Image ${index + 1}`} />
                </div>
              ))}
              <Button variant="primary">RSVP Now</Button>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default CelebrationDetail;
