import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { UserContext } from '../contexts/UserContext';

function ProtectedRoute({ component: Component, roles, ...rest }) {
  const { currentUser, loading, isAdmin } = useContext(UserContext);
  // console.log('currentUser in ProtectedRoute:', currentUser);
  // console.log('isAdmin in ProtectedRoute:', isAdmin);

//   if (loading) {
//     // If we are still loading the user's auth status, show a loading message or spinner
//     return <div>Loading...</div>; // Or some loading spinner
//   }

//   if (!currentUser) {
//     // User is not authenticated
//     return <Navigate to="/login" />;
//   }

//   if (roles && roles.includes('admin') && !isAdmin) {
//     // User is not an admin but trying to access an admin route
//     return <Navigate to="/" />; // or some "access denied" page
//   }

//   return <Component {...rest} />;
// }

// export default ProtectedRoute;

// First, check if we are still loading the user's auth status
if (loading) {
  return <div>Loading...</div>; // Show loading indicator and don't proceed to other checks
}

// Once loading is complete, check if user is not authenticated
if (!currentUser) {
  return <Navigate to="/login" />; // If not authenticated, redirect to login
}

// Check if the user is trying to access a role-specific route (e.g., admin route)
if (roles && roles.includes('admin') && !isAdmin) {
  return <Navigate to="/" />; // If not an admin, redirect to home or access denied page
}

// If all checks pass, render the requested component
return <Component {...rest} />;
}

export default ProtectedRoute;