import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { doc, getDoc } from 'firebase/firestore';
import { Container, Card, Row, Col, Breadcrumb, Button, Badge, Carousel } from 'react-bootstrap';
import { projectFirestore } from '../../firebase/config';

const placeholderImage = "https://via.placeholder.com/150?text=No+Image+Available";

const AdvertPostDetailPage = () => {
  const { advertId } = useParams();
  const [advert, setAdvert] = useState(null);
  const [loading, setLoading] = useState(true);
  // Placeholder for vertical images and similar items, to be fetched or determined somehow
  // const verticalImages = [
  //   { id: "img1", src: "https://via.placeholder.com/150?text=Image+1" },
  //   { id: "img2", src: "https://via.placeholder.com/150?text=Image+2" },
  //   { id: "img3", src: "https://via.placeholder.com/150?text=Image+3" },
  //   { id: "img4", src: "https://via.placeholder.com/150?text=Image+4" },
  //   { id: "img5", src: "https://via.placeholder.com/150?text=Image+5" }
  // ];
  const similarItems = [
    {
      id: 'item1',
      imageUrl: 'https://via.placeholder.com/150?text=Item+1',
      title: 'Similar Item 1',
      description: 'Description for similar item 1'
    },
    {
      id: 'item2',
      imageUrl: 'https://via.placeholder.com/150?text=Item+2',
      title: 'Similar Item 2',
      description: 'Description for similar item 2'
    },
    {
      id: 'item3',
      imageUrl: 'https://via.placeholder.com/150?text=Item+3',
      title: 'Similar Item 3',
      description: 'Description for similar item 3'
    },
    // ... more items
  ];

  useEffect(() => {
    const fetchAdvert = async () => {
      setLoading(true);
      try {
        const advertRef = doc(projectFirestore, 'adverts', advertId);
        const advertSnap = await getDoc(advertRef);
        if (advertSnap.exists()) {
          setAdvert({ id: advertSnap.id, ...advertSnap.data() });
        } else {
          console.log('No such advert!');
        }
      } catch (error) {
        console.error('Error fetching advert:', error);
      } finally {
        setLoading(false);
      }
    };
    fetchAdvert();
  }, [advertId]);
  
  
  if (loading) {
    return <div>Loading...</div>;
  }

  if (!advert) {
    return <div>Advert not found</div>;
  }

  const renderCarouselItems = () => {
    const images = advert.images?.length ? advert.images : [placeholderImage];
    return images.map((image, index) => (
      <Carousel.Item key={index}>
        <img className="d-block w-100" src={image} alt={`Slide ${index + 1}`} />
      </Carousel.Item>
    ));
  };

  // Function to render vertical images
  const renderVerticalImages = () => {
    const imagesToShow = advert.images?.slice(0, 5) || [];
    if (imagesToShow.length === 0) {
      imagesToShow.push(placeholderImage); // Add placeholder if no images
    }
    return imagesToShow.map((image, index) => (
      <img key={index} src={image} alt={`Vertical Image ${index + 1}`} className="img-fluid mb-2" />
    ));
  };

  return (
    <Container>
      <Breadcrumb>
        <Breadcrumb.Item linkAs={Link} linkProps={{ to: '/' }}>Home</Breadcrumb.Item>
        <Breadcrumb.Item linkAs={Link} linkProps={{ to: '/category' }}>{advert.category}</Breadcrumb.Item>
        <Breadcrumb.Item active>{advert.title}</Breadcrumb.Item>
      </Breadcrumb>
      <Row className="justify-content-center">
        <Col md={2} className="vertical-images">
          {renderVerticalImages()}
        </Col>
        <Col md={5} className="advert-image-carousel">
          <Carousel indicators={false}>
            {renderCarouselItems()}
          </Carousel>
        </Col>
        <Col md={5} className="advert-details">
          <h3>{advert.title}</h3>
          <p><Badge bg="secondary">New</Badge></p>
          <h3>${advert.price}</h3>
          <p>{advert.description}</p>
          <Button variant="primary">Buy It Now</Button>
          <Button variant="outline-primary" className="ms-2">Add to Cart</Button>
          {/* Seller info, payment info, etc. */}
        </Col>
      </Row>c
      <Row className="mt-4">
        <Col>
          <h3>Similar Items</h3>
          {/* Similar items grid */}
          <Row xs={1} md={3} className="g-4">
            {similarItems.map((item, index) => (
              <Col key={index}>
                <Card>
                  <Card.Img variant="top" src={item.imageUrl} />
                  <Card.Body>
                    <Card.Title>{item.title}</Card.Title>
                    <Card.Text>{item.description}</Card.Text>
                    {/* ... other item details */}
                  </Card.Body>
                </Card>
              </Col>
            ))}
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default AdvertPostDetailPage;



