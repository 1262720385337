import React, { useState, useEffect, useContext } from 'react';
import { projectFirestore } from '../../firebase/config';
import { collection, query, where, onSnapshot } from 'firebase/firestore';
import { UserContext } from '../contexts/UserContext';
import { useNavigate } from 'react-router-dom';
import { Table, Alert } from 'react-bootstrap';

const UserOrders = () => {
    const [orders, setOrders] = useState([]);
    const [error, setError] = useState('');
    const { currentUser, loading } = useContext(UserContext);
    const navigate = useNavigate();

    useEffect(() => {
        console.log('Checking loading state:', loading);
        console.log('Current user:', currentUser);
        
        if (loading) {
            // Log a message and return early while loading user state
            console.log('Loading user state...');
            return; 
        }

        if (!currentUser) {  
            console.log('No user found, redirecting to login...');
            navigate('/login'); // Redirect to login if no user is found
            return;
        }

        // Fetch orders specific to the current user
        console.log('Fetching orders for user:', currentUser.uid);
        const ordersRef = collection(projectFirestore, 'cleaningOrders');
        const q = query(ordersRef, where("customerInfo.uid", "==", currentUser.uid));
        const unsubscribe = onSnapshot(q, (querySnapshot) => {
            const ordersData = querySnapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data(),
            }));
            console.log('Orders fetched:', ordersData);
            setOrders(ordersData);
        }, (err) => {
            console.error('Error fetching orders from UserOrders:', err);
            setError('Failed to fetch orders.');
        });

        // Cleanup subscription on unmount
        return () => {
            console.log('Unsubscribing from orders collection...');
            unsubscribe();
        };
    }, [currentUser, navigate, loading]);

    if (loading) {
        return <div>Loading...</div>; // Display loading indicator while fetching user data
    }

    if (!currentUser) {
        console.log('Rendering login alert...');
        return <Alert variant="danger">Please log in to view your orders.</Alert>; // Show alert if no user is found after loading
    }

    const handleOrderClick = (orderId) => {
        console.log('Navigating to order details:', orderId);
        navigate(`/order-details/${orderId}`); // Adjust this path as per your route configuration
    };

    return (
        <div className="container mt-5">
            <h2 className="mb-4">My Orders</h2>
            {error && <Alert variant="danger">{error}</Alert>}
            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Service</th>
                        <th>Price</th>
                        <th>Date</th>
                        <th>Time</th>
                        <th>Status</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {orders.map((order, index) => (
                        <tr key={order.id}>
                            <td>{index + 1}</td>
                            <td>{order.serviceDetails.selectedService}</td>
                            <td>GHS {order.serviceDetails.calculatedPrice}</td>
                            <td>{order.serviceDetails.serviceDate}</td>
                            <td>{order.serviceDetails.serviceTime}</td>
                            <td>{order.orderStatus}</td>
                            <td>
                                <button 
                                    className="btn btn-primary btn-sm"
                                    onClick={() => handleOrderClick(order.id)}
                                >
                                    View
                                </button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        </div>
    );
};

export default UserOrders;
