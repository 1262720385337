import React, { useState, useEffect } from "react";
import {
  collection,
  query,
  where,
  getDocs,
  doc,
  updateDoc,
  Timestamp,
  orderBy,
} from "firebase/firestore";
import { projectFirestore } from "../../firebase/config";
import {
  Container,
  Row,
  Col,
  Card,
  Table,
  Spinner,
  form,
} from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";

const AdminWinnerOverviews = () => {
  const [wins, setWins] = useState([]);
  const [loading, setLoading] = useState(true);
  // const [paymentStatus, setPaymentStatus] = useState(win.paid);

  useEffect(() => {
    const fetchAllWins = async () => {
      try {
        const dailyWinnersQuery = query(
          collection(projectFirestore, "dailyWinners"),
          orderBy("date", "desc")
        );
        const weeklyWinnersQuery = query(
          collection(projectFirestore, "weeklyWinners"),
          orderBy("date", "desc")
        );
        const monthlyWinnersQuery = query(
          collection(projectFirestore, "monthlyWinners"),
          orderBy("date", "desc")
        );

        const [
          dailyWinnersSnapshot,
          weeklyWinnersSnapshot,
          monthlyWinnersSnapshot,
        ] = await Promise.all([
          getDocs(dailyWinnersQuery),
          getDocs(weeklyWinnersQuery),
          getDocs(monthlyWinnersQuery),
        ]);

        const allWins = [
          ...dailyWinnersSnapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          })),
          ...weeklyWinnersSnapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          })),
          ...monthlyWinnersSnapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          })),
        ];

        setWins(allWins);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching wins:", error);
        setLoading(false);
      }
    };

    fetchAllWins();
  }, []);

  if (loading) {
    return (
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ minHeight: "100vh" }}
      >
        <Spinner animation="border" />
      </div>
    );
  }

  const formatDate = (seconds) => {
    const date = new Date(seconds * 1000);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };

  return (
    <Container className="mt-5">
      <Row className="mb-4">
        <Col>
          <Card>
            <Card.Body>
              <Card.Title className="text-center">
                Admin Winner Overview
              </Card.Title>
              <div className="table-responsive">
                <Table className="table table-striped">
                  <thead>
                    <tr>
                      <th>Date</th>
                      <th>User ID</th>
                      <th>Winning ID</th>
                      <th>Name</th>
                      <th>Amount</th>
                      <th>Win Type</th>
                      <th>Claim Status</th>
                      <th>Claim Date</th>
                      <th>Payment Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {wins.map((win) => (
                      <tr key={win.id}>
                        <td>{formatDate(win.date.seconds)}</td>
                        <td>{win.userId}</td>
                        <td>{win.id}</td>
                        <td>{win.name}</td>
                        <td>{win.amount} cedis</td>
                        <td>{win.type}</td>
                        <td
                          style={{
                            fontWeight: "bold",
                            color: win.claimed ? "blue" : "black",
                          }}
                        >
                          {win.claimed ? "Claimed" : "Unclaimed"}
                        </td>
                        <td>
                          {win.claimedDate
                            ? new Date(
                                win.claimedDate.seconds * 1000
                              ).toLocaleDateString("en-GB")
                            : "N/A"}
                        </td>
                        <td
                          style={{
                            fontWeight: "bold",
                            color: win.paid === "Yes Paid" ? "blue" : "black",
                          }}
                        >
                          {win.paid || "Not Paid"}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default AdminWinnerOverviews;
