import React, { useState, useEffect, useContext } from 'react';
import { UserContext } from '../contexts/UserContext';

import { projectFirestore } from '../../firebase/config';
import { collection, getDocs, onSnapshot, addDoc } from 'firebase/firestore'; // <-- Import the necessary Firestore functions

const AddBooking = () => {
    const { currentUser } = useContext(UserContext);
    const userId = currentUser?.uid; // This will be undefined if the user is not authenticated

    const [services, setServices] = useState([]);
    const [selectedService, setSelectedService] = useState('');
    const [date, setDate] = useState('');
    const [time, setTime] = useState('');
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchServices = async () => {
            try {
                const servicesCollection = collection(projectFirestore, 'services'); // <-- Define the collection
                const servicesSnapshot = await getDocs(servicesCollection); // <-- Use getDocs to fetch documents
                setServices(servicesSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })));
            } catch (err) {
                setError('Failed to fetch services.');
            } finally {
                setLoading(false);
            }
        };
        fetchServices();
    }, []);

    useEffect(() => {
        const servicesCollection = collection(projectFirestore, 'services'); // <-- Define the collection
        const unsubscribe = onSnapshot(servicesCollection, snapshot => { // <-- Use onSnapshot for real-time updates
            let fetchedServices = [];
            snapshot.forEach(doc => {
                fetchedServices.push({ id: doc.id, ...doc.data() });
            });
            setServices(fetchedServices);
        });

        return () => unsubscribe();
    }, []);


    const addBooking = async (e) => {
        e.preventDefault();

            // Log the values here
            // console.log("userId:", userId);
            // console.log("date:", date);
            // console.log("time:", time);
            // console.log("selectedService:", selectedService);

        if (!userId || !date || !time || !selectedService) {
            setError('All fields are required.');
            return;
        }
        try {
            const bookingsCollection = collection(projectFirestore, 'bookings');
            await addDoc(bookingsCollection, {
                userId,
                date,
                time,
                serviceId: selectedService,
                status: 'pending'
            });
            alert('Booking added successfully!');
        } catch (err) {
            console.error(err);
            setError('Failed to add booking.');
        }
    };
    

    return (
        <div>
            <h2>Add a Booking</h2>
            {error && <p>{error}</p>}
            {loading ? (
                <p>Loading services...</p>
            ) : (
                <form onSubmit={addBooking}>
                    <label>
                        Service:
                        <select
                            value={selectedService}
                            onChange={(e) => setSelectedService(e.target.value)}
                            required
                        >
                            <option value="" disabled>Select a service</option>
                            {services.map((service) => (
                                <option key={service.id} value={service.id}>
                                    {service.name} - ${service.price}
                                </option>
                            ))}
                        </select>
                    </label>
                    <br />
                    <label>
                        Date:
                        <input
                            type="date"
                            value={date}
                            onChange={(e) => setDate(e.target.value)}
                            required
                        />
                    </label>
                    <br />
                    <label>
                        Time:
                        <input
                            type="time"
                            value={time}
                            onChange={(e) => setTime(e.target.value)}
                            required
                        />
                    </label>
                    <br />
                    <button type="submit">Add Booking</button>
                </form>
            )}
        </div>
    );
};

export default AddBooking;
