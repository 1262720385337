import React, { useState, useEffect, useContext } from 'react';
import { UserContext } from '../contexts/UserContext';
import { projectFirestore } from '../../firebase/config';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import { Container, Table } from 'react-bootstrap';


function MyOrders() {
  const { currentUser } = useContext(UserContext);
  const navigate = useNavigate();
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchUserOrders();
  }, []);


const fetchUserOrders = async () => {
    if (!currentUser) {
      console.log('No user logged in.');
      return;
    }
  
    setLoading(true);
    try {
      const ordersRef = collection(projectFirestore, 'deliveryorders');
      const querySnapshot = await getDocs(query(ordersRef, where('userId', '==', currentUser.uid)));
  
      if (querySnapshot.empty) {
        console.log('No orders found for user:', currentUser.uid);
      } else {
        const fetchedUserOrders = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        console.log('Fetched User Orders:', fetchedUserOrders); // Log fetched orders
        setOrders(fetchedUserOrders);
      }
    } catch (error) {
      console.error('Error fetching user orders:', error);
    } finally {
      setLoading(false);
    }
  };
  

  const renderOrdersTable = (ordersList, title) => (
    <div>
      <h2>{title} Orders</h2>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Order ID</th>
            <th>Pickup Date</th>
            <th>Delivery Date</th>
            <th>Order Status</th>
          </tr>
        </thead>
        <tbody>
          {ordersList.map(order => (
            <tr key={order.id} onClick={() => navigate(`/allGDeliveries/${order.id}`)} style={{ cursor: 'pointer' }}>
              <td>{order.id}</td>
              <td>{order.packageDetails.pickupDate ? new Date(order.packageDetails.pickupDate).toLocaleDateString() : 'No Date'}</td>
              <td>{order.packageDetails.deliveryDate ? new Date(order.packageDetails.deliveryDate).toLocaleDateString() : 'No Date'}</td>
              <td>{order.orderStatus}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );

  if (loading) {
    return <div>Loading orders...</div>;
  }

  // Filter orders
  const inTransitOrders = orders.filter(order => order.orderStatus === 'in_transit');
  const pendingOrders = orders.filter(order => order.orderStatus === 'pending');
  const deliveredOrders = orders.filter(order => order.orderStatus === 'delivered');

  return (

      <Container className="mt-4">
        <h1 className="mb-4">My Orders Overview</h1>
        {renderOrdersTable(pendingOrders, 'Pending')}
        {renderOrdersTable(inTransitOrders, 'In-transit')}
        {renderOrdersTable(deliveredOrders, 'Delivered')}
      </Container>

  );
}

export default MyOrders;
