

import React, { useContext } from 'react';
import { UserContext } from '../../../components/contexts/UserContext';
import { doc, updateDoc } from 'firebase/firestore';
import { projectFirestore } from '../../../firebase/config';

const PartnerProfile = () => {
  const { currentUser, updateUserRole } = useContext(UserContext);

  const handleBecomePartner = async () => {
    if (!currentUser) {
      console.error('No current user found');
      return;
    }
    // Call updateUserRole with the current user's ID and the new role
    await updateUserRole(currentUser.uid, 'partner');
    console.log('User role updated to partner');
  };

  return (
    <div className="partner-profile">
      <button className="btn btn-primary" onClick={handleBecomePartner}>Become a Partner</button>
    </div>
  );
};

export default PartnerProfile;
