


import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Card, Row, Col } from "react-bootstrap";
import { collection, getDocs } from "firebase/firestore";
import { projectFirestore } from "../../firebase/config";
import { UserContext } from "../contexts/UserContext";
import backgroundImage from "../../assets/livingroom3.jpg";
import styles from "./MainServicesPage.module.css";
import DailyWinners from "../PlayWinners/Draw/DailyWinners";
import WeeklyWinners from "../PlayWinners/Draw/WeeklyWinners";
import MonthlyWinners from "../PlayWinners/Draw/MonthlyWinners";
import StatsDisplay from "./StatsDisplay"; // Assuming StatsDisplay is in the same directory

const PlayServicesPage = () => {
  const { currentUser, loading } = useContext(UserContext);
  const [unclaimedPercentage, setUnclaimedPercentage] = useState("0.00");
  const [paidPercentage, setPaidPercentage] = useState("0.00");

  useEffect(() => {
    const calculateStatistics = async () => {
      const collections = ["dailyWinners", "weeklyWinners", "monthlyWinners"];
      let total = 0,
        unclaimed = 0,
        claimed = 0,
        paid = 0;

      for (let coll of collections) {
        const querySnapshot = await getDocs(collection(projectFirestore, coll));
        querySnapshot.forEach((doc) => {
          const data = doc.data();
          total++; // Count each entry as part of the total
          if (!data.claimed) {
            unclaimed++;
          } else {
            claimed++;
            if (data.paid === "Yes Paid") {
              paid++;
            }
          }
        });
      }

      // Set the percentage of unclaimed winnings
      setUnclaimedPercentage(((unclaimed / total) * 100).toFixed(2));

      // Set the percentage of claimed winnings that are paid
      if (claimed > 0) {
        setPaidPercentage(((paid / claimed) * 100).toFixed(2));
      } else {
        setPaidPercentage("0.00"); // Handle case where there are no claimed winnings
      }
    };

    calculateStatistics();
  }, []);

  return (
    <section className={styles.mainServicesPage}>
      <div
        className={styles.hero}
        style={{ backgroundImage: `url(${backgroundImage})` }}
      >
        <div className={styles.content}>
          <h1>Welcome to AllGhanaians</h1>
          <p>
            Join our daily, weekly, and monthly giveout to win your Kenkey Gift
            Prizes! Refer your friends to increase your chances of winning.
          </p>
          {!loading && !currentUser && (
            <p className="text-warning">
              Please sign up using the navigation bar above to participate in
              the giveouts.
            </p>
          )}
          <Link to="/winners" className="btn btn-secondary">
            Go to Play Dashboard
          </Link>
        </div>
      </div>

      <div className="container my-5">
        <StatsDisplay
          unclaimedPercentage={unclaimedPercentage}
          paidPercentage={paidPercentage}
        />

        <Row xs={1} md={3} className="g-4 mb-5">
          <Col>
            <Card>
              <Card.Body>
                <Card.Title>Join GiveOuts</Card.Title>
                <Card.Text>
                  Sign up and participate in the daily giveout.
                </Card.Text>
                <Link to="/signup" className="btn btn-secondary">
                  Join Now
                </Link>
              </Card.Body>
            </Card>
          </Col>
          <Col>
            <Card>
              <Card.Body>
                <Card.Title>Refer Friends</Card.Title>
                <Card.Text>
                  Invite friends and earn extra entries into the giveout.
                </Card.Text>
                <Link to="/winners-dashboard" className="btn btn-secondary">
                  Refer Now
                </Link>
              </Card.Body>
            </Card>
          </Col>
          <Col>
            <Card>
              <Card.Body>
                <Card.Title>Check Dashboard</Card.Title>
                <Card.Text>Review your entries and winnings.</Card.Text>
                <Link to="/dashboard" className="btn btn-secondary">
                  Dashboard
                </Link>
              </Card.Body>
            </Card>
          </Col>
        </Row>

        <section>
          <DailyWinners />
          <WeeklyWinners />
          <MonthlyWinners />
        </section>
      </div>
    </section>
  );
};

export default PlayServicesPage;
