import { projectFirestore, serverTimestamp } from "../../../firebase/config";
import {
  collection,
  query,
  orderBy,
  limit,
  getDocs,
  addDoc,
} from "firebase/firestore";

// Get daily winners
const getDailyWinners = async () => {
  const q = query(
    collection(projectFirestore, "dailyWinners"),
    orderBy("date", "desc"),
    limit(30)
  );
  const querySnapshot = await getDocs(q);
  return querySnapshot.docs.map((doc) => doc.data());
};

// Get weekly winners
const getWeeklyWinners = async () => {
  const q = query(
    collection(projectFirestore, "weeklyWinners"),
    orderBy("date", "desc"),
    limit(30)
  );
  const querySnapshot = await getDocs(q);
  return querySnapshot.docs.map((doc) => doc.data());
};

// Get monthly winners
const getMonthlyWinners = async () => {
  const q = query(
    collection(projectFirestore, "monthlyWinners"),
    orderBy("date", "desc"),
    limit(12)
  );
  const querySnapshot = await getDocs(q);
  return querySnapshot.docs.map((doc) => doc.data());
};

// Add a winner (this should be called by a backend function or admin interface)
const addWinner = async (winner) => {
  const collectionName =
    winner.type === "daily"
      ? "dailyWinners"
      : winner.type === "weekly"
      ? "weeklyWinners"
      : "monthlyWinners";
  await addDoc(collection(projectFirestore, collectionName), {
    ...winner,
    date: serverTimestamp(),
  });

  // Call the cloud function to send notifications for test winners
  if (winner.isTest) {
    const response = await fetch(
      "https://us-central1-your-project-id.cloudfunctions.net/sendTestNotifications",
      {
        method: "POST",
      }
    );
    if (!response.ok) {
      throw new Error("Error sending test notifications");
    }
  }
};

export default {
  getDailyWinners,
  getWeeklyWinners,
  getMonthlyWinners,
  addWinner,
};
